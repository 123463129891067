import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';


const CurrencyFormat = forwardRef((props, ref) => {
  const { financeindex, index, name, onBlur, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      onBlur={(event) => {
        onBlur({
          target: {
            name: name,
            value: event.target.value.replace('$', ''),
            index: index,
            financeIndex: financeindex,
          },
        });
      }}
      placeholder="$"
      isNumericString
      allowNegative={false}
      thousandSeparator
      prefix='$'
      decimalScale={0}
      fixedDecimalScale={true}
    />
  );
});

CurrencyFormat.propTypes = {
  financeindex: PropTypes.number,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};


export default CurrencyFormat;
