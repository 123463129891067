import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  IconButton,
  TextField,
  Tooltip,
  Popover,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import CurrencyFormat from './CurrencyFormat';
import { trashLeads, duplicateLeads } from "../../Store/Leads";
import { setLeads, setReleasedLeads, } from "../../Store/SelectedLeads";
import { defaultPopoverTheme } from "../LeadsTableTheme";

const selectLeadStyles = makeStyles()({
  iconButton: {
    display: "inline-block",
    position: "relative",
  },
  assignIcon: {
    color: "#000"
  },
  popover: {
    padding: '1rem',
  }
});

const popoverTheme = () => createTheme(defaultPopoverTheme);

export default function ReviewLeadTools(props) {

  const dispatch = useDispatch();

  const { leadSetStatus, rows, userRole } = props;
  const [ loading, setLoading ] = useState(false);
  const [ price, setPrice ] = useState('');
  const [ pricePopover, setPricePopover ] = useState(null);

  const { classes } = selectLeadStyles();

  const selectedLeads = () => {
    const leads = props.rows;
    let selectedLeads = [];
    props.selectedRows.data.forEach(row => {
      selectedLeads.push(leads[row.dataIndex]);
    });
    return selectedLeads;
  }

  const selectedLeadIds = () => {
    const leads = props.rows;
    let selectedLeads = [];
    props.selectedRows.data.forEach(row => {
      selectedLeads.push(leads[row.dataIndex].id);
    });
    return selectedLeads;
  }

  const handlePrice = (event) => {
    setPrice(event.target.value);
  }

  const handleRelease = () => {
    setLoading(true);

    const selectedIds = selectedLeadIds();
    const selected = selectedLeads();
    
    const remainingLeads = rows.filter(lead => !selectedIds.includes(lead.id));
    
    dispatch(setReleasedLeads(selected));
    dispatch(setLeads(remainingLeads)).then( () => setLoading(false));
    
  };

  const handleDelete = () => {
    const selectedIds = selectedLeadIds();
    
    if (window.confirm("Are you sure you want to delete the selected leads?")){
      setLoading(true);
      const remainingLeads = rows.filter(lead => !selectedIds.includes(lead.id));
      dispatch(trashLeads({leads: selectedIds}));
      dispatch(setLeads(remainingLeads)).then( () => setLoading(false));
    }
  }; 

  const handleDuplicates = () => {
    const selectedIds = selectedLeadIds();

    if (window.confirm("Are you sure you want to remove the selected leads from inventory and mark them as duplicates?")){
      setLoading(true);
      dispatch(duplicateLeads({leads: selectedIds}));
      const remainingLeads = rows.filter(lead => !selectedIds.includes(lead.id));
      dispatch(setLeads(remainingLeads)).then( () => setLoading(false));
    }
  };

  const updatePrices = () => {
    const updateIds = selectedLeadIds();
    const thePrice = price.replace(/,/g, '');

    setLoading(true);
    const updatedLeads = rows.map(lead => ({
      ...lead,
      price: (updateIds.includes(lead.id) ? thePrice : lead.price),
    }));

    dispatch(setLeads(updatedLeads)).then( () => {
      handleClose();
      setLoading(false);
    });
  }

  const showPopover = (event) => {
    setPricePopover(event.currentTarget);
  }

  const handleClose = () => {
    setPricePopover(null);
  } 

  const popoverBool = Boolean(pricePopover);

  return (
    <div className={"toolbar-review-leads"}>
      {loading && (
          <div className="progressWrapper">
            <CircularProgress />
          </div>
      )}
      {leadSetStatus !== 'request' && <>
        <Tooltip title={"Override Price"}>
          <IconButton className={classes.iconButton} onClick={event => showPopover(event)} size="large">
            <MonetizationOnIcon className={classes.assignIcon} />
          </IconButton>
        </Tooltip>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={popoverTheme()}>
            <Popover
              id='priceChange'
              classes={{
                paper: classes.popover,
              }}
              open={popoverBool}
              anchorEl={pricePopover}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <FormControl varient="outlined">
                <TextField
                  label="Lead Price"
                  InputLabelProps={{ shrink: true }}
                  name="price"
                  variant="outlined"
                  value={price}
                  onChange={event => handlePrice(event)}
                  InputProps={{
                    inputComponent: CurrencyFormat,
                  }}
                  inputProps= {{
                    index: 0,
                  }}
                />
              </FormControl>
              <FormGroup>
                <Button type='submit' variant='contained' color='primary' onClick={updatePrices}>
                  Update Prices
                </Button>
                <Button variant='outlined' color='secondary' onClick={handleClose} >
                  Cancel
                </Button>
              </FormGroup>
            </Popover>
          </ThemeProvider>
        </StyledEngineProvider>
      </>}

      <Tooltip title="Remove Selected Leads">
        <IconButton className={classes.iconButton} onClick={handleRelease} size="large">
          <RemoveCircleOutlineIcon className={classes.assignIcon} />
        </IconButton>
      </Tooltip>

      {userRole === 'sl_lead_dept' && leadSetStatus !== 'revisedApproved' && <>
        <Tooltip title={"Delete Selected Leads"}>
          <IconButton className={classes.iconButton} onClick={handleDelete} size="large">
            <DeleteIcon className={classes.assignIcon} />
          </IconButton>
        </Tooltip>
        
        <Tooltip title={"Mark Selected Leads as Duplicates"}>
          <IconButton
            className={classes.iconButton}
            onClick={handleDuplicates}
            size="large">
            <PersonAddDisabledIcon className={classes.assignIcon} />
          </IconButton>
        </Tooltip>
      </>}
    </div>
  );
}
