import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  TextField,
  Tooltip,
  Popover
} from "@mui/material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { makeStyles } from 'tss-react/mui';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { setLeads, setAllLeads } from "../../Store/SelectedLeads";
import { reassignLeads, trashLeads, duplicateLeads, assignLeadsInventory, dncLeads, dnmLeads } from "../../Store/Leads";
import { themeBreakPoints, defaultPopoverTheme } from "../LeadsTableTheme";

const selectLeadStyles = makeStyles()({
  assignIcon: {
    color: "#000",
    [themeBreakPoints.breakpoints.down('md')]: {
      width: '.75em',
      height: '.75em',
    }
  },
  iconButton: {
    display: "inline-block",
    position: "relative",
    borderRadius: '100%',
    marginRight: 26,
    background: 'rgb(244, 196, 42)',
    border: '2px solid rgb(244, 196, 42)',
    padding: '9px 12px 6px',
    '&:hover': {
      background: '#fff',
      borderColor: '#999',
    },
    [themeBreakPoints.breakpoints.down('md')]: {
      paddingTop: 5,
      marginBottom: 9,
      '&::after': {
        position: 'absolute',
        color: '#fff',
        fontSize: 14,
        bottom: -21,
        left: 0,
        width: '100%',
        textAlign: 'center',
      }
    }
  },
  textAssign: {
    [themeBreakPoints.breakpoints.down('md')]: {
      '&::after': {
        content: '"Assign"',
      }
    }
  },
  textDelete: {
    [themeBreakPoints.breakpoints.down('md')]: {
      '&::after': {
        content: '"Delete"',
      }
    }
  },
  textDnc: {
    [themeBreakPoints.breakpoints.down('md')]: {
      '&::after': {
        content: '"DNC"',
      }
    }
  },
  textDnm: {
    [themeBreakPoints.breakpoints.down('md')]: {
      '&::after': {
        content: '"DNM"',
      }
    }
  },
  textDupe: {
    [themeBreakPoints.breakpoints.down('md')]: {
      '&::after': {
        content: '"Duplicate"',
        left: -7,
      }
    }
  },
  textInventory: {
    [themeBreakPoints.breakpoints.down('md')]: {
      '&::after': {
        content: '"Inventory"',
        left: -7,
      }
    }
  },
  infoWrapper: {
    display: 'grid',
    gridTemplateColumns: '40px calc(100% - 50px)',
    marginBottom: 15,
  },
  infoIcon: {
    color: 'rgba(255, 106, 0, 1)',
    fontSize: 40,
  },
  infoDescription: {
    paddingLeft: 10,
    margin: 0,
  },
  legend: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    marginBottom: '.5rem',
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '1rem',
    width: 'fit-content',
  },
});

const popoverTheme = () => createTheme(defaultPopoverTheme);

export default function FilterLeadsTools(props) {
  const { allSelectedLeads, managers, options, rows, selectedRows, userRole } = props;

  const [assignPopover, setAssignPopover] = useState(null);
  const [generalInventory, setGeneralInventory] = useState(false);
  const [inventoryPopover, setInventoryPopover] = useState(null);
  const [manager, setManager] = useState(null);
  const [managerInput, setManagerInput] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const {classes, cx} = selectLeadStyles();

  // These are the leads selected from the currently displayed rows.
  const selectedLeadIds = () => {
    const leads = rows;
    let selectedLeads = [];
    selectedRows.data.forEach(row => {
      selectedLeads.push(leads[row.dataIndex].id);
    });
    return selectedLeads;
  }

  const selectedLeads = () => {
    const leads = rows;
    let selectedLeads = [];
    selectedRows.data.forEach(row => {
      selectedLeads.push(leads[row.dataIndex]);
    });
    return selectedLeads;
  }

  const showAssignLeads = (event) => {
    setAssignPopover(event.currentTarget);
  }

  const handleAssignLeads = (event) => {
    const selected = selectedLeads();
    setLoading(true);

    if(event.target.name === 'selectedLeads'){
      let leadSetLeads = selected;

      if(allSelectedLeads && allSelectedLeads.length > 0) {
        leadSetLeads = allSelectedLeads.concat(leadSetLeads);

        leadSetLeads = leadSetLeads.filter((lead, index, leadsArray) => leadsArray.findIndex(checkLead => (checkLead.id === lead.id)) === index);

        leadSetLeads = leadSetLeads.slice(0, 500);
      }

      dispatch(setLeads(leadSetLeads));
    } else {
      dispatch(setAllLeads({options: options, existingLeads: allSelectedLeads}));
      setAssignPopover(null);
    }
  };

  const handleReassign = () => {
    const selectedIds = selectedLeadIds();
    if (window.confirm("Are you sure you want to reassign the selected leads?")) dispatch(reassignLeads({leads: selectedIds, options: options}));
  };

  const handleDelete = () => {
    const selectedIds = selectedLeadIds();
    if (window.confirm("Are you sure you want to delete the selected leads?")) dispatch(trashLeads({leads: selectedIds, options: options}));
  };

  const handleDnc = () => {
    setLoading(true);

    const selectedIds = selectedLeadIds();
    
    if (window.confirm("Are you sure you want to designate the selected leads as Do Not Call? They will be removed from any pending lead sets.")) {
      dispatch(dncLeads({leads: selectedIds, options: options})).then( () => setLoading(false));
    }
    else {
      setLoading(false);
    }
  }

  const handleDnm = () => {
    setLoading(true);

    const selectedIds = selectedLeadIds();
    
    if (window.confirm("Are you sure you want to designate the selected leads as Do Not Mail? They will be removed from any pending lead sets.")) {
      dispatch(dnmLeads({leads: selectedIds, options: options})).then( () => setLoading(false));
    }
    else {
      setLoading(false);
    }
    ;
  }

  const handleDuplicates = () => {
    const selectedIds = selectedLeadIds();
    if (window.confirm("Are you sure you want to remove the selected leads from inventory and mark them as duplicates?")) dispatch(duplicateLeads({leads: selectedIds, options: options}));
  }

  const handleInventory = () => {
    const selectedIds = selectedLeadIds();
    const managerId = (manager ? manager.uid : null);

    dispatch(assignLeadsInventory({leads: selectedIds, rvp: managerId, options: options}));
    setInventoryPopover(null);
  }

  const showPopover = (event) => {
    setInventoryPopover(event.currentTarget);
  }

  const handleClose = () => {
    setInventoryPopover(null);
    setAssignPopover(null);
    setLoading(false);
  }

  const handleManager = (e, value) => {
    setManager(value);
  }

  const handleManagerInput = (e, value) => {
    setManagerInput(value);
  }

  const handleGeneralChange = (e) => {
    setGeneralInventory(e.target.checked);
    setManager('');
  }

  const popoverBool = Boolean(inventoryPopover);
  const assignPopBool = Boolean(assignPopover);

  return (
    <div className={classes.toolbar}>
      {loading && (
        <div className="progressWrapper">
          <CircularProgress />
        </div>
      )}
      <Tooltip title={"Assign Selected Leads"}>
        <IconButton
          className={cx(classes.iconButton, classes.textAssign)}
          onClick={event => showAssignLeads(event)}
          size="large">
          <AssignmentIndIcon className={classes.assignIcon} />
        </IconButton>
      </Tooltip>

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={popoverTheme()}>
          <Popover
            id='assignLeads'
            open={assignPopBool}
            anchorEl={assignPopover}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: -100,
            }}
          >
            <FormGroup component='fieldset'>
              <div className={classes.infoWrapper}>
                <InfoIcon className={classes.infoIcon} />
                <p className={classes.infoDescription}>
                  { (!allSelectedLeads || allSelectedLeads.length === 0) && (
                    <>
                      Begin creating a lead set. Leads may be removed from the set during the request and review steps. There is a maximum of 500 leads.
                    </>
                  )}
                  { allSelectedLeads && allSelectedLeads.length > 0 && allSelectedLeads.length < 500 && (
                    <>
                      Add leads to the current lead set.
                    </>
                  )}
                  { allSelectedLeads && allSelectedLeads.length > 499 && (
                    <>The current lead set has the maximum number of leads. Unable to add additional leads.</>
                  )}
                </p>
              </div>
              { (allSelectedLeads && allSelectedLeads.length < 500) && (
                <>
                  <FormLabel component='legend' className={classes.legend}>Add:</FormLabel>
                  <Button type='submit' name='selectedLeads' variant='contained' color='primary' onClick={event => handleAssignLeads(event)}>
                    Selected Leads
                  </Button>
                  <Button type='submit' name='allLeads' variant='contained' color='primary' onClick={event => handleAssignLeads(event)}>
                    All Leads Matching Filter
                  </Button>
                  <p>Assign up to the first 500 leads that match the current filter.</p>
                  <Button variant='outlined' color='secondary' onClick={handleClose} >
                    Cancel
                  </Button>
                </>
              )}
            </FormGroup>
          </Popover>
        </ThemeProvider>
      </StyledEngineProvider>

      {userRole === 'sl_lead_dept' && (
        <>
          <Tooltip title={"Reassign Selected Leads"}>
            <IconButton className={cx(classes.iconButton, classes.textReassign)} onClick={handleReassign} size="large">
              <AssignmentReturnIcon className={classes.assignIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Delete Selected Leads"}>
            <IconButton className={cx(classes.iconButton, classes.textDelete)} onClick={handleDelete} size="large">
              <DeleteIcon className={classes.assignIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Mark Selected Leads as Duplicates"}>
            <IconButton
              className={cx(classes.iconButton, classes.textDupe)}
              onClick={handleDuplicates}
              size="large">
              <PersonAddDisabledIcon className={classes.assignIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Designate as Do Not Call"}>
            <IconButton className={cx(classes.iconButton, classes.textDnc)} onClick={handleDnc} size="large">
              <PhoneDisabledIcon className={classes.assignIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Designate as Do Not Mail"}>
            <IconButton className={cx(classes.iconButton, classes.textDnm)} onClick={handleDnm} size="large">
              <DomainDisabledIcon className={classes.assignIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Assign Leads to an Inventory"}>
            <IconButton className={cx(classes.iconButton, classes.textInventory)} onClick={event => showPopover(event)} size="large">
              <SupervisorAccountIcon className={classes.assignIcon} />
            </IconButton>
          </Tooltip>
          
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={popoverTheme()}>
              <Popover
                id='assignInventory'
                classes={{
                  paper: classes.popover,
                }}
                open={popoverBool}
                anchorEl={inventoryPopover}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >

                <FormControlLabel 
                  control={
                    <Checkbox 
                      name="generalInventory"
                      checked={generalInventory}
                      onChange={handleGeneralChange}
                    />
                  } 
                  label="General Inventory" 
                />
                { !generalInventory && 
                  <Autocomplete
                    value={manager}
                    inputValue={managerInput}
                    name="manager"
                    id="manager"
                    onChange={handleManager}
                    onInputChange={handleManagerInput}
                    options={(managers ? Object.values(managers) : [])}
                    getOptionLabel={(user) => (user.name ? user.name : "") }
                    isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                    renderInput={(params) => <TextField {...params} label="Manager" varient="outlined" required={true} /> }
                  />
                }
                <FormGroup>
                  <Button type='submit' variant='contained' color='primary' onClick={handleInventory} disabled={manager === null}>
                    Update Inventory
                  </Button>
                  <Button variant='outlined' color='secondary' onClick={handleClose} >
                    Cancel
                  </Button>
                </FormGroup>
              </Popover>
            </ThemeProvider>
          </StyledEngineProvider>
        </>
      )}
    </div>
  );
}
