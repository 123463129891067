import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LeadApi from '../Api/LeadApi';
import ArchiveLeadApi from '../Api/ArchiveLeadApi';

export const setLeads = createAsyncThunk(
  'selectedLeads/setLeads',
  (leads) => {
    return leads;
  }
);

export const fetchAllSetLeads = createAsyncThunk(
  'selectedLeads/fetchAllSetLeads',
  async (leadIds, { getState, rejectWithValue }) => {
    const { user, taxonomy } = getState();
    try {
      let response = await LeadApi.fetchAllSetLeads(leadIds, user);

      if (response.errors) return rejectWithValue(response.errors);

      if(response.leads.length === 0) return response;

      response.leads.forEach(lead => {
        if(lead.price === null && taxonomy.pricing) {
          const pricingArray = taxonomy.pricing.filter(p => p.leadType === lead.leadTypeId);

          if (pricingArray !== null && pricingArray.length === 1) {
            lead.price = pricingArray[0].price;
          }
          else if (pricingArray !== null && pricingArray.length > 1) {
            let findPrice = pricingArray.filter(p => p.state === lead.stateId);
            if(findPrice.length === 0){
              findPrice = pricingArray.filter(p => p.state === null );
            }
            else {
              if(!lead.leadTypeLabel) {
                lead.leadTypeLabel = `DM-${findPrice[0].price.match(/(\d+)/)[0]}`;
              }
            }
            lead.price = findPrice[0].price;
          }
        }
      });

      return response;
    }
    catch (err) {
      console.error('fetchAllSetLeads error: ', err);
      return rejectWithValue(err);
    }
  }    
);

export const fetchArchivedLeads = createAsyncThunk(
  'selectedLeads/fetchArchivedLeads',
  async(leadSetId, { getState, rejectWithValue }) => {
    try {
      let response = await ArchiveLeadApi.fetchLeads(leadSetId, getState().user);
      return response;
    }
    catch (err) {
      console.error('fetchArchivedLeads error: ', err);
      return rejectWithValue(err);
    }
  }
);

export const setAllLeads = createAsyncThunk(
  'selectedLeads/setAllLeads',
  async ({ options, existingLeads = [] }, { getState, rejectWithValue }) => {
    try {
      const { user, taxonomy } = getState();
      let response = await LeadApi.fetchAllQuery(options, user, rejectWithValue);
      if (response.errors) return rejectWithValue(response.errors[0]);

      if (!response.leads && response.payload) return rejectWithValue(response.payload.message);

      response.leads.forEach(lead => {
        if(lead.price === null && taxonomy.pricing) {
          const pricingArray = taxonomy.pricing.filter(p => p.leadType === lead.leadTypeId);

          if (pricingArray !== null && pricingArray.length === 1) {
            lead.price = pricingArray[0].price;
          }
          else if (pricingArray !== null && pricingArray.length > 1) {
            let findPrice = pricingArray.filter(p => p.state === lead.stateId);
            if(findPrice.length === 0){
              findPrice = pricingArray.filter(p => p.state === null );
            }
            else {
              if(!lead.leadTypeLabel) {
                lead.leadTypeLabel = `DM-${findPrice[0].price.match(/(\d+)/)[0]}`;
              }
            }
            lead.price = findPrice[0].price;
          }
        }
      });

      if(existingLeads.length > 0 ){
        response.leads = existingLeads.concat(response.leads);
        response.leads = response.leads.filter((lead, index, leadsArray) => leadsArray.findIndex(checkLead => (checkLead.id === lead.id)) === index);
        response.leads = response.leads.slice(0, 500);
      }
      return response;
    }
    catch (err) {
      console.error('fetchAllQuery', err);
      return rejectWithValue(err);
    }
  }
);

export const setReleasedLeads = createAsyncThunk(
  'selectedLeads/setReleasedLeads',
  (leads, { getState }) => {
    const { selectedLeads } = getState();
    const releasedLeads = selectedLeads.releasedLeads.concat(leads);

    return releasedLeads;
  }
);

export const undoReleaseLeads = createAsyncThunk(
  'selectedLeads/undoReleasedLeads',
  (bool = true, { getState }) => {
    const { selectedLeads } = getState();
    let updatedLeads = selectedLeads.selectedLeads.concat(selectedLeads.releasedLeads);

    updatedLeads = updatedLeads.filter((lead, index, leadsArray) => leadsArray.findIndex(checkLead => (checkLead.id === lead.id)) === index);

    return updatedLeads;
  }
);

export const resetSelectedLeads = createAsyncThunk(
  'selectedLeads/resetSelectedLeads',
  () => {
    return true;
  }
);

const leadsSlice = createSlice({
  name: 'selectedLeads',
  initialState: {
    selectedLeads: [],
    releasedLeads: [],
    loading: 'idle',
    error: null,
  },
  extraReducers: {
    [fetchAllSetLeads.pending]: (state) => {
      state.loading = 'pending';
      state.error = null;
    },
    [fetchAllSetLeads.fulfilled]: (state, action) => {
      state.selectedLeads = action.payload.leads;
      state.loading = 'idle';
      state.error = null;
    },
    [fetchArchivedLeads.pending]: (state) => {
      state.loading = 'pending';
      state.error = null;
    },
    [fetchArchivedLeads.fulfilled]: (state, action) => {
      state.selectedLeads = action.payload.leads;
      state.loading = 'idle';
      state.error = null;
    },
    [resetSelectedLeads.fulfilled]: (state) => {
      state.selectedLeads = [];
      state.releasedLeads = [];
      state.loading = 'idle';
      state.error = null;
    },
    [setAllLeads.fulfilled]: (state, action) => {
      state.selectedLeads = action.payload.leads;
      state.error = null;
    },
    [setAllLeads.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [setLeads.fulfilled]: (state, action) => {
      state.selectedLeads = action.meta.arg;
      state.error = null;
    },
    [setReleasedLeads.fulfilled]: (state, action) => {
      state.releasedLeads = action.payload;
      state.error = null;
    },
    [undoReleaseLeads.fulfilled]: (state, action) => {
      state.selectedLeads = action.payload;
      state.releasedLeads = [];
      state.error = null;
    },
  }
});


export default leadsSlice.reducer;
