import { makeStyles } from 'tss-react/mui';

const appStyles = makeStyles()({
  footer: {
    color: '#ffffff',
    paddingTop: 15,
  },
});

export default function Footer(props) {
  const { loggedIn, apiUrl } = props;
  const subdomain = apiUrl.split('.')[0].slice(8);
  const {classes} = appStyles();

  return (
    <>
      {loggedIn && apiUrl !== "https://leadstheway.net"  && (
        <footer className={ classes.footer }>
          This is a test environment, using subdomain <em>{subdomain}</em>
        </footer>
      )}
    </>
  );

}
