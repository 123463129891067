import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { fetchAllSetLeads } from "../../Store/SelectedLeads";
import { CircularProgress, } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import ReviewLeadTools from './ReviewLeadTools';
import { themeBreakPoints, defaultTableOptions } from "../LeadsTableTheme";

import '../Dashboard/filter.css';

const tableStyleOptions = {
  components: {
    MUIDataTable:{
      styleOverrides: {
        paper: {
          borderRadius: 14,
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        responsiveStackedSmallParent: {
          [themeBreakPoints.breakpoints.down('md')]: {
            margin: '5px 10px',
          }
        }
      }
    },
  }
}

const leadsTableTheme = () => createTheme(deepmerge(defaultTableOptions, tableStyleOptions));

export default function ReviewLeads() {

  const dispatch = useDispatch();
  const taxonomy = useSelector(state => state.taxonomy);
  const user = useSelector(state => state.user);
  const leadSet = useSelector(state => state.reviewLeadSet).leadSet;
  const selectedLeads = useSelector(state => state.selectedLeads);
  const underReview = useSelector(state => state.reviewLeadSet).review;

  const [ filteredLeads, setFilteredLeads ] = useState([]);
  const [ initialLoad, setInitialLoad ] = useState(true);
  const [ loading, setLoading ] = useState(true);
  const [ leadSetStatus, setLeadSetStatus ] = useState('request');

  useEffect(() => {
    if (initialLoad) {
      if (leadSet && (!selectedLeads || selectedLeads.selectedLeads.length === 0)) {
        const leadIds = leadSet.relationships.leads.data;
        if(leadIds) {
          setLoading(true);
          dispatch(fetchAllSetLeads(leadIds));
          }
      }
      if(leadSet && !leadSet.attributes.date_approved) {
        setLeadSetStatus('underReview');
      }
      else if (leadSet && leadSet.attributes.date_approved) {
        setLeadSetStatus('revising');
      }
      setInitialLoad(false);
    }
  }, [dispatch, initialLoad, leadSet, selectedLeads]);

  useEffect(() => {
    if(selectedLeads && selectedLeads.loading === 'idle') {
      setFilteredLeads(selectedLeads.selectedLeads);
      setLoading(false);
    };
    if(!selectedLeads || (selectedLeads && selectedLeads.loading === 'pending') ) setLoading(true);
  }, [selectedLeads]);

  let columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      }
    },
    {
      name: "nid",
      label: "nid",
      options: {
        filter: false,
        display: false,
      }
    },
    {
      name: "zip",
      label: 'Zip Code',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "dts",
      label: "Call DTS",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "county",
      label: "County",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "stateId",
      label: "State",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if(taxonomy.states){
            let val = taxonomy.states.filter(p => p.id === value);
            if (val !== null && val.length > 0) {
              return val[0].name;
            }
          }
          return '';
        },
        filterType: 'multiselect',
        filterOptions: {
          fullWidth: true,
        },
      }
    },
    {
      name: "leadTypeId",
      label: "Lead Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const nid = tableMeta.rowData[tableMeta.columnIndex - 6];
          const lead = filteredLeads.find(lead => lead.nid === nid);

          if(lead.leadTypeLabel) return lead.leadTypeLabel;
          if(taxonomy.leadTypes){
            let val = taxonomy.leadTypes.filter(p => p.id === value);
            if (val !== null && val.length > 0) {
              return val[0].type;
            }
          }
          return '';
        },
        filterType: 'multiselect',
        filterOptions: {
          fullWidth: true,
        },
      }
    },
    {
      name: "dropCode",
      label: "DM Drop Code",
      options: {
        filter: false,
        sort: true
      }
    },
  ];
  
  if (user.storedUser && user.storedUser.user_role === 'sl_lead_dept') {
    const leadDeptColumns = [
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'address',
        label: 'Address',
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: 'comments',
        label: 'Comments',
        options: {
          filter: false,
          sort: false,
        }
      },
    ];

    columns = leadDeptColumns.concat(columns);
    columns.push({
      name: "price",
      label: "Price",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value ? value : 0);
        }
      }
    });
  }

  const options = {
    download: true,
    downloadOptions: {
      filename: 'LeadSetLeads.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
      }
    },
    rowsPerPageOptions: [10, 50, 100, 200, 300],
    rowsSelected: [],
    search: false,
    selectableRowsOnClick: underReview,
    selectableRowsHideCheckboxes: !underReview,
    viewColumns: true,
    customToolbarSelect: selectedRows => {
      if(underReview) {
        return (<ReviewLeadTools
          selectedRows={selectedRows}
          rows={filteredLeads}
          dispatch={dispatch}
          userRole={user.storedUser.user_role}
          leadSetStatus={leadSetStatus}
          selectedLeadsState={selectedLeads}
        />);
      }
      else {return null;}
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const stateColumn = columns.findIndex(column => column.name === 'stateId');
      const leadTypeColumn = columns.findIndex(column => column.name === 'leadTypeId');

      const mappedData = data.map(row => {
        let newRow = {
          ...row
        };

        if(taxonomy.states && stateColumn){
          let val = taxonomy.states.filter(p => p.id === row.data[stateColumn]);
          if (val !== null && val.length > 0) {
            newRow.data[stateColumn] = val[0].name;
          }
        }

        if(taxonomy.states && leadTypeColumn){
          let val = taxonomy.leadTypes.filter(p => p.id === row.data[leadTypeColumn]);
          if (val !== null && val.length > 0) {
            newRow.data[leadTypeColumn] = val[0].type;
          }
        }

        return newRow;
      });

      return buildHead(columns) + buildBody(mappedData);
    },
  };

  return (
    <div className="leadsDashboard">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={leadsTableTheme()}>
          <MUIDataTable
            title={"Review Leads"}
            data={filteredLeads}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </StyledEngineProvider>
      {loading && (
        <div className="progressWrapper" >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
