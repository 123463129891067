import { format } from 'date-fns';
import PaginatedApi from './PaginatedApi';

const API_URL = process.env.REACT_APP_API_URL;

export default class LeadApi extends PaginatedApi {

  static async fetchLeads(options = {}, user) {
    // console.debug('lead api options', options);
    if(options.leadSetLeads && options.leadSetLeads.length === 0) return [];

    let newOptions = {...options};

    if(!newOptions.userRole) {
      newOptions.userRole = user.storedUser.user_role;
    }

    let response = {
      leads: null,
      next: null,
      previous: null,
      options: newOptions,
    }

    const fetchHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.storedUser.access_token
      }
    }
    console.log(user.storedUser.access_token)
    // create the query filter.
    let queryFilter = this.createQueryFilter(newOptions, user);
    
    let leadJson = [];
    if(newOptions.leadSetLeads !== undefined) {
      let leadResponse = await fetch(API_URL + `/node/leads?${queryFilter}`, fetchHeader);
      leadJson = await leadResponse.json();
    }
    else {
      const userPath = (newOptions.userRole === 'sl_lead_dept' ? 'sl' : 'manager');
      let leadResponse = await fetch(API_URL + `/${userPath}/leads/?${queryFilter}`, fetchHeader);
      console.log(API_URL + `/${userPath}/leads/?${queryFilter}`);
      leadJson = await leadResponse.json();
    }


    // console.debug('leadJson.data', leadJson.data);

    response.leads = this.mapLeads(leadJson, user);

    // get the next/previous links.
    if (leadJson.links !== undefined && leadJson.links.next !== undefined) {
      response.options.next = leadJson.links.next.href;
    } else {
      response.options.next = null;
    }
    if (leadJson.links !== undefined && leadJson.links.prev !== undefined) {
      response.options.previous = leadJson.links.prev.href;
    } else {
      response.options.previous = null;
    }
    if (leadJson.meta !== undefined && leadJson.meta.count !== undefined) {
      response.options.items = parseInt(leadJson.meta.count, 10);
    } else {
      response.options.items = -1;
    }

    return response;
  }

  static createQueryFilter(options, user) {
    let filters = [];
    // console.debug('lead api query options', options);
    
    if(options.userRole === 'sl_lead_dept')  {

      // filter for trash
      if (options.trash !== undefined) {
        filters.push(`filter[field_trash]=${options.trash}`);
      }

      // filter dnc if defined
      if (options.dnc !== undefined) {
        filters.push(`filter[field_do_not_call]=${options.dnc}`);
      }

      // filter dnm if defined
      if (options.dnm !== undefined) {
        filters.push(`filter[field_do_not_mail]=${options.dnm}`);
      }
    }

    // only use flexible location filter if county, city, and zipcode filters are left blank.
    if (options.location !== undefined && options.county === undefined && options.city === undefined && options.zip === undefined) {  
      filters.push(`filter[location-group][group][conjunction]=OR`);

      // Split up the filter string into array.
      let locationValues = options.location.replaceAll(', ', ',');
      filters.push(`filter[location-group][value]=${locationValues.trim()}`);
    }
    
    // filter ani if defined
    if (options.ani !== undefined) {
      filters.push(`filter[ani][condition][path]=field_ani`);
      filters.push(`filter[ani][condition][operator]=CONTAINS`);
      filters.push(`filter[ani][condition][value]=${options.ani}`);
    }

    // filter phone if defined
    if (options.phone !== undefined) {
      filters.push(`filter[phone][condition][path]=field_phone`);
      filters.push(`filter[phone][condition][operator]=CONTAINS`);
      filters.push(`filter[phone][condition][value]=${options.phone}`);
    }

    // filter first name if defined
    if (options.firstName !== undefined) {
      filters.push(`filter[firstName][condition][path]=field_first_name`);
      filters.push(`filter[firstName][condition][operator]=CONTAINS`);
      filters.push(`filter[firstName][condition][value]=${options.firstName}`);
    }

    // filter last name if defined
    if (options.lastName !== undefined) {
      filters.push(`filter[lastName][condition][path]=field_last_name`);
      filters.push(`filter[lastName][condition][operator]=CONTAINS`);
      filters.push(`filter[lastName][condition][value]=${options.lastName}`);
    }

    // filter zip code if defined
    if (options.zip !== undefined) {
      filters.push(`filter[zip][condition][path]=field_zip`);
      filters.push(`filter[zip][condition][operator]=CONTAINS`);
      filters.push(`filter[zip][condition][value]=${options.zip}`);
    }

    // filter county if defined
    if (options.county !== undefined) {
      filters.push(`filter[county][condition][path]=field_address2`);
      filters.push(`filter[county][condition][operator]=CONTAINS`);
      filters.push(`filter[county][condition][value]=${options.county}`);
    }

    // filter address if defined
    if (options.address !== undefined) {
      filters.push(`filter[address][condition][path]=field_address`);
      filters.push(`filter[address][condition][operator]=CONTAINS`);
      filters.push(`filter[address][condition][value]=${options.address}`);
    }

    // filter as400Id
    if (options.as400id !== undefined) {
      filters.push(`filter[as400id][condition][path]=field_as400_id`);
      filters.push(`filter[as400id][condition][operator]=CONTAINS`);
      filters.push(`filter[as400id][condition][value]=${options.as400id}`);
    }

    // filter complete if defined
    if (options.complete !== undefined) {
      filters.push(`filter[field_complete]=${options.complete}`);
    }

    //add city filter if defined
    if(options.city !== undefined) {
      filters.push(`filter[city][condition][path]=field_city`);
      filters.push(`filter[city][condition][operator]=CONTAINS`);
      filters.push(`filter[city][condition][value]=${options.city}`);
    }

    // filter DM Drop Code if defined
    if (options.dropCode !== undefined) {
      filters.push(`filter[dropCode][condition][path]=field_dm_drop_code`);
      filters.push(`filter[dropCode][condition][operator]=CONTAINS`);
      filters.push(`filter[dropCode][condition][value]=${options.dropCode}`);
    }

    // filter email if defined
    if (options.email) {
      filters.push(`filter[email][condition][path]=field_email`);
      filters.push(`filter[email][condition][operator]=CONTAINS`);
      filters.push(`filter[email][condition][value]=${options.email}`);
    }

    // filter call DTS if defined
    if (options.dts) {
      // const filterDate = format(new Date(options.dts), 'yyyy/MM/dd');
      filters.push(`filter[dts][condition][path]=field_call_dts`);
      filters.push(`filter[dts][condition][operator]=CONTAINS`);
      filters.push(`filter[dts][condition][value]=${options.dts}`);
    }
    
    // add the state query filter if defined.
    if (options.states !== undefined && options.states.length > 0) {
      filters.push(`filter[state][condition][path]=field_state.id`);
      filters.push(`filter[state][condition][operator]=IN`);
      options.states.forEach((item, index) => {
        filters.push(`filter[state][condition][value][${index + 1}]=${item.id}`);
      });
    }

    // add the lead type query filter if defined.
    if (options.leadTypes !== undefined && options.leadTypes.length > 0) {
      filters.push(`filter[lead_type][condition][path]=field_lead_type.id`);
      filters.push(`filter[lead_type][condition][operator]=IN`);
      options.leadTypes.forEach((item, index) => {
        filters.push(`filter[lead_type][condition][value][${index + 1}]=${item.id}`);
      });
    }

    // add the inventory status filter if defined
    if(options.inventoryStatus !== undefined && options.inventoryStatus.length > 0) {

      filters.push(`filter[inventory][condition][path]=field_inventory_status`);
      filters.push(`filter[inventory][condition][operator]=IN`)
      
      options.inventoryStatus.forEach((item, index) => {
        let status = item.toLowerCase();
        if(status === 'not available') status = 'not_available';
        filters.push(`filter[inventory][condition][value][${index + 1}]=${status}`);
      }); 
    }

    //date created filter
    if(options?.created?.min) {
      filters.push(`filter[min_date][condition][path]=created`);
      filters.push(`filter[min_date][condition][value]=${options.created.min}`);
      filters.push(`filter[min_date][condition][operator]=%3E`);
    }

    if(options?.created?.max) {
      filters.push(`filter[max_date][condition][path]=created`);
      filters.push(`filter[max_date][condition][value]=${options.created.max}`);
      filters.push(`filter[max_date][condition][operator]=%3C`);
    }
    
    // add the manager inventory query filter if defined.
    if (options.managerInventory) {
      filters.push(`filter[managers][condition][path]=field_manager_inventory.id`)
      filters.push(`filter[managers][condition][operator]=IN`);
      options.managerInventory.forEach((item, index) => {
        filters.push(`filter[managers][condition][value][${index + 1}]=${item.id}`);
      });
    }

    //build leads for a leadset
    if(options.leadSetLeads !== undefined) {
      filters.push(`filter[leadset][condition][path]=id`);
      filters.push(`filter[leadset][condition][operator]=IN`);
      options.leadSetLeads.forEach((item, index) => {
        filters.push(`filter[leadset][condition][value][${index + 1}]=${item.id}`);
      });
    }

    // add the sorting.
    if (options.sort !== undefined) {
      let field = '';
      switch (options.sort.name) {
        case 'city': {
          field = 'field_city'
          break;
        }
        case 'zip': {
          field = 'field_zip';
          break;
        }
        case 'stateId': {
          field = 'field_state.name';
          break;
        }
        case 'leadTypeId': {
          field = 'field_lead_type.name';
          break;
        }
        case 'dropCode': {
          field = 'field_dm_drop_code';
          break;
        }
        case 'county': {
          field = 'field_address2';
          break;
        }
        case 'created': {
          field = 'created';
          break;
        }
        case 'inventoryStatus': {
          field = 'field_inventory_status';
          break;
        }
        case 'firstName': {
          field = 'field_first_name';
          break;
        }
        case 'lastName': {
          field = 'field_last_name';
          break;
        }
        case 'email': {
          field = 'field_email';
          break;
        }
        case 'address': {
          field = 'field_address';
          break;
        }
        case 'complete': {
          field = 'field_complete';
          break;
        }
        case 'dnc': {
          field = 'field_do_not_call';
          break;
        }
        case 'dnm': {
          field = 'field_do_not_mail';
          break;
        }
        case 'phone': {
          field = 'field_phone';
          break;
        }
        case 'dts': {
          field = 'field_call_dts';
          break;
        }
        case 'nid' : {
          field = 'nid';
          break;
        }
        default: { 
          console.error('Unsupported sort field');
          break; 
        }
      }
      if (field && !options.sort.asc) {
        field = '-' + field;
      }
      if (field){
        filters.push(`sort=${field}`);
      }
    }

    // add the page size.
    if (options.itemsPerPage !== undefined) {
      filters.push(`page[limit]=${options.itemsPerPage}`);
    } else {
      filters.push(`page[limit]=50`);
    }

    // add the offset.
    if (options.page !== undefined) {
      let itemsPerPage = 50;
      if (options.itemsPerPage !== undefined) {
        itemsPerPage = options.itemsPerPage;
      }
      let offset = itemsPerPage * options.page;
      filters.push(`page[offset]=${offset}`);
    }

    let queryFilter = filters.join('&');
    return queryFilter;
  }
  static getAttribute(lead){
    return lead.attributes.attributes?lead.attributes:lead;
  }
  static getRelationship(lead){
    return lead.attributes.relationships?lead.attributes:lead;
  }
  static mapLeads(leadJson, user) {
    let leads = [];
    if(leadJson.data?.length === 0) return leads;

    if(user.storedUser.user_role === 'sl_lead_dept') {
      leads = leadJson.data.map(lead => ({
        
        //same as RVP below
        id: lead.id,
        nid: this.getAttribute(lead).attributes.drupal_internal__nid,
        dts: (this.getAttribute(lead).attributes.field_call_dts ? this.getAttribute(lead).attributes.field_call_dts : ''), //this.tryFormat(lead.attributes.field_call_dts.replace(/-/g, '/')) 
        city: this.getAttribute(lead).attributes.field_city,
        complete: this.getAttribute(lead).attributes.field_complete,
        county: this.getAttribute(lead).attributes.field_address2,
        zip: this.getAttribute(lead).attributes.field_zip,
        leadTypeId: (this.getRelationship(lead).relationships.field_lead_type.data ? this.getRelationship(lead).relationships.field_lead_type.data.id : false),
        leadTypeLabel: this.getAttribute(lead).attributes.field_lead_type_label,
        managerInventory: (this.getRelationship(lead).relationships.field_manager_inventory.data ? this.getRelationship(lead).relationships.field_manager_inventory.data.id : ''),
        stateId: (this.getRelationship(lead).relationships.field_state.data ? this.getRelationship(lead).relationships.field_state.data.id : ''),
        dropCode: this.getAttribute(lead).attributes.field_dm_drop_code,

        // only leads see these
        ani: this.getAttribute(lead).attributes.field_ani,
        address: this.getAttribute(lead).attributes.field_address,
        comments: this.getAttribute(lead).attributes.field_comments,
        created: this.getAttribute(lead).attributes.created?format(new Date(this.getAttribute(lead).attributes.created), 'MM/dd/yyyy'):null,
        dnc: (this.getAttribute(lead).attributes.field_do_not_call ? 'DNC' : ''),
        dnm: (this.getAttribute(lead).attributes.field_do_not_mail ? 'DNM' : ''),
        email: this.getAttribute(lead).attributes.field_email,
        inventoryStatus: this.getAttribute(lead).attributes.field_inventory_status,
        firstName: this.getAttribute(lead).attributes.field_first_name,
        lastName: this.getAttribute(lead).attributes.field_last_name,
        mailImage: (this.getRelationship(lead).relationships.field_mail_image?.data ? this.getRelationship(lead).relationships.field_mail_image.data.meta.url : null),
        phone: this.getAttribute(lead).attributes.field_phone,
        price: this.getAttribute(lead).attributes.field_price,
        as400Id: this.getAttribute(lead).attributes.field_as400_id
      }));
    }
    else if (user.storedUser.user_role === 'manager' || user.storedUser.user_role === 'rvp') {
      leads = leadJson.data.map(lead => ({
        id: lead.id,
        nid: this.getAttribute(lead).attributes.drupal_internal__nid,
        dts: (this.getAttribute(lead).attributes.field_call_dts ? this.getAttribute(lead).attributes.field_call_dts : ''), //this.tryFormat(lead.attributes.field_call_dts.replace(/-/g, '/'))
        city: this.getAttribute(lead).attributes.field_city,
        county: this.getAttribute(lead).attributes.field_address2,
        zip: this.getAttribute(lead).attributes.field_zip,
        leadTypeId: (this.getRelationship(lead).relationships.field_lead_type.data ? this.getRelationship(lead).relationships.field_lead_type.data.id : false),
        leadTypeLabel: this.getAttribute(lead).attributes.field_lead_type_label,
        managerInventory: (this.getRelationship(lead).relationships.field_manager_inventory.data ? this.getRelationship(lead).relationships.field_manager_inventory.data.id : ''),
        stateId: (this.getRelationship(lead).relationships.field_state.data ? this.getRelationship(lead).relationships.field_state.data.id : ''),
        dropCode: this.getAttribute(lead).attributes.field_dm_drop_code,
        complete: this.getAttribute(lead).attributes.field_complete,
        inventoryStatus: this.getAttribute(lead).attributes.field_inventory_status,
        as400Id: this.getAttribute(lead).attributes.field_as400_id
      }));
    }
    else {
      leads = leadJson.data.map(lead => ({
        id: lead.id,
        nid: this.getAttribute(lead).attributes.drupal_internal__nid,
        dts: (this.getAttribute(lead).attributes.field_call_dts ? lead.attributes.field_call_dts : ''), //this.tryFormat(lead.attributes.field_call_dts.replace(/-/g, '/'))
        city: this.getAttribute(lead).attributes.field_city,
        county: this.getAttribute(lead).attributes.field_address2,
        zip: this.getAttribute(lead).attributes.field_zip,
        leadTypeId: (this.getRelationship(lead).relationships.field_lead_type.data ? this.getRelationship(lead).relationships.field_lead_type.data.id : false),
        leadTypeLabel: this.getAttribute(lead).attributes.field_lead_type_label,
        managerInventory: (this.getRelationship(lead).relationships.field_manager_inventory.data ? this.getRelationship(lead).relationships.field_manager_inventory.data.id : ''),
        stateId: (this.getRelationship(lead).relationships.field_state.data ? this.getRelationship(lead).relationships.field_state.data.id : ''),
        dropCode: this.getAttribute(lead).attributes.field_dm_drop_code,
        complete: this.getAttribute(lead).attributes.field_complete,
      }));
    }

    return (leads);
  }

  /**
   * Fetches all that match the query using paginated fetch, for generating CSV.
   */
  static async fetchAllLeads(options = {}, user) {
    let newOptions = {...options, itemsPerPage: 300,  sort: {name: 'nid', asc: true} };

    if(!newOptions.userRole) {
      newOptions.userRole = user.storedUser.user_role;
    }

    let response = {
      leads: null,
    }

    const fetchHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.storedUser.access_token
      }
    }

    let queryFilter = this.createQueryFilter(newOptions, user);

    const userPath = (newOptions.userRole === 'sl_lead_dept' ? 'sl' : 'manager');
    let leadJson = {};
    const fetchUrl = API_URL + `/${userPath}/leads/?${queryFilter}`;
    leadJson.data = await this.fetchPaginated(fetchUrl, fetchHeader);

    response.leads = this.mapLeads(leadJson, user);

    return response;
  }

  static async fetchAllSetLeads(leadIds, user) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: leadIds,
      }),
    }

    let leadResponse = await fetch(API_URL + `/fetch_all_set_leads`, requestOptions);
    let responseJson = await leadResponse.json();
    let response = {'leads': []};

    response.leads = this.mapLeads(responseJson, user);

    return response;
  }

  /**
   * Fetches up to 500 leads that match the current query.
   * Used for creating large lead sets.
   */
  static async fetchAllQuery(options, user, rejectWithValue) {
    let newOptions = {...options, itemsPerPage: 500 };


    if(!newOptions.userRole) {
      newOptions.userRole = user.storedUser.user_role;
    }

    let response = {
      leads: null,
      next: null,
      previous: null,
      options: newOptions,
    }

    const fetchHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.storedUser.access_token
      }
    }

    // create the query filter.
    let queryFilter = this.createQueryFilter(newOptions, user);
    queryFilter += `fields[leads]=id,field_price`;

    const userPath = (newOptions.userRole === 'sl_lead_dept' ? 'sl' : 'manager');

    // query the data.
    try {
      let leadResponse = await fetch(API_URL + `/${userPath}/leads/?${queryFilter}`, fetchHeader);
      let leadJson = await leadResponse.json();

      response.leads = this.mapLeads(leadJson, user);

      return response;
    }
    catch (err) {
      console.error('fetchAllQuery API error', err);
      return rejectWithValue(err);
    }
  }

  static async reserveLeads(state, dispatch) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + state.user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        leads: state.selectedLeads,
        chargeAgents: state.chargeTo,
      }),
    }

    let response = await fetch(API_URL + `/reserve_leads`, requestOptions);
    let responseJson = await response.json();
    return responseJson;
  }

  static async dncLeads(leads, user) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        leads: leads,
      }),
    }

    let response = await fetch(API_URL + `/leads/dnc`, requestOptions);
    let responseJson = await response.json();
    return responseJson;
  }

  static async dnmLeads(leads, user) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        leads: leads,
      }),
    }

    let response = await fetch(API_URL + `/leads/dnm`, requestOptions);
    let responseJson = await response.json();
    return responseJson;
  }

  static async duplicateLeads(leads, user) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        leads: leads,
      }),
    }

    let response = await fetch(API_URL + `/leads/duplicates`, requestOptions);
    let responseJson = await response.json();
    return responseJson;
  }

  static async recoverTrashLeads(leads, user) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        leads: leads,
      }),
    }

    let response = await fetch(API_URL + `/leads/trash-recover`, requestOptions);
    let responseJson = await response.json();
    return responseJson;
  }

  static async trashLeads(leads, user) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        leads: leads,
      }),
    }

    let response = await fetch(API_URL + `/leads/trash`, requestOptions);
    let responseJson = await response.json();
    return responseJson;
  }

  static async assignLeads(leads, rvp, user) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        leads: leads,
        manager: rvp,
      }),
    }

    let response = await fetch(API_URL + `/leads/assign`, requestOptions);
    let responseJson = await response.json();

    return responseJson;
  }

  static async reassignLeads(leads, user) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        leads: leads
      }),
    }

    let response = await fetch(API_URL + `/leads/reassign`, requestOptions);
    let responseJson = await response.json();

    return responseJson;
  }

  static tryFormat(date) {
    try {
      return format(new Date(date), 'MM/dd/yyy');
    }
    catch(err) {
      console.warn('Lead date formatting error:', date);
      return date;
    }
  }
}


