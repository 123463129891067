const API_URL = process.env.REACT_APP_API_URL;

export default class LeadSetApi {

  static async fetchLeadSet(leadSetId, dispatch) {
    const state = dispatch.getState();

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.user.storedUser.access_token,
      },
    }

    try {
      let response = await fetch(API_URL + '/lead_set/' + leadSetId, requestOptions);
      let responseJson = await response.json();

      if(responseJson.errors) {
        return dispatch.rejectWithValue(responseJson.errors.message);
      }

      return {
        message: "Fulfilled",
        name: "Lead Set View",
        leadSet: responseJson.data,
      }
    }
    catch (err) {
      return dispatch.rejectWithValue(err);
    }
  }

  static async reviewLeadSet(leadSetId, dispatch) {

    const state = dispatch.getState();

    const data = {
      'data': {
        'type': 'lead_set',
        'id': leadSetId,
        'uid': state.user.storedUser.current_user.uid,
      }
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.user.storedUser.access_token,
      },
      body: JSON.stringify(data),
    }

    try {
      let response = await fetch(API_URL + '/lead_set/review', requestOptions);
      let responseJson = await response.json();

      if(responseJson.errors) {
        return dispatch.rejectWithValue(responseJson.errors.message);
      }

      return {
        message: "Fulfilled",
        name: "Lead Set Review",
        leadSet: responseJson.data,
      }
    }
    catch (err) {
      return dispatch.rejectWithValue(err);
    }
  }

  static async reviewPendingLead(leadId, dispatch) {

    const state = dispatch.getState();

    const data = {
      'data': {
        'type': 'lead',
        'id': leadId,
        'uid': state.user.storedUser.current_user.uid,
      }
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.user.storedUser.access_token,
      },
      body: JSON.stringify(data),
    }

    try {
      let response = await fetch(API_URL + '/lead_set/review', requestOptions);
      let responseJson = await response.json();

      if(responseJson.errors) {
        return dispatch.rejectWithValue(responseJson);
      }

      return {
        message: "Fulfilled",
        name: "Lead Set Review",
        leadSet: responseJson.data,
      }
    }
    catch (err) {
      return dispatch.rejectWithValue(err);
    }
  }

  static async releaseLeads(leadIds, dispatch) {
    const state = dispatch.getState();
    const postData = {
      data: {
        leadSetId: state.reviewLeadSet.leadSet.id,
        leadIds: leadIds,
      }
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + state.user.storedUser.access_token,
      },
      body: JSON.stringify(postData),
    }

    try {
      let response = await fetch(API_URL + '/lead_set/remove_leads', requestOptions);
      let responseJson = await response.json();

      if(responseJson.errors) {
        return dispatch.rejectWithValue([responseJson.status,  responseJson.title]);
      }

      return {
        message: "Fulfilled",
        name: "Leads Removed",
        leadSet: responseJson.data,
      }
    }
    catch (err) {
      return dispatch.rejectWithValue(err);
    }

  }

  static async updateLeadSet(submission, dispatch) {
    const state = dispatch.getState();

    let postData = {
      'data': {
        'leadSetOriginal': submission.leadSet,
        'updateLeads': state.selectedLeads.selectedLeads,
        'updatedFields': {
          'agent': submission.agent,
          'agentNumber': submission.agentNumber,
          'comments': submission.comments,
          'manager': submission.manager,
          'chargeAgents': submission.chargeTo
        }
      }
    }

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.user.storedUser.access_token,
      },
      body: JSON.stringify(postData),
    }

    try {
      let response = await fetch(API_URL + '/lead_set/update', requestOptions);
      let responseJson = await response.json();

      return responseJson;
    }
    catch (err) {
      console.error('updateLeadSet API error', err);
      return dispatch.rejectWithValue(err);
    }
  }

  static async createLeadSet(state, reserved, dispatch) {

    const date = new Date();
    const formattedDate =  ' ' + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + date.getHours() + date.getMinutes();

    const leadsArray = reserved.assignedLeads.map(leadId => {
      return {
        'type': 'lead',
        'id': leadId,
      }
    });
    const managerArray = {
      'type': 'user',
      'id': state.manager.uuid,
    }
    const chargeArray = reserved.chargedAgents.map(agentId => {
      return {
        type: 'charged_agent',
        id: agentId,
      };
    })

    const data = {
      'data': {
        'type': 'lead_set',
        'attributes': {
          'title': state.agent + formattedDate,
          'assigned_agent': state.agent,
          'agent_number': state.agentNumber,
          'comments': state.comments,
          'field_set_default_price': 0,
        },
        'relationships': {
          'leads': {
            'data': leadsArray,
          },
          'manager': {
            'data': managerArray,
          },
          'charged_agents': {
            'data': chargeArray,
          }
        }
      }
    }
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + state.user.storedUser.access_token,
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      },
      body: JSON.stringify(data),
    }

    try {
      let response = await fetch(API_URL + '/lead_set', requestOptions);
      let responseJson = await response.json();
      if(responseJson.errors) {
        return dispatch.rejectWithValue([responseJson.status,  responseJson.title]);
      }

      return {
        message: "Fulfilled",
        name: "Lead Set Created",
        uuid: responseJson.data.id,
        leadCount: leadsArray.length,
      }
    }
    catch (err) {
      console.error('lead set creation error', err);
      return dispatch.rejectWithValue(err);
    }

  };

  static async fetchLeadSets(options = {}, getState) {

    let response = {
      leadSets: null,
      next: null,
      previous: null,
      options: options,
    }

    const fetchHeader = {
      headers: {
        'Authorization': 'Bearer ' + getState().user.storedUser.access_token
      }
    }

    // create query filter
    let queryFilter = this.createQueryFilter(options);

    // query the data
    let leadSetResponse = await fetch(API_URL + `/lead_set?${queryFilter}`, fetchHeader);
    let leadSetJson = await leadSetResponse.json();

    // map the response
    response.leadSets = leadSetJson.data.map(leadSet => ({
      id: leadSet.id,
      assignedAgent: leadSet.attributes.assigned_agent,
      title: leadSet.attributes.title,
      leadsCount: leadSet.relationships.leads.data.length,
      status: leadSet.attributes.field_lead_set_status,
      author: leadSet.relationships.author.data.meta.name,
      authorId: leadSet.relationships.author.data.id,
      created: new Date(leadSet.attributes.created).toLocaleDateString("en-US"),
      dateApproved: (leadSet.attributes.date_approved ? new Date(`${leadSet.attributes.date_approved}T00:00:00-06:00`).toLocaleDateString("en-US") : null),
    }));

    // get the next/previous links.
    if (leadSetJson.links !== undefined && leadSetJson.links.next !== undefined) {
      response.options.next = leadSetJson.links.next.href;
    } else {
      response.options.next = null;
    }
    if (leadSetJson.links !== undefined && leadSetJson.links.prev !== undefined) {
      response.options.previous = leadSetJson.links.prev.href;
    } else {
      response.options.previous = null;
    }
    if (leadSetJson.meta !== undefined && leadSetJson.meta.count !== undefined) {
      response.options.items = parseInt(leadSetJson.meta.count, 10);
    } else {
      response.options.items = -1;
    }

    return response;
  }

  static createQueryFilter(options) {
    let filters = [];

    // add the filters
    if(options.status !== undefined) {
      filters.push(`filter[field_lead_set_status]=${options.status}`);
    }

    if(options.authorId !== undefined) {
      filters.push(`filter[author.meta.drupal_internal__target_id]=${options.authorId}`);
    }

    if(options.assignedAgent !== undefined) {
      filters.push(`filter[firstName][condition][path]=field_assigned_agent`);
      filters.push(`filter[firstName][condition][operator]=CONTAINS`);
      filters.push(`filter[firstName][condition][value]=${options.assignedAgent}`);
    }

    if(options.dateApproved !== undefined) {
      if(options.dateApproved.min && options.dateApproved.max) {
        filters.push(`filter[approved_date][group][conjunction]=AND`);
        filters.push(`filter[min_date][condition][memberOf]=approved_date`);
        filters.push(`filter[max_date][condition][memberOf]=approved_date`);
      }

      if(options.dateApproved.min) {
        filters.push(`filter[min_date][condition][path]=field_date_approved`);
        filters.push(`filter[min_date][condition][value]=${options.dateApproved.min}`);
        filters.push(`filter[min_date][condition][operator]=%3E`);
      }

      if(options.dateApproved.max) {
        filters.push(`filter[max_date][condition][path]=field_date_approved`);
        filters.push(`filter[max_date][condition][value]=${options.dateApproved.max}`);
        filters.push(`filter[max_date][condition][operator]=%3C`);
      }
    }

    // add the sorting.
    if (options.sort !== undefined) {
      let field = '';
      switch (options.sort.name) {
        case 'assignedAgent': {
          field = 'assigned_agent';
          break;
        }
        case 'author': {
          field = 'uid.name';
          break;
        }
        case 'leadsCount': {
          field = 'field_leads_count';
          break;
        }
        case 'status': {
          field = 'field_lead_set_status';
          break;
        }
        case 'created': {
          field = 'created';
          break;
        }
        case 'title': {
          field = 'title';
          break;
        }
        default: { break; }
      }
      if (!options.sort.asc) {
        field = '-' + field;
      }
      if (field){
        filters.push(`sort=${field}`);
      }
    }

    // add the page size.
    if (options.itemsPerPage !== undefined) {
      filters.push(`page[limit]=${options.itemsPerPage}`);
    } else {
      filters.push(`page[limit]=50`);
    }

    // add the offset.
    if (options.page !== undefined) {
      let itemsPerPage = 50;
      if (options.itemsPerPage !== undefined) {
        itemsPerPage = options.itemsPerPage;
      }
      let offset = itemsPerPage * options.page;
      filters.push(`page[offset]=${offset}`);
    }

    const queryFilter = filters.join('&');
    return queryFilter;
  }

}
