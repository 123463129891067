import { useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { themeBreakPoints, defaultTableOptions } from "../LeadsTableTheme";

import '../Dashboard/filter.css';

const tableStyleOptions = {
  components: {
    MUIDataTable:{
      styleOverrides: {
        paper: {
          borderRadius: 14,
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        responsiveStackedSmallParent: {
          [themeBreakPoints.breakpoints.down('md')]: {
            margin: '5px 10px',
          }
        }
      }
    },
  }
}

const leadsTableTheme = () => createTheme(deepmerge(defaultTableOptions, tableStyleOptions));

export default function ReviewLeads() {
  const selectedLeads = useSelector(state => state.selectedLeads).selectedLeads;


  let columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      }
    },
    {
      name: "nid",
      label: "nid",
      options: {
        filter: false,
        display: false,
      }
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "county",
      label: "County",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "zip",
      label: 'Zip Code',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "dts",
      label: "Call DTS",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
        filterType: 'multiselect',
        filterOptions: {
          fullWidth: true,
        },
      }
    },
    {
      name: "leadTypeText",
      label: "Lead Type",
      options: {
        filter: true,
        sort: true,
        filterType: 'multiselect',
        filterOptions: {
          fullWidth: true,
        },
      }
    },
    {
      name: "dropCode",
      label: "DM Drop Code",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value ? value : 0);
        }
      }
    },
    {
      name: 'comments',
      label: 'Comments',
      options: {
        filter: false,
        sort: false,
      }
    },
  ];

  const options = {
    download: true,
    downloadOptions: {
      filename: 'LeadSetLeads.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
      }
    },
    rowsPerPageOptions: [10, 25, 50],
    rowsSelected: [],
    search: false,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: true,
  };

  return (
    <div className="leadsDashboard">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={leadsTableTheme()}>
          <MUIDataTable
            title={"Review Leads"}
            data={selectedLeads}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}
