import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchManagers } from '../../Store/Managers';
import { fetchAllTaxonomies } from '../../Store/Taxonomy';
import FilterArchivedLeadSets from './FilterArchivedLeadSets';

export default function LeadRequests() {

  const dispatch = useDispatch();
  const managers = useSelector(state => state.fetchManagers);
  const taxonomy = useSelector(state => state.taxonomy);
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (managers.loading !== 'fulfilled' && managers.loading !== 'fail') {
      dispatch(fetchManagers(user));
    }
    if (taxonomy.loading !== 'fulfilled' && taxonomy.loading !== 'fail') {
      dispatch(fetchAllTaxonomies());
    }
  }, [dispatch, managers.loading, taxonomy.loading, user]);

  return (
    <div>
      <h1>Search and Review Lead Sets</h1>
      <FilterArchivedLeadSets />
    </div>
  );
}
