import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LeadApi from '../Api/LeadApi';

export const fetchLeads = createAsyncThunk(
  'leads/fetchLeads',
  async (options, { getState, rejectWithValue }) => {
    const { leads, user, taxonomy } = getState();

    if(options === false) {
      return {
        leads: [],
        options: leads.options,
      };
    }

    if(!updateLeadState(leads.options, options)) return leads;

    try {
      let response = await LeadApi.fetchLeads(options, user);

      if(response.leads){
        response.leads.forEach(lead => {
          if(lead.price === null && taxonomy.pricing) {
            const pricingArray = taxonomy.pricing.filter(p => p.leadType === lead.leadTypeId);
            
            if (pricingArray !== null && pricingArray.length === 1) {
              lead.price = pricingArray[0].price;
            }
            else if (pricingArray !== null && pricingArray.length > 1) {
              let findPrice = pricingArray.filter(p => p.state === lead.stateId);

              if(findPrice.length === 0){
                findPrice = pricingArray.filter(p => p.state === null );
              }
              else {
                if(!lead.leadTypeLabel) {
                  lead.leadTypeLabel = `DM-${findPrice[0].price.match(/(\d+)/)[0]}`;
                }
              }
              lead.price = findPrice[0].price;
            }
          }
        });
      }
      
      return response;
    } catch (err) {
      console.error('fetchLeads store error:', err);
      return rejectWithValue(err.message);
    }
  }
);

/**
 * Uses recursive API calls to fetch all leads that match a filter, prepared for CSV download.
 */
export const fetchAllLeads = createAsyncThunk(
  'leads/fetchAllLeads',
  async(options, { getState, rejectWithValue }) => {
    const {fetchManagers, taxonomy, user} = getState();

    try {
      const leadsResponse = await LeadApi.fetchAllLeads(options, user);
      const getStateName = (lead) => {
        if(taxonomy.states){
          let val = taxonomy.states.filter(p => p.id === lead.stateId);
          if (val !== null && val.length > 0) {
            return val[0].name;
          }
        }
        return '';
      }

      const getLeadType = (lead) => {
        if(lead.leadTypeLabel) return lead.leadTypeLabel;
        if(taxonomy.leadTypes){
          let val = taxonomy.leadTypes.filter(p => p.id === lead.leadTypeId);
          if (val !== null && val.length > 0) {
            return val[0].type;
          }
        }
        return '';
      }      

      const getManagerName = (lead) => {
        if(fetchManagers.managers && lead.managerInventory) {
          let val = Object.values(fetchManagers.managers).filter(p => p.uuid === lead.managerInventory);
          if (val !== null && val.length > 0) {
            return val[0].name;
          }
        }
        return '';
      }

      const leads = leadsResponse.leads.map(lead => {
        return {
          ...lead,
          stateName: getStateName(lead),
          leadType: getLeadType(lead),
          manager: getManagerName(lead),
        }
      });

      const includeHeaders = options.columns.filter(column => {
        return column.display === 'true';
      });

      let headers = includeHeaders.map(column => {
        let columnKey = column.name;
        let  columnLabel = column.label;

        switch (column.name) {
          case 'leadTypeId':
            columnKey = 'leadType';
            break;
          case 'managerInventory':
            columnKey = 'manager';
            break;
          case 'stateId':
            columnKey = 'stateName';
            break;
          default:
            break;
        }   
      
        return {
          label: columnLabel,
          key: columnKey,
        }
      });
      headers.push({label: 'ID', key: 'nid'});

      return {
        leads: leads,
        headers: headers,
      }
    }
    catch (err) {
      console.error('reserveLeads error', err);
      return rejectWithValue(err.message);
    }
  }
)

export const reserveLeads = createAsyncThunk(
  'leads/reserveLeads',
  async(leads, { getState, rejectWithValue }) => {
    const user = getState().user;
    try {
      const response = await LeadApi.reserveLeads(leads, user);
      return response;
    }
    catch (err) {
      console.error('reserveLeads error', err);
      return rejectWithValue(err.message);
    }
  }
);

export const reassignLeads = createAsyncThunk(
  'leads/reassignLeads',
  async({leads, options}, { getState, rejectWithValue }) => {
    const user = getState().user;
    try {
      const reassignResponse = await LeadApi.reassignLeads(leads, user);
      if(reassignResponse.success) {
        const fetchOptions = (options ? options : {} );
        const response = await LeadApi.fetchLeads(fetchOptions, user);
        return {leadResponse: response, reassigned: reassignResponse.reassigned_leads};
      }
      console.error('reassignReponse error: ', reassignResponse);
      return rejectWithValue('Reassigning leads failed.');
    }
    catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const trashLeads = createAsyncThunk(
  'leads/trashLeads',
  async({leads, options}, { getState, rejectWithValue }) => {
    const user = getState().user;
    try {
      const trashResponse = await LeadApi.trashLeads(leads, user);
      if(trashResponse.success) {
        const fetchOptions = (options ? options : {} );
        const response = await LeadApi.fetchLeads(fetchOptions, user);
        return {leadResponse: response, trashed: trashResponse.trashed_leads};
      }
      console.error('trashReponse error: ', trashResponse);
      return rejectWithValue('Trashing leads failed.');
    }
    catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const recoverTrashLeads = createAsyncThunk(
  'leads/recoverTrash',
  async({leads, options}, { getState, rejectWithValue }) => {
    const user = getState().user;
    try {
      const recoverResponse = await LeadApi.recoverTrashLeads(leads, user);
      if(recoverResponse.success) {
        const fetchOptions = (options ? options : {} );
        const response = await LeadApi.fetchLeads(fetchOptions, user);
        return {leadResponse: response, recovered: recoverResponse.recovered_leads};
      }
    }
    catch (err) {
      console.error("Trash recovery error", err);
      return rejectWithValue(err.message);
    }
  }
);

export const dncLeads = createAsyncThunk(
  'leads/dncLeads',
  async({leads, options}, {getState, rejectWithValue}) => {
    const user = getState().user;
    try {
      const dncResponse = await LeadApi.dncLeads(leads, user);
      if(dncResponse.success) {
        const fetchOptions = (options ?  options : {} );
        const response = await LeadApi.fetchLeads(fetchOptions, user);
        return {leadResponse: response, updateCount: dncResponse.updateCount};
      }
      console.error('dncResponse error: ', dncResponse);
      return rejectWithValue('Designating leads as Do Not Call failed.');
    }
    catch (err) {
      console.error('dncLeads error', err);
      return rejectWithValue(err.message);
    }
  }
);

export const dnmLeads = createAsyncThunk(
  'leads/dnmLeads',
  async({leads, options}, {getState, rejectWithValue}) => {
    const user = getState().user;
    try {
      const dnmResponse = await LeadApi.dnmLeads(leads, user);
      if(dnmResponse.success) {
        const fetchOptions = (options ?  options : {} );
        const response = await LeadApi.fetchLeads(fetchOptions, user);
        return {leadResponse: response, updateCount: dnmResponse.updateCount};
      }
      console.error('dnmResponse error: ', dnmResponse);
      return rejectWithValue('Designating leads as Do Not Mail failed.');
    }
    catch (err) {
      console.error('dnmLeads error', err);
      return rejectWithValue(err.message);
    }
  }
);

export const duplicateLeads = createAsyncThunk(
  'leads/duplicateLeads',
  async({leads, options}, { getState, rejectWithValue }) => {
    const user = getState().user;
    try {
      const duplicateResponse = await LeadApi.duplicateLeads(leads, user);
      if(duplicateResponse.success) {
        const fetchOptions = (options ?  options : {} );
        const response = await LeadApi.fetchLeads(fetchOptions, user);
        return {leadResponse: response, duplicates: duplicateResponse.duplicate_leads};
      }
      console.error('duplicateLeads error: ', duplicateResponse);
      return rejectWithValue('Marking leads as duplicates failed.');
    }
    catch (err) {
      console.error('duplicate store error', err);
      return rejectWithValue(err.message);
    }
  }
);

// export const updatePrice = createAsyncThunk(
//   'leads/updatePrice',
//   ({ price, updateIds }, { getState }) => {
//     const leads = getState().leads.leads;

//     const updatedLeads = leads.map( lead => ({
//       ...lead,
//       price: (updateIds.includes(lead.id)) ? price : lead.price,
//       leadTypeLabel: (updateIds.includes(lead.id) && lead.leadTypeLabel ? 'DM' : lead.leadTypeLabel),
//     }));

//     return updatedLeads;
//   }
// );

export const assignLeadsInventory = createAsyncThunk(
  'leads/assignLeadsInventory',
  async({ leads, rvp, options }, { getState, rejectWithValue }) => {
    const user = getState().user;
    try {
      const assignResponse = await LeadApi.assignLeads(leads, rvp, user);
      if(assignResponse.success) {
        const fetchOptions = (options ? options : {} );
        const response = await LeadApi.fetchLeads(fetchOptions, user);

        return {leadResponse: response, assigned: assignResponse.assigned_leads};
      }
      console.error('assignResponse error: ', assignResponse);
      return rejectWithValue('Assigning leads failed.');
    }
    catch (err) {
      console.error("Leads inventory error", err);
      return rejectWithValue(err.message);
    }
  }
);

const leadsSlice = createSlice({
  name: 'leads',
  initialState: {
    allLeadsData: null,
    allLeadsHeaders: null,
    leads: null,
    options: null,
    loading: 'idle',
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: {
    [fetchLeads.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.allLeadsData = null;
        state.error = null;
        state.successMessage = null;
      }
    },
    [fetchLeads.fulfilled]: (state, action) => {
      if (state.loading === 'pending' && action.payload) {
        state.leads = action.payload.leads;
        state.options = action.payload.options;
        state.loading = 'idle';
        state.successMessage = null;
        state.error = null;
      }
    },
    [fetchAllLeads.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.allLeadsData = null;
        state.loading = 'pending';
        state.error = null;
        state.successMessage = null;
      }
    },
    [fetchAllLeads.fulfilled]: (state, action) => {
      if (state.loading === 'pending' && action.payload) {
        state.allLeadsData = action.payload.leads;
        state.allLeadsHeaders = action.payload.headers;        
        state.loading = 'idle';
        state.successMessage = null;
        state.error = null;
      }
    },
    [assignLeadsInventory.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
        state.successMessage = null;
      }
    },
    [assignLeadsInventory.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.leads = action.payload.leadResponse.leads;
        state.options = action.payload.leadResponse.options;
        state.loading = 'idle';
        state.error = null;
        state.successMessage = `${action.payload.assigned} leads succesfully assigned.`;
      }
    },
    [reassignLeads.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.allLeadsData = null;
        state.loading = 'pending';
        state.error = null;
        state.successMessage = null;
      }
    },
    [reassignLeads.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.leads = action.payload.leadResponse.leads;
        state.options = action.payload.leadResponse.options;
        state.loading = 'idle';
        state.error = null;
        state.successMessage = `${action.payload.reassigned} leads succesfully reassigned.`;
      }
    },
    [trashLeads.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.allLeadsData = null;
        state.loading = 'pending';
        state.error = null;
        state.successMessage = null;
      }
    },
    [trashLeads.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.leads = action.payload.leadResponse.leads;
        state.options = action.payload.leadResponse.options;
        state.loading = 'idle';
        state.error = null;
        state.successMessage = `${action.payload.trashed} leads succesfully sent to trash.`;
      }
    },
    [recoverTrashLeads.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.allLeadsData = null;
        state.loading = 'pending';
        state.error = null;
        state.successMessage = null;
      }
    },
    [recoverTrashLeads.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.leads = action.payload.leadResponse.leads;
        state.options = action.payload.leadResponse.options;
        state.loading = 'idle';
        state.error = null;
        state.successMessage = `${action.payload.recovered} leads succesfully recovered from trash.`;
      }
    },
    [dncLeads.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.allLeadsData = null;
        state.loading = 'pending';
        state.error = null;
        state.successMessage = null;
      }
    },
    [dncLeads.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.leads = action.payload.leadResponse.leads;
        state.options = action.payload.leadResponse.options;
        state.loading = 'idle';
        state.successMessage = `${action.payload.updateCount} leads succesfully marked as Do Not Call.`;
      }
    },
    [dnmLeads.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.allLeadsData = null;
        state.loading = 'pending';
        state.error = null;
        state.successMessage = null;
      }
    },
    [dnmLeads.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.leads = action.payload.leadResponse.leads;
        state.options = action.payload.leadResponse.options;
        state.loading = 'idle';
        state.successMessage = `${action.payload.updateCount} leads succesfully marked as Do Not Mail.`;
      }
    },
    [duplicateLeads.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.allLeadsData = null;
        state.loading = 'pending';
        state.error = null;
        state.successMessage = null;
      }
    },
    [duplicateLeads.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.leads = action.payload.leadResponse.leads;
        state.options = action.payload.leadResponse.options;
        state.loading = 'idle';
        state.successMessage = `${action.payload.duplicates} leads succesfully marked as duplicates.`;
      }
    },
    // [updatePrice.pending]: (state, action) => {
    //   if (state.loading === 'idle') {
    //     state.loading = 'pending';
    //     state.error = null;
    //     state.successMessage = null;
    //   }
    // },
    // [updatePrice.fulfilled]: (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle';
    //     state.leads = action.payload;
    //   }
    // },
    [dncLeads.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.successMessage = null;
        state.loading = 'idle';
      }
    },
    [dnmLeads.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.successMessage = null;
        state.loading = 'idle';
      }
    },
    [fetchLeads.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.successMessage = null;
        state.loading = 'idle';
      }
    },
    [fetchAllLeads.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.successMessage = null;
        state.loading = 'idle';
      }
    },
    [trashLeads.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.successMessage = null;
        state.loading = 'idle';
      }
    },
    [recoverTrashLeads.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.successMessage = null;
        state.loading = 'idle';
      }
    },
  }
});

export default leadsSlice.reducer;

const updateLeadState = (currentOptions, newOptions) => {
  // console.debug(currentOptions, newOptions);
  if(!currentOptions || !newOptions) return true;

  const currentKeys = Object.keys(currentOptions);
  const newKeys = Object.keys(newOptions);

  if(currentKeys.length !== newKeys.length) return true;

  const newEntries = Object.entries(newOptions);
  const ignoreOptions = [
    'items',
    'next',
    'previous',
    'userRole'
  ]

  const shouldUpdate = newEntries.some(entry => {
    
    const key = entry[0];

    if(!ignoreOptions.includes(key)) {
      const thisCurrentOption = currentOptions[key];
      const thisNewOption = entry[1];

      if(key === 'sort') {
        if(thisCurrentOption.name !== thisNewOption.name || thisCurrentOption.asc !== thisNewOption.asc) return true;
      }
      else if (typeof thisNewOption === 'number') {
        if(thisCurrentOption !== thisNewOption) return true;
      }
      else if(!thisCurrentOption || thisCurrentOption.length !== thisNewOption.length) {
        return true;
      }
      else{
        return thisNewOption.some((option, index) => {
          if(option.id && option.id !== thisCurrentOption[index].id) return true;
          if(!option.id && option !== thisCurrentOption[index]) return true;
          return false;
        });
      }
    }
    return false;
  });

  return shouldUpdate;
}