import PaginatedApi from './PaginatedApi';

const API_URL = process.env.REACT_APP_API_URL;

export default class ArchivedLeadSetApi extends PaginatedApi {

  static async fetchChargeAgents(agentIds, {getState, rejectWithValue}) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getState().user.storedUser.access_token,
      },
    }

    let agentQuery = [];
    agentIds.forEach((id, index) => {
      agentQuery.push(`filter[agent][${index}]=${id}`);
    });
    

    try {
      let response = await fetch(API_URL + '/charges?' + agentQuery, requestOptions);
      let responseJson = await response.json();

      if(responseJson.errors) {
        return rejectWithValue(responseJson.errors.message);
      }

      const chargeAgents = responseJson.data.map(agent => ({
        id: agent.id,
        bid: agent.attributes.drupal_internal__id,
        agentName: agent.attributes.field_agent_name,
        agentNumber: agent.attributes.field_agent_number,
        amountCredit: agent.attributes.field_amount_credit,
        amountFinance: agent.attributes.field_amount_finance,
        billingCycle: agent.attributes.field_billing_cycle,
        chargeDates: agent.attributes.field_charge_dates,
        splitCharge: agent.attributes.field_split_charge,
      }));

      return {
        message: "Fulfilled",
        name: "Lead Set View",
        chargeAgents: chargeAgents,
      }
    }
    catch (err) {
      console.error("fetchChargeAgents error: ", err)
      return rejectWithValue(err);
    }
  }

  static async fetchAllLeadSets(options = {}, { getState, rejectWithValue }) {
    let response = {
      leadSets: null,
      options: options,
    }

    const fetchHeader = {
      headers: {
        'Authorization': 'Bearer ' + getState().user.storedUser.access_token
      }
    }

    let queryFilter = this.createQueryFilter(options);

    const fetchUrl = API_URL + `/archive/lead_sets?${queryFilter}`;
    try {

      let leadSetJson = await this.fetchPaginated(fetchUrl, fetchHeader);

      response.leadSets = this.mapLeadSets(leadSetJson);

      return response;
    }
    catch(err) {
      console.error("archived fetchAllLeadSets error: ", err)
      return rejectWithValue(err);
    }
  }

  static async fetchLeadSets(options = {}, { getState, rejectWithValue }) {
    let response = {
      leadSets: null,
      options: {},
    }

    const fetchHeader = {
      headers: {
        'Authorization': 'Bearer ' + getState().user.storedUser.access_token
      }
    }

    // create query filter
    let queryFilter = this.createQueryFilter(options);
    try {
      // query the data
      let leadSetResponse = await fetch(API_URL + `/archive/lead_sets?${queryFilter}`, fetchHeader);
      let leadSetJson = await leadSetResponse.json();

      // map the response
      response.leadSets = this.mapLeadSets(leadSetJson.data);

      // get the next/previous links.
      if (leadSetJson.links !== undefined && leadSetJson.links.next !== undefined) {
        response.options.next = leadSetJson.links.next.href;
      } else {
        response.options.next = options.next;
      }
      if (leadSetJson.links !== undefined && leadSetJson.links.prev !== undefined) {
        response.options.previous = leadSetJson.links.prev.href;
      } else {
        response.options.previous = options.previous;
      }
      if (leadSetJson.meta !== undefined && leadSetJson.meta.count !== undefined) {
        response.options.items = parseInt(leadSetJson.meta.count, 10);
      } else {
        response.options.items = -1;
      }

      response.sort = options.sort;

      return response;
    }
    catch(err) {
      console.error("archived fetchLeadSets error: ", err)
      return rejectWithValue(err);
    }

  }

  static async updateLeadSet(leadSet, {getState, rejectWithValue}) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getState().user.storedUser.access_token
      },
      body: JSON.stringify(leadSet),
    }

    try {
      let leadSetResponse = await fetch(API_URL + `/archive/update_lead_set`, requestOptions);
      let responseJson = await leadSetResponse.json();

      return {
        message: "Fulfilled",
        name: "Lead Set Review",
        leadSet: responseJson.data,
      }
    }
    catch (err) {
      console.error("archived updateLeadSet error: ", err)
      return rejectWithValue(err);
    }

  }

  static createQueryFilter(options) {
    let filters = [];

    // add the filters
    if(options.agentNumber) {
      filters.push(`filter[firstName][condition][path]=field_agent_number`);
      filters.push(`filter[firstName][condition][operator]=CONTAINS`);
      filters.push(`filter[firstName][condition][value]=${options.agentNumber}`);
    }

    if(options.approvedByName) filters.push(`filter[field_approved_by]=${options.approvedByName}`);

    if(options.assignedAgent) {
      filters.push(`filter[firstName][condition][path]=field_assigned_agent`);
      filters.push(`filter[firstName][condition][operator]=CONTAINS`);
      filters.push(`filter[firstName][condition][value]=${options.assignedAgent}`);
    }

    if(options.authorId) filters.push(`filter[uid]=${options.authorId}`);
    
    if(options.chargedAgents) {
      filters.push(`filter[firstName][condition][path]=field_charged_agents__names`);
      filters.push(`filter[firstName][condition][operator]=CONTAINS`);
      filters.push(`filter[firstName][condition][value]=${options.chargedAgents}`);
    }

    if(options.comments) {
      filters.push(`filter[firstName][condition][path]=field_comments`);
      filters.push(`filter[firstName][condition][operator]=CONTAINS`);
      filters.push(`filter[firstName][condition][value]=${options.comments}`);
    }

    if(options.dateApproved !== undefined) {
      if(options.dateApproved.min) {
        filters.push(`filter[min_date][condition][path]=field_date_approved`);
        filters.push(`filter[min_date][condition][value]=${options.dateApproved.min}`);
        filters.push(`filter[min_date][condition][operator]=%3E`);
      }

      if(options.dateApproved.max) {
        filters.push(`filter[max_date][condition][path]=field_date_approved`);
        filters.push(`filter[max_date][condition][value]=${options.dateApproved.max}`);
        filters.push(`filter[max_date][condition][operator]=%3C`);
      }
    }

    if(options.managerName) filters.push(`filter[field_manager]=${options.managerName}`);

    if(options.rvpName) filters.push(`filter[field_rvp]=${options.rvpName}`);

    if(options.status) filters.push(`filter[field_lead_set_status]=${options.status}`);

    // add the sorting.
    if (options.sort !== undefined) {
      let field = '';
      switch (options.sort.name) {
        case 'agentNumber': {
          field = 'field_agent_number';
          break;
        }
        case 'approvedByName': {
          field = 'field_approved_by__name';
          break;
        }
        case 'assignedAgent': {
          field = 'assigned_agent';
          break;
        }
        case 'author': {
          field = 'uid';
          break;
        }
        case 'created': {
          field = 'created';
          break;
        }
        case 'dateApproved': {
          field = 'field_date_approved';
          break;
        }
        case 'leadsCount': {
          field = 'field_leads_count';
          break;
        }
        case 'managerName': {
          field = 'field_manager__name';
          break;
        }
        case 'rvpName': {
          field = 'field_rvp__name';
          break;
        }
        case 'status': {
          field = 'field_lead_set_status';
          break;
        }
        case 'title': {
          field = 'title';
          break;
        }
        default: { break; }
      }
      if (!options.sort.asc) {
        field = '-' + field;
      }
      if (field){
        filters.push(`sort=${field}`);
      }
    }

    // add the page size.
    if (options.itemsPerPage !== undefined) {
      filters.push(`page[limit]=${options.itemsPerPage}`);
    } else {
      filters.push(`page[limit]=50`);
    }

    // add the offset.
    if (options.page !== undefined) {
      let itemsPerPage = 50;
      if (options.itemsPerPage !== undefined) {
        itemsPerPage = options.itemsPerPage;
      }
      let offset = itemsPerPage * options.page;
      filters.push(`page[offset]=${offset}`);
    }

    const queryFilter = filters.join('&');
    return queryFilter;
  }

  static mapLeadSets(leadSetData) {
    return leadSetData.map(leadSet => ({
      id: leadSet.id,
      nid: leadSet.nid,
      agentNumber: leadSet.field_agent_number,
      approvedBy: leadSet.field_approved_by,
      approvedByName: leadSet.field_approved_by__name,
      assignedAgent: leadSet.field_assigned_agent,
      author: leadSet.author,
      authorId: leadSet.author_uuid,
      chargedAgents: leadSet.field_charged_agents__names,
      charges: leadSet.field_charged_agents,
      comments: leadSet.field_comments,
      created: new Date(leadSet.created * 1000).toLocaleDateString("en-US"),
      dateApproved: (leadSet.field_date_approved ? new Date(`${leadSet.field_date_approved} GMT-05:00`).toLocaleDateString("en-US") : null),
      leads: leadSet.field_leads,
      leadsCount: leadSet.field_leads.length,
      manager: leadSet.field_manager,
      managerName: leadSet.field_manager__name,
      rvp: leadSet.field_rvp,
      rvpName: leadSet.field_rvp__name,
      status: leadSet.field_lead_set_status,
      title: leadSet.title,
      totalPrice: leadSet.field_total_price,
    }));
  }

}
