import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LeadApi from '../Api/LeadApi';
import LeadSetApi from '../Api/LeadSetApi';
import ArchivedLeadSetApi from '../Api/ArchivedLeadSetApi';
import { resetSelectedLeads, fetchArchivedLeads } from './SelectedLeads';

export const createLeadSet = createAsyncThunk(
  'leadset/Create',

  async(state, thunk) => {

    try {
      const reserveResponse = await LeadApi.reserveLeads(state, thunk);

      if(reserveResponse.assignedLeads && reserveResponse.assignedLeads.length > 0){
        const response = await LeadSetApi.createLeadSet(state, reserveResponse, thunk);
        return response;
      }
      else if(reserveResponse.remainingLeads) {
        return reserveResponse;
      }
      else {
        return thunk.rejectWithValue("No leads to assign, selected leads already assigned to another agent.");
      }
    }
    catch (err) {
      return thunk.rejectWithValue(err);
    }
  }
);

export const fetchLeadSets = createAsyncThunk(
  'leadsets/fetch',
  async(options = {}, { getState, rejectWithValue }) => {
    try {
      const leadSets = await LeadSetApi.fetchLeadSets(options, getState);
      return leadSets;
    }
    catch(err) {
      return rejectWithValue(err.message);
    }
  }
);

/**
 * Uses recursive API calls to fetch all leads that match a filter, prepraed for CSV download.
 */
export const fetchAllArchiveLeadSets = createAsyncThunk(
  'leadsets/fetchAllArchiveLeadSets',
  async(options = {}, { getState, rejectWithValue }) => {
    try {
      const leadSetReponse = await ArchivedLeadSetApi.fetchAllLeadSets(options, { getState, rejectWithValue });

      const includeHeaders = options.columns.filter(column => {
        return column.display === 'true';
      });

      let headers = includeHeaders.map(column => {
        let columnKey = column.name;
        let columnLabel = column.label;

        switch (column.name) {
          case 'authorId':
            columnKey = 'author';
            break;
          case 'id':
            columnLabel = 'ID';
            break;
          default:
            break;
        }   
        return {
          label: columnLabel,
          key: columnKey,
        }
      });

      headers.push({label: 'NID', key: 'nid'});

      return {
        leadSets: leadSetReponse.leadSets,
        headers: headers,
      }
    }
    catch(err) {
      console.error("fetchAllArchiveLeadSets error", err);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchArchivedLeadSets = createAsyncThunk(
  'leadsets/fetchArchived',
  async(options = {}, { getState, rejectWithValue }) => {
    try {
      const leadSets = await ArchivedLeadSetApi.fetchLeadSets(options, { getState, rejectWithValue });
      return leadSets;
    }
    catch(err) {
      return rejectWithValue(err.message);
    }
  }
);

export const reviewLeadSet = createAsyncThunk(
  'leadset/reserve',
  async(leadSetId, thunk) => {
    const { dispatch, } = thunk;

    if(!leadSetId) return null;
    dispatch(resetSelectedLeads());
    try {
      const response = await LeadSetApi.reviewLeadSet(leadSetId, thunk);
      return response;
    }
    catch(err) {
      console.error(err);
      return thunk.rejectWithValue("Unable to review lead set, already under review by another user.");
    }
  }
);

export const updateArchivedLeadSet = createAsyncThunk(
  'leadset/updateArchived',
  async(leadSetId, thunk) => {
    const { dispatch, getState, rejectWithValue } = thunk;

    if(!leadSetId) return null;
    dispatch(resetSelectedLeads());
    const leadSets = getState().fetchLeadSets.archivedLeadSets;
    const leadSet = leadSets.find(set => set.id === leadSetId);

    try {
      const response = await ArchivedLeadSetApi.updateLeadSet(leadSet, thunk);
      return response;
    }
    catch(err) {
      console.error(err);
      return rejectWithValue("Unable to review lead set, already under review by another user.");
    }
  }
);

export const viewArchivedLeadSet = createAsyncThunk(
  'leadset/viewArchived',
  async(leadSetId, thunk) => {
    const { dispatch, getState } = thunk;
    if(!leadSetId) return null;
    dispatch(resetSelectedLeads());
    try {
      const leadSets = getState().fetchLeadSets.archivedLeadSets;
      const leadSet = leadSets.find(set => set.id === leadSetId);
      const chargeIds = leadSet.charges;
      dispatch(fetchArchivedLeads(leadSet.nid));
      const agentResponse = await ArchivedLeadSetApi.fetchChargeAgents(chargeIds, thunk);

      return {...leadSet, 'chargeAgents': agentResponse.chargeAgents};
    }
    catch(err) {
      console.error(err);
      return thunk.rejectWithValue("Unable to view lead set.");
    }
  }
);

export const viewLeadSet = createAsyncThunk(
  'leadset/view',
  async(leadSetId, thunk) => {
    const { dispatch } = thunk;

    if(!leadSetId) return null;
    dispatch(resetSelectedLeads());
    try {
      const response = await LeadSetApi.fetchLeadSet(leadSetId, thunk);
      return response;
    }
    catch(err) {
      console.error(err);
      return thunk.rejectWithValue("Unable to view lead set.");
    }
  }
);

export const reviewPendingLead = createAsyncThunk(
  'leadset/pending',
  async(leadId, thunk) => {
    if(!leadId) {
      console.error('No Lead ID provided to review');
      return thunk.rejectWithValue("Lead ID not provided. Unable to review");
    }

    try {
      const response = await LeadSetApi.reviewPendingLead(leadId, thunk);
      if(response?.payload?.errors) return thunk.rejectWithValue(`Unable to review lead set. ${response.payload.errors.message}`);
      return response;
    }
    catch(err) {
      console.error(err);
      return thunk.rejectWithValue(`Unable to review lead set. ${err}`);
    }
  }
);

export const releaseLeads = createAsyncThunk(
  'leadset/releaseLeads',
  async(leadSetIds, thunk) => {
    try {
      const response = await LeadSetApi.releaseLeads(leadSetIds, thunk);
      return response;
    }
    catch(err) {
      console.error(err);
    }
  }
);

export const updateLeadSet = createAsyncThunk(
  'leadset/approve',
  async(submission, thunk) => {
    try {
      const response = await LeadSetApi.updateLeadSet(submission, thunk);
      return response;
    }
    catch(err) {
      console.error(err);
      return thunk.rejectWithValue(`Lead set unable to be approved ${err}`);
    }
  }
);

const leadSetSlice = createSlice({
  name: 'leadSet',
  initialState: {
    allLeadSets: null,
    allLeadSetsHeaders: null,
    archivedLeadSet: null,
    archivedLeadSets: null,
    leadSet: null,
    leadSets: null,
    options: null,
    loading: 'idle',
    error: null,
    review: false,
  },
  reducers: {
  },
  extraReducers: {
    [fetchAllArchiveLeadSets.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
        state.allLeadSets = null;
      }
    },
    [fetchArchivedLeadSets.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
        state.allLeadSets = null;
      }
    },    
    [fetchLeadSets.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
        state.allLeadSets = null;
      }
    },
    [updateLeadSet.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    },
    [fetchAllArchiveLeadSets.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.allLeadSets = action.payload.leadSets;
        state.allLeadSetsHeaders = action.payload.headers;        
        state.loading = 'idle';
        state.error = null;
      }
    },
    [fetchArchivedLeadSets.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.archivedLeadSets = action.payload.leadSets;
        state.options = action.payload.options;
        state.loading = 'idle';
        state.error = null;
      }
    },
    [fetchArchivedLeadSets.rejected]: (state, action) => {
        state.error = action.payload;
        state.loading = 'idle';
    },
    [fetchLeadSets.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.leadSets = action.payload.leadSets;
        state.options = action.payload.options;
        state.loading = 'idle';
        state.error = null;
      }
    },
    [fetchLeadSets.rejected]: (state, action) => {
        state.error = action.payload;
        state.loading = 'idle';
    },
    [reviewLeadSet.fulfilled]: (state, action) => {
      if(action.payload === null) {
        state.leadSet = null;
      }
      else{
        state.leadSet = action.payload.leadSet;
        state.review = true;
      }
    },
    [reviewPendingLead.fulfilled]: (state, action) => {
      if(action.payload === null) {
        state.leadSet = null;
        state.review = true;
      }
      else{
        state.leadSet = action.payload.leadSet;
        state.review = true;
      }
    },
    [updateArchivedLeadSet.fulfilled]: (state, action) => {
      if(action.payload === null) {
        state.leadSet = null;
      }
      else{
        state.leadSet = action.payload.leadSet;
        state.review = true;
      }
    },
    [viewArchivedLeadSet.fulfilled]: (state, action) => {
      if(action.payload === null) {
        state.archivedLeadSet = null;
      }
      else{
        state.archivedLeadSet = action.payload;
        state.review = false;
      }
    },
    [viewLeadSet.fulfilled]: (state, action) => {
      if(action.payload === null) {
        state.leadSet = null;
      }
      else{
        state.leadSet = action.payload.leadSet;
        state.review = false;
      }
    },
    [reviewPendingLead.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = 'idle';
    },
    [releaseLeads.fulfilled]: (state, action) => {
      state.leadSet = action.payload.leadSet;
    },
    [updateLeadSet.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = 'idle';
    },
    [fetchLeadSets.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.loading = 'idle';
      }
    }
  }  
});

export default leadSetSlice.reducer;
