import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import leads from './Leads';
import updatePrice from './Leads';
import dncLeads from './Leads';
import duplicateLeads from './Leads';
import assignLeadsInventory from './Leads';
import recoverTrashLeads from './Leads';
import trashLeads from './Leads';
import selectedLeads from './SelectedLeads';
import taxonomy from './Taxonomy';
import user from './User';
import fetchManagers from './Managers';
import createLeadSet from './LeadSets';
import fetchLeadSets from './LeadSets';
import reviewLeadSet from './LeadSets';
import removeLeads from './LeadSets';
import updateLeadSet from './LeadSets';
import reviewPendingLead from './LeadSets';
  
const reducer = combineReducers({
  leads,
  selectedLeads,
  taxonomy,
  user,
  assignLeadsInventory,
  dncLeads,
  duplicateLeads,
  recoverTrashLeads,
  trashLeads,
  createLeadSet,
  fetchLeadSets,
  fetchManagers,
  reviewLeadSet,
  removeLeads,
  reviewPendingLead,
  updateLeadSet,
  updatePrice,
});

const store = configureStore({
  reducer,
});

export default store;