import { createTheme, } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export const themeBreakPoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      s: 400,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: 'rgb(244, 196, 42)',
    },
    secondary: {
      main: red[800],
    },
  },
});

export const defaultTableOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: { 
          color: 'rgba(0, 0, 0, 0.87)',
        }
      }
    },
    MUIDataTable: {
      styleOverrides: {
        tableRoot: {
          [themeBreakPoints.breakpoints.down('md')]: {
            overflow: 'hidden',
          }
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        stackedCommon: {
          [themeBreakPoints.breakpoints.down('md')]: {
            fontSize: '.8rem',
          }
        },
        responsiveStackedSmallParent: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'flex',
            margin: '5px auto',
          }
        },
      }
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        responsiveStacked: {
          [themeBreakPoints.breakpoints.down('md')]: {
            padding: '10px 0',
            '&:nth-of-type(even)': {
              backgroundColor: '#ececec',
            },
          }
        },
      }
    },
    MUIDataTableFilter: {
      styleOverrides: {
        reset: {
          [themeBreakPoints.breakpoints.down('md')]: {
            marginTop: 7,
          }
        },
        resetLink: {
          color: '#3f51b5',
        },
      }
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        chip: {
          display: 'none'
        }
      }
    },
    MUIDataTableHead : {
      styleOverrides: {
        responsiveStacked: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'block',
            height: '2.65rem',
          },
        }
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        checkboxRoot: {
          [themeBreakPoints.breakpoints.down('md')]: {
            top: 0,
            left: 0,
            position: 'absolute',
          }
        },
        root: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'table-cell',
            minWidth: 40,
          },
        },
        fixedHeader: {
          [themeBreakPoints.breakpoints.down('md')]: {
            height: '2.6rem',
            '&::after': {
              content: '"Select All Leads"',
              position: 'absolute',
              background: 'rgba(0, 0, 0, .05)',
              top: '0',
              right: '-100vw',
              width: '100vw',
              height: '1.5rem',
              padding: '.8rem 0 .3rem',
              fontWeight: 400,
              borderBottom: '1px solid rgba(0, 0, 0, .25)',
            },
          },
        },
      }
    },
    MUIDataTablePagination: {
      styleOverrides: {
        tableCellContainer: {
          [themeBreakPoints.breakpoints.down('md')]: {
            padding: 0,
          },
        },
        root: {
          [themeBreakPoints.breakpoints.down('md')]: {
            width: '100%',
          },
        }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        actions: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'space-around',
            width: '80%',
            maxWidth: '20rem',
            margin: '0 auto',
          }
        },
        filterPaper: {
          [themeBreakPoints.breakpoints.down('md')]: {
            width: '75%',
            maxWidth: '90%',
          },
          [themeBreakPoints.breakpoints.down('sm')]: {
            width: '90%',
          },
        },
        icon: {
          margin: 0,
          color: '#444444',
        },
        left: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'none',
          },
        },
        root: {
          [themeBreakPoints.breakpoints.down('md')]: {
            minHeight: 'auto',
          },
        },
      }
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          [themeBreakPoints.breakpoints.up('md')]: {
            borderRadius: '5px 5px 0 0',
          },
          [themeBreakPoints.breakpoints.down('md')]: {
            backgroundColor: '#444',
            borderRadius: 0,
            bottom: 0,
            color: '#ffffff',
            padding: '10px 0 15px',
            position: 'fixed',
            columnGap: '1rem',
          }
        },
        title: {
          [themeBreakPoints.breakpoints.down('md')]: {
            paddingLeft: 0,
          }
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'none',
          },
        },
        root: {
          borderBottom: '1px dashed rgba(0, 0, 0, .25)',
          color: '#000',
          fontWeight: 400,
          padding: '5px 10px',
          [themeBreakPoints.breakpoints.down('md')]: {
            border: 'none',
            fontSize: '.8rem',
            padding: 0,
          },
        },
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          [themeBreakPoints.breakpoints.down('md')]: {
            marginLeft: 0,
            fontSize: '.75rem',
            fontWeight: 600,
          },
        },
        select: {
          [themeBreakPoints.breakpoints.down('md')]: {
            fontSize: '.75rem',
            fontWeight: 600,
            width: 16,
          },
        },
        selectIcon: {
          [themeBreakPoints.breakpoints.down('md')]: {
            left: 25,
          }
        },
        selectLabel: {
          [themeBreakPoints.breakpoints.down('md')]: {
            fontSize: '.75rem',
            fontWeight: 600,
          },
        },
        spacer: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'none',
          },
        },
        toolbar: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto auto',
          },
        },
      }
    },
  }
};

export const defaultLeadSetTable = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: { color: 'rgba(0, 0, 0, 0.87)' },
        textPrimary: { color: '#3f51b5'},
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        stackedCommon: {
          '&:not(:first-of-type)': {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignContent: 'center',
            rowGap: 10,
          }
        }
      }
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        chip: {
          display: 'none'
        }
      }
    },
    MUIDataTableHead : {
      styleOverrides: {
        responsiveStacked: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'table-header-group',
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'none',
          },
        },
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          [themeBreakPoints.breakpoints.down('md')]: {
            display: 'table-cell',
          },
        },
        fixedHeader: {
          [themeBreakPoints.breakpoints.down('md')]: {
            '&::after': {
              content: '"Select All Lead Sets"',
              position: 'absolute',
              background: 'rgba(0, 0, 0, .05)',
              top: '0',
              left: 68,
              width: 100,
              padding: '5px 0 5px',
              borderBottom: '1px solid rgba(0, 0, 0, .25)',
            },
          },
        },
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        filterPaper: {
          maxWidth: 500,
          [themeBreakPoints.breakpoints.down('md')]: {
            width: '75%',
            maxWidth: '90%',
            height: '100%',
          },
          [themeBreakPoints.breakpoints.down('sm')]: {
            width: '90%',
          },
        }
      }
    },
  }
}

export const defaultPopoverTheme = {
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: '1rem',
          maxWidth: 350,
          [themeBreakPoints.breakpoints.down('md')]: {
            position: 'fixed',
            left: '.5rem !important',
            right: '.5rem',
          }
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '10px 0',
        },
      }
    },
  },
  palette: {
    primary: {
      main: 'rgb(244, 196, 42)',
    },
    secondary: {
      main: red[800],
    }
  },
}
