import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { Navigate } from 'react-router-dom';
import Parser from 'html-react-parser';
import { reviewLeadSet, updateLeadSet } from '../../Store/LeadSets';
import { undoReleaseLeads, setLeads } from "../../Store/SelectedLeads";
import ReviewLeads from './ReviewLeads';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { addDays, nextMonday, format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Autocomplete } from '@mui/material';
import CurrencyFormat from './CurrencyFormat';

import './forms.css';
console.log('review leadset1')
const reviewStyles = makeStyles()({
  submitControl: {
    margin: '0 20px',
  },
  alert: {
    border: '2px solid black',
  },
  billingRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noFocus: {
    "&.Mui-focused": {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  paddingLeft: {
    paddingLeft: 2,
  },
  paddingRight: {
    paddingRight: 2,
  },
});

export default function LeadsForm() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leadSet = useSelector(state => state.reviewLeadSet).leadSet;
  const underReview = useSelector(state => state.reviewLeadSet).review;
  const managers = useSelector(state => state.fetchManagers).managers;
  const selectedLeads = useSelector(state => state.selectedLeads);

  const [ errorMessage, setErrorMessage ] = useState('');
  const [ chargeTotal, setChargeTotal ] = useState(0);
  const [ chargeTo, setChargeTo ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ submitSuccess, setSubmitSuccess ] = useState(false);
  const [ managerInput, setManagerInput ] = useState('');
  const [ manager, setManager ] = useState('');
  const [ comments, setComments ] = useState();
  const [ agent, setAgent ] = useState('');
  const [ agentCharges, setAgentCharges ] = useState(0);
  const [ agentNumber, setAgentNumber ] = useState('');
  const [ validDates, setValidDates ] = useState(true);
  const [ snackOpen, setSnackOpen ] = useState(false);

  const defaultDate = nextMonday(addDays(new Date(), 5));
  const {classes} = reviewStyles();
  
  const currencyNumber = (value, keys = []) => {
    if(typeof(value) === 'number') return value;
    if(typeof(value) === 'string'){ 
      return parseFloat(value.replace(/,/g,""));
    }

    let total = 0;
    keys.forEach(key => {
      const fieldValue = value[key];
      if(fieldValue === null) {
        total +=0;
      }
      else if(!Array.isArray(fieldValue)){
        total += typeof(fieldValue) === 'number' ? parseFloat(fieldValue) : parseFloat(fieldValue.replace(/,/g,""));
      }
      else {
        total += fieldValue.reduce((field, reduceValue) => {
          const reduceValueNumber = typeof(reduceValue) === 'number' ? reduceValue : parseFloat(reduceValue.replace(/,/g,""));
          const reduceFieldValue = typeof(field) === 'number' ? field : parseFloat(field.replace(/,/g,""));
          return reduceValueNumber + reduceFieldValue;
        }, 0);
      }
    });
    
    return total;
  };

  const updateCharges = useCallback(() => {
    let leadTotal = 0;

    if(selectedLeads.selectedLeads && selectedLeads.selectedLeads.length >= 1) {
      if(selectedLeads.selectedLeads.length === 1) {
        leadTotal = currencyNumber(selectedLeads.selectedLeads[0].price);
      }
      else{
        leadTotal = selectedLeads.selectedLeads.reduce( (lead, value) => {
          return currencyNumber(lead, ['price']) + currencyNumber(value, ['price']);;
        });
      }
    }

    if( chargeTotal !== leadTotal && chargeTo.length>0) {
      setChargeTotal(leadTotal);

      if(leadSet && leadSet.attributes.date_approved === null){
        const agentTotal = leadTotal / (chargeTo.length===0?1:chargeTo.length);

        const agents = chargeTo.map( agent => ({
            ...agent,
            field_amount_credit: (agent.field_split_charge === 'credit' || agent.field_split_charge === 'split' ? agentTotal : 0),
            field_amount_finance: (agent.field_split_charge === 'finance' ? [agentTotal] : [0]),
            field_billing_cycle: 1,
        }));

        setChargeTo(agents);
      }
    }
  }, [chargeTo, chargeTotal, leadSet, selectedLeads]);

  useEffect(() => {
    updateCharges();
  }, [updateCharges]);

  useEffect(() =>{
    if(leadSet && chargeTo.length === 0){
      window.scrollTo(0,0);
      
      if(leadSet.attributes.comments) setComments(leadSet.attributes.comments);

      let agentCharges = [{
        id: null,
        field_agent_name: leadSet.attributes.assigned_agent,
        field_agent_number: (leadSet.attributes.agent_number ? leadSet.attributes.agent_number : ''),
        field_amount_credit: 0,
        field_amount_finance: [0],
        field_charge_dates: [defaultDate],
        field_billing_cycle: 1,
        field_split_charge: 'credit',
      }];

      if(leadSet.relationships.charged_agents.data.length >= 1) {
        agentCharges = leadSet.relationships.charged_agents.data.map(agent => {
          return {
            id: agent.id,
            field_agent_name: agent.meta.field_agent_name,
            field_agent_number: (agent.meta.field_agent_number ? agent.meta.field_agent_number : ''),
            field_amount_credit: (agent.meta.field_amount_credit ? parseFloat(agent.meta.field_amount_credit) : 0),
            field_billing_cycle: (agent.meta.field_billing_cycle ? parseFloat(agent.meta.field_billing_cycle) : 1),
            field_split_charge: (agent.meta.field_split_charge ? agent.meta.field_split_charge : 'credit'),
            field_charge_dates: (agent.meta.field_charge_dates.length > 0 ? agent.meta.field_charge_dates : [defaultDate]),
            field_amount_finance: (agent.meta.field_amount_finance ? agent.meta.field_amount_finance : [0]),
          }
        });

      }

      setChargeTo(agentCharges);
      setAgent(leadSet.attributes.assigned_agent);

      if(leadSet.attributes.agent_number) setAgentNumber(leadSet.attributes.agent_number);
      const managerUid = (leadSet.relationships.manager.data ? leadSet.relationships.manager.data.meta.uid : null);
      if(managerUid) {
        setManager(managers[managerUid]);
        setManagerInput(managers[managerUid].name);
      }
    }
  }, [chargeTo, defaultDate, leadSet, managers]);

  useEffect(() => {
    if(chargeTo.length === 1){
      setAgentCharges(currencyNumber(chargeTo[0], ['field_amount_credit', 'field_amount_finance']));
    }
    if(chargeTo.length > 1) {
      const agentCharge = chargeTo.reduce( (agent, value) => {
        let agentValue = 0;
        let valueValue = 0;
        
        if((typeof(value) === 'number')) {
          valueValue = value;
        }
        else {
          valueValue = currencyNumber(value, ['field_amount_credit', 'field_amount_finance']);          
        }
        agentValue = currencyNumber(agent, ['field_amount_credit', 'field_amount_finance']);
  
        return agentValue + valueValue;
      });
      setAgentCharges(agentCharge);
    }
  }, [chargeTo]);

  if (!leadSet) return <Navigate to="/lead-requests" />;

  const addChargeAgent = () => {
    const chargeAgents = [...chargeTo];
    if(chargeAgents.length < 3) {
      chargeAgents.push({
        id: null,
        field_agent_name: '',
        field_agent_number: '',
        field_amount_credit: 0,
        field_amount_finance: [0],
        field_charge_dates: [defaultDate],
        field_billing_cycle: 1,
        field_split_charge: 'credit',
      });
      setChargeTo(chargeAgents);
    }
  }

  const removeChargeAgent = (index) => {
    const chargeAgents = [...chargeTo];
    chargeAgents.splice(index, 1);
    setChargeTo(chargeAgents);
  }

  const updatePaymentName = (index, event) => {
    let chargeAgents = [...chargeTo];
    const { value } = event.target;
    chargeAgents[index]['field_agent_name'] = value;
    setChargeTo(chargeAgents);
  }

  const handleChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case 'agent':
        setAgent(value);
        break;
      case 'agentNumber':
        setAgentNumber(value);
        break;
      case 'comments':
        setComments(value);
        break;
      default:
        console.error(`unhandled change`, e);
        break;
    }
  }

  const handleCharge = (event, index = event.target.index) => {
    // console.debug(index, event);
    let chargeAgents = [...chargeTo];
    const { name, value } = event.target;
    chargeAgents[index][name] = value;

    switch(value) {
      case 'credit':
        chargeAgents[index]['field_amount_credit'] = parseFloat(chargeAgents[index]['field_amount_finance'][0]) + parseFloat(chargeAgents[index]['field_amount_credit']);
        chargeAgents[index]['field_amount_finance'] = [0];
        break;
      case 'finance':
        chargeAgents[index]['field_amount_finance'] = [parseFloat(chargeAgents[index]['field_amount_finance'][0]) + parseFloat(chargeAgents[index]['field_amount_credit'])];
        chargeAgents[index]['field_amount_credit'] = 0;
        break;
      default:
        break;
    }
    if(name === 'field_billing_cycle') {
      const defaultPrice = chargeAgents[index]['field_amount_finance'].reduce((a, b) => parseInt(a) + parseInt(b), 0) + chargeAgents[index]['field_amount_credit'];
      chargeAgents[index]['field_amount_finance'] = [];
      chargeAgents[index]['field_amount_credit'] = 0;
      chargeAgents[index]['field_split_charge'] = 'finance';
      chargeAgents[index]['field_charge_dates'] = [];
      for( let i = 0; i < value; i++) {
        const financeAmount = (i === 0 ? defaultPrice : 0);
        chargeAgents[index]['field_amount_finance'].push(financeAmount);
        chargeAgents[index]['field_charge_dates'].push(addDays(defaultDate, (i * 7)));
      }
    }

    setChargeTo(chargeAgents);
  }

  const handleFinance = (event, index = event.target.index, financeIndex = event.target.financeIndex) => {
    let chargeAgents = [...chargeTo];
    // console.debug('handleFincance agents', chargeAgents);
    const { value } = event.target;
    // console.debug(index, financeIndex);
    // console.debug('value', value);
    let finances = [...chargeAgents[index]['field_amount_finance']];

    finances[financeIndex] = Number(value.replace(/[^0-9.-]+/g,""));

    chargeAgents[index]['field_amount_finance'] = finances;
    
    setChargeTo(chargeAgents);
  }

  const handleDate = (index, scheduleIndex, date) => {
    let chargeAgents = [...chargeTo];
    let dates = [...chargeAgents[index]['field_charge_dates']];
    dates[scheduleIndex] = date;
    chargeAgents[index]['field_charge_dates'] = dates;

    try{
      const validateDates = chargeAgents.every(charge => {
        return charge.field_charge_dates.every(chargeDate => format(chargeDate, "eeee") === 'Monday');
      });
      if(validateDates !== validDates) setValidDates(validateDates);
    }
    catch(e) {
      //do nothing, chargeDate isn't set to a valid date yet
    }

    setChargeTo(chargeAgents);
  }

  const disabledDates = (date) => {
    let dt = new Date(date);
    console.log(dt);
    return dt.getDay() !== 1;
  }
  

  const handleManager = (e, value) => {
    setManager(value);
  }

  const handleManagerInput = (e, value) => {
    setManagerInput(value);
  }

  const agentLabel = (index) => {
    switch(index) {
      case 0:
        return 'First Agent Name';
      case 1:
        return 'Second Agent Name';
      case 2:
        return 'Third Agent Name';
      default:
        break;
    }
  }

  const agentNumberLabel = (index) => {
    switch(index) {
      case 0:
        return 'First Agent Number';
      case 1:
        return 'Second Agent Number';
      case 2:
        return 'Third Agent Number';
      default:
        break;
    }
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);

    const submission = {
      agent: agent,
      agentNumber: agentNumber,
      comments: comments,
      manager: manager,
      chargeTo: chargeTo,
      leadSet: leadSet,
    };
    try {
      dispatch(updateLeadSet(submission))
        .then(res => {

          if(!res.payload.error && !res.error) {
            // setErrorMessage('success'); // Debugging
            setSubmitSuccess(true);
            setLoading(false); 
          }
          else {
            let errorResponse = '';

            if(res.payload.data) {
              errorResponse = `
                <p><strong>${res.payload.title}</strong></p>
                <p>${res.payload.status}</p>
              `;
              setLeads(res.payload.data.assignedLeads);
            }
            else if(res.error) {
              errorResponse = `Application error. Error message: ${res.payload.message}`;
            }
            else{
              errorResponse += 'Lead set not approved succesfully, please fix or report the following error(s): <ul>';
              res.payload.status.forEach(leadError => errorResponse+=`<li>${leadError}</li>`);
              errorResponse +='<ul/>';
            }

            setErrorMessage(errorResponse);
            window.scrollTo(0, 0);
            setLoading(false); 
          }
        });
    } catch (err) {
      setErrorMessage("There has been an application error: " + err);
      console.error('Lead set approve error', err);
      setLoading(false);
    }
  }
  
  return (
    <div>
      {submitSuccess && (<Alert severity="success">Lead Set approved.</Alert>)}
      {errorMessage && (<Alert severity="error">{Parser(errorMessage)}</Alert>)}
      {loading && (
        <div className="progressWrapper">
          <CircularProgress />
        </div>
      )}
      {!submitSuccess && (<div>
      <h1>{leadSet && leadSet.attributes.date_approved ? 'Update' : 'Review'} Lead Set</h1>
      <form className="review-leads" onSubmit={handleSubmit}>
        <div className="formGrid">
          <h2>Assign Leads To</h2>
          <FormGroup row={true}>
            <FormControl>
              <TextField disabled={!underReview} label="Agent" variant="outlined" required={true} value={agent} name="agent" id="agent" onChange={event => handleChange(event)} />
            </FormControl>
            <FormControl>
              <TextField disabled={!underReview} label="Agent Number" variant="outlined" required={true} value={agentNumber} name="agentNumber" id="agentNumber" type="number" InputProps={{ inputProps: { min: 0 } }} onChange={handleChange} />
            </FormControl>
          </FormGroup>
          <h2>Charge Agent(s)</h2>
          <div className="chargeAgents">
            {chargeTo.map((agent, index) => (
              <FormGroup row={true} key={`agent-wrapper~${index}`}>
                <FormControl>
                  <TextField 
                    disabled={!underReview}
                    key={`agent-name~${index}`}
                    label={agentLabel(index)}
                    variant="outlined"
                    required={true}
                    name={`field_agent_name`}
                    value={agent.field_agent_name}
                    id={`charge-to~${index}`}
                    helperText={index === 0 ? "If different from assigned agent." : 'Split charge with first agent.'}
                    onChange={event => handleCharge(event, index)}
                    onBlur={event => updatePaymentName(index, event)}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    disabled={!underReview}
                    key={`agent-number~${index}`}
                    label={agentNumberLabel(index)}
                    variant="outlined"
                    value={agent.field_agent_number}
                    name="field_agent_number"
                    id={`chargeToNumber-${index}`}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={event => handleCharge(event, index)}
                  />
                </FormControl>
                {chargeTo.length > 1 && underReview &&(
                  <Tooltip title="Remove Charge Agent">
                    <IconButton
                      className="chargeIcon"
                      onClick={() => removeChargeAgent(index)}
                      size="large">
                      <RemoveCircleIcon className="removePerson" />
                    </IconButton>
                  </Tooltip>
                )}
              </FormGroup>
            ))}
          </div>
          {chargeTo.length < 3 && underReview && (
              <Tooltip title="Add Charge Agent">
                <IconButton className="chargeIcon" onClick={addChargeAgent} size="large">
                  <PersonAddIcon className="addPerson" />
                </IconButton>
              </Tooltip>
            )}
          <FormControl className="managerControl" >
            <Autocomplete
              disabled={!underReview}
              value={manager}
              inputValue={managerInput}
              name="manager"
              id="manager"
              onChange={handleManager}
              onInputChange={handleManagerInput}
              options={Object.values(managers)}
              getOptionLabel={(user) => (user.name ? user.name : "") }
              isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
              renderInput={(params) => <TextField {...params} label="Manager" varient="outlined" required={true} /> }
            />
          </FormControl>
          <FormControl className="commentsControl" fullWidth={true}>
            <TextField disabled={!underReview}  variant="outlined" label="Comments" name="comments" id="comments" onChange={handleChange} value={comments} />
          </FormControl>

          <h2>Payments</h2>
          {chargeTo.map((agent, index) => (agent.field_agent_name && 
            <div key={`payments-${agent.field_agent_name}~${index}`} >
              <h3>{agent.field_agent_name}</h3>
              <FormGroup row={true} className={`paymentsGroup`}>
                <FormGroup className="column">
                  <FormControl>
                    <InputLabel id={`billing-cycle-label-${index}`}>Billing Cycle</InputLabel>
                    <Select
                      disabled={!underReview}
                      labelId={`billing-cycle-label~${index}`}
                      id={`billing-cycle~${index}`}
                      onChange={event => handleCharge(event, index)}
                      value={agent.field_billing_cycle}
                      name="field_billing_cycle"
                      label="Billing Cycle"
                    >
                      <MenuItem value={1}>One Payment</MenuItem>
                      <MenuItem value={2}>Two Weeks</MenuItem>
                      <MenuItem value={3}>Three Weeks</MenuItem>
                      <MenuItem value={4}>Four Weeks</MenuItem>
                      <MenuItem value={5}>Five Weeks</MenuItem>
                      <MenuItem value={6}>Six Weeks</MenuItem>
                      <MenuItem value={7}>Seven Weeks</MenuItem>
                      <MenuItem value={8}>Eight Weeks</MenuItem>
                      <MenuItem value={9}>Nine Weeks</MenuItem>
                    </Select>
                  </FormControl>
                  {agent.field_billing_cycle === 1 &&
                    <FormControl className="datePick">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled={!underReview}
                          format="MM/dd/yyyy"
                          margin="normal"
                          id={`date-picker-inline~${index}~0`}
                          label="Charge Date"
                          onChange={date => handleDate(index, 0, date)}
                          renderInput={props => <TextField {...props}/> }
                          value={agent.field_charge_dates[0] instanceof Date ? agent.field_charge_dates[0] : new Date(agent.field_charge_dates[0])}
                          shouldDisableDate={disabledDates}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        /> 
                      </LocalizationProvider>
                    </FormControl>
                  }
                  {agent.field_billing_cycle > 1 &&
                    <div className={classes.billingRow}>
                      <div className={classes.paddingLeft}>Total charge for {agent.field_agent_name}:</div>
                      <div className={classes.paddingRight}>${currencyNumber(agent,['field_amount_finance'])}</div>
                    </div>
                  }
                </FormGroup>

                {agent.field_billing_cycle === 1 &&
                  <>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" className={classes.noFocus}>Charge Type</FormLabel>
                      <RadioGroup disabled={!underReview} onChange={event => handleCharge(event, index)} value={agent.field_split_charge} name="field_split_charge" required>
                        <FormControlLabel value="credit" control={<Radio />} label="Credit" />
                        <FormControlLabel value="finance" control={<Radio />} label="Finance" />
                        <FormControlLabel value="split" control={<Radio />} label="Split Charge" />
                      </RadioGroup>
                    </FormControl>
                  
                    <FormGroup component="fieldset" className="chargeSplitSet">
                      <FormLabel component="legend">Charge Amount</FormLabel>
                      {(agent.field_split_charge === 'split' || agent.field_split_charge === 'credit') && (
                        <FormControl varient="outlined">
                          <TextField
                            disabled={!underReview}
                            label="Credit"
                            InputLabelProps={{ shrink: true }}
                            name="field_amount_credit"
                            variant="outlined"
                            value={agent.field_amount_credit}
                            onBlur={handleCharge}
                            InputProps={{
                              inputComponent: CurrencyFormat,
                            }}
                            inputProps= {{
                              index: index,
                            }}
                          />
                        </FormControl>
                      )}
                      {(agent.field_split_charge === 'split' || agent.field_split_charge === 'finance') && (  
                        <FormControl varient="outlined">
                          <TextField
                            disabled={!underReview}
                            label="Finance"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            name="field_amount_finance"
                            value={agent.field_amount_finance[0]}
                            onBlur={handleFinance}
                            InputProps={{
                              inputComponent: CurrencyFormat,
                            }}
                            inputProps= {{
                              index: index,
                              financeindex: 0,
                            }}
                          />
                        </FormControl>
                      )}
                    </FormGroup>
                  </>
                }
                {agent.field_billing_cycle > 1 &&
                  <FormGroup className="column charges">
                    {agent.field_charge_dates.map( (chargeDate, billingIndex) => (
                      <FormGroup row={true} className="chargeRow" key={`billing~${index}~${billingIndex}`}>
                        <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              disabled={!underReview}
                              format="MM/dd/yyyy"
                              id={`date-picker~${index}~${billingIndex}`}
                              onChange={date => handleDate(index, billingIndex, date)}
                              value={chargeDate instanceof Date ? chargeDate : null}
                              renderInput={props => <TextField {...props}/> }
                              label="Charge Date"
                              shouldDisableDate={disabledDates}
                              KeyboardButtonProps={{
                                'aria-label': 'charge date',
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        <FormControl varient="outlined">
                        <TextField
                          disabled={!underReview}
                            label="Finance"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            name="field_amount_finance"
                            value={agent.field_amount_finance[billingIndex]}
                            onBlur={handleFinance}
                            InputProps={{
                              inputComponent: CurrencyFormat,
                            }}
                            inputProps= {{
                              index: index,
                              financeindex: billingIndex,
                            }}
                            className="multiCurrency"
                          />
                        </FormControl>
                      </FormGroup>
                    ))}
                  </FormGroup>
                }
              </FormGroup>
            </div>
          ))}
          <div className="chargeTotals">
            <div className="row">
              <div>Total Lead Cost:</div><div>${chargeTotal}</div>
            </div>
            <div className="row">
              <div>Total Billing:</div><div>${agentCharges}</div>
            </div>
            {(chargeTotal !== agentCharges) && (
              <Alert severity="warning">Lead costs and agent charges do not match.</Alert>
            )}
            {!validDates && (<Alert severity="warning">Charge dates should be scheduled for Monday's only.</Alert>)}
          </div>
        </div>

        {underReview &&
          <FormControl className={classes.submitControl}>
            <Button
              disabled={loading}
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                setLoading(true);
                // saveFormState(); // this doesn't exist yet
                navigate('/dashboard');
              }}
            >
              Add Additional Leads
            </Button>
          </FormControl>
        }

        {selectedLeads && selectedLeads.releasedLeads.length > 0 &&(
          <>
            <FormControl className={classes.submitControl}>
              <Button
                disabled={loading}
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => {
                  if( selectedLeads.selectedLeads.length + selectedLeads.releasedLeads.length > 500) {
                    setSnackOpen(true);
                  }
                  else {
                    setLoading(true);
                    dispatch(undoReleaseLeads(true)).then( () => setLoading(false));
                  }
                }}
              >
                Return Removed Leads
              </Button>
            </FormControl>

            <FormControl className={classes.submitControl}>
              <Tooltip title={"Reset leads to original request."}>
                <Button
                  disabled={loading}
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setLoading(true);
                    dispatch(reviewLeadSet(leadSet.id))
                      .then( () => setLoading(false));
                  }}
                >
                  Reset Leads
                </Button>
              </Tooltip>
            </FormControl>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={snackOpen}
              autoHideDuration={6000}
              onClose={handleSnackClose}
            >
              <Alert onClose={handleSnackClose} severity="error" className={classes.alert}>
                Unable to undo leads back to lead set, too many leads added. Reset Leads to start over.
              </Alert>
            </Snackbar>
          </>
        )}

        {leadSet && selectedLeads && 
          <ReviewLeads />
        }

        {underReview &&
          <FormControl>
            <Button disabled={chargeTotal !== agentCharges || !validDates || loading} type="submit" variant="contained" color="primary">Approve</Button>
          </FormControl>
        }

        {!underReview &&
          <FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => { dispatch(reviewLeadSet()).then(navigate('/lead-requests')) }}
            >
              Back to Lead Sets
            </Button>
          </FormControl>
        }
      </form></div>
      )}
    </div>
  );

}
