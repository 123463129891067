import PaginatedApi from './PaginatedApi';

const API_URL = process.env.REACT_APP_API_URL;

export default class TaxonomyApi extends PaginatedApi {

  static async fetchAllTaxonomies(user, dispatch) {

    let response = {
      states: null,
      leadTypes: null,
      pricing: null,
    }

    const fetchHeader = {
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token
      }
    }

    try {
     
      const leadTypesUrl = API_URL + '/lead_types?sort=name';
      const leadTypeJson = await this.fetchPaginated(leadTypesUrl, fetchHeader);

      if(leadTypeJson){
        response.leadTypes = leadTypeJson.map(type => ({
          id: type.id,
          type: type.attributes.name
        }));
      }

      const stateUrl = API_URL + '/states?sort=name';       
      const stateJson = await this.fetchPaginated(stateUrl, fetchHeader);
      response.states = stateJson.map(state => ({
        id: state.id,
        name: state.attributes.name,
        abbr: state.attributes.abbr
      }));

      if(user.storedUser.user_role === 'sl_lead_dept') {
        const priceUrl = API_URL + '/lead_prices';       
        const priceJson = await this.fetchPaginated(priceUrl, fetchHeader);

        if(priceJson) {
          response.pricing = priceJson.map(price => ({
            id: price.id,
            name: price.attributes.name,
            price: price.attributes.price,
            leadType: (price.relationships.lead_type.data ? price.relationships.lead_type.data.id : null),
            state: (price.relationships.state.data ? price.relationships.state.data.id : null)
          }));
        }
      }

      return response;
    }
    catch (err) {
      console.error('taxonomyAPI error', err);
      return dispatch.rejectWithValue(err);
    }
  }

}
