import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { useNavigate,} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, TextField } from '@mui/material';
import './login.css';
import { login } from '../../Store/User';


export default function LoginPage(props) {
  const { loggedIn } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userCreds, setUserCreds] = useState({username: '', password: ''});
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    let creds = {...userCreds};
    creds[name] = value;
    setUserCreds(creds);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    setErrorMessage('');


    if (userCreds.username && userCreds.password) {
      dispatch(login(userCreds))
        .then((res) => {
          if(!res.payload.ok) {
            setSubmitted(false);
            setErrorMessage(res.payload.message);
          }
          else {
            navigate('/dashboard');
          }
        });
    }
  }

  useEffect(() => {
    if(loggedIn) navigate('/dashboard');
  }, [loggedIn, navigate]);

  return (
    <div className="login-block">
      <h2>Login</h2>
      {errorMessage &&
        <Alert severity="error">{errorMessage}</Alert>
      }
      <form className="login formGrid" name="login" onSubmit={handleSubmit}>
        {submitted && 
          <div className="progressWrapper">
            <CircularProgress />
          </div>
        }
        <TextField label='Username' name='username' variant="outlined" required={true} value={userCreds.username} onChange={handleChange} />
        <TextField label='Password' name='password' variant="outlined" type="password" required={true} value={userCreds.password} onChange={handleChange} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="btn"
        >
          Login
        </Button>
      </form>
    </div>
  );
}
