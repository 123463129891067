import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { fetchLeadSets, reviewLeadSet, viewLeadSet } from '../../Store/LeadSets';
import { Autocomplete, Button, CircularProgress, TextField, } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import './filter.css';
import { 
  Alert,
  FormControl,
 } from '@mui/material';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { addDays, format, subDays } from 'date-fns';
import { defaultLeadSetTable } from "../LeadsTableTheme";


const useStyles = makeStyles()({
  dateErrorMsg: {
    color: 'red',
    width: '100%',
  },
  datePicker: {
    flex: '1 5 25%',
  },
  dateWrapper: {
    display: 'grid',
    gap: '1rem',
  },
  dateWrapperError: {
    display: 'grid',
    gap: '1rem',
    borderColor: 'red',
  },
});

const leadsTableTheme = () => createTheme(defaultLeadSetTable);

export default function FilterLeadSets() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {classes} = useStyles();

  const [ errorAlert, setErrorAlert ] = useState(false);
  const leadSets = useSelector(state => state.fetchLeadSets);
  const managers = useSelector(state => state.fetchManagers);

  const [ loading, setLoading ] = useState(true);
  const [ filteredSets, setFilteredSets ] = useState([]);
  const [ filteredLeadOptions, setFilteredLeadOptions ] = useState({
    sort: {
      name: 'created',
      asc: false,
    }
  });
  const [ filteredLeadPaging, setFilteredLeadPaging ] = useState({
    itemsPerPage: 50,
    items: -1,
  });
  const [ dateError, setDateError ] = useState(false);

  useEffect(() => {
    if (leadSets && leadSets.leadSets && leadSets.loading === 'idle') {
      setFilteredSets(leadSets.leadSets);
      setLoading(false);
      if(filteredLeadPaging.items !== leadSets.options.items || filteredLeadPaging.itemsPerPage !== leadSets.options.itemsPerPage) {
        let updatedFilteredPaging = {...filteredLeadPaging};
        updatedFilteredPaging.items = leadSets.options.items;
        updatedFilteredPaging.itemsPerPage = leadSets.options.itemsPerPage;
        setFilteredLeadPaging(updatedFilteredPaging);
      }
    }
  }, [filteredLeadPaging, leadSets]);

  useEffect(() => {
    if (filteredLeadOptions) {
      setLoading(true);
      dispatch(fetchLeadSets(filteredLeadOptions));
    }
  }, [dispatch, filteredLeadOptions]);

  let columns = [
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const leadSetStatus = filteredSets[tableMeta.rowIndex].status;
          
          let buttons = [
            <Button 
              key={value}
              value=""
              variant="contained"
              color="primary"
              disableElevation
              onClick={() =>{
                setLoading(true);
                setErrorAlert(false);
                dispatch(reviewLeadSet(value)).then((resp) => {
                  if(!resp.error) {
                    navigate('/review-leadset');
                  }
                  else {
                    const alertMsg = {
                      label: tableMeta.rowData[2],
                      payload: `${resp.payload} Please select a different lead set to review.`
                    };
                    setErrorAlert(alertMsg);
                    setLoading(false);
                    window.scrollTo(0, 0);
                  }
                })}
              }
            >
              {leadSetStatus === 'sent' ? 'Update' : 'Review'}
            </Button>];

            if(leadSetStatus === 'sent') {
              buttons.push(
                <Button
                  key={value+'-view'}
                  value=""
                  variant="contained"
                  color="primary"
                  disableElevation
                  className='viewLeadset'
                  onClick={() =>{
                    setLoading(true);
                    setErrorAlert(false);
                    dispatch(viewLeadSet(value)).then((resp) => {
                      if(!resp.error) {
                        navigate('/review-leadset');
                      }
                      else {
                        const alertMsg = {
                          label: tableMeta.rowData[2],
                          payload: `${resp.payload} Please select a different lead set to view.`
                        };
                        setErrorAlert(alertMsg);
                        setLoading(false);
                        window.scrollTo(0, 0);
                      }
                    })}
                  }
                >
                  View
                </Button>
              );
            }

            return buttons;

        },
      }

    },
    {
      name: "title",
      label: 'Label',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "assignedAgent",
      label: "Assigned Agent",
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
        filterOptions: {
          fullWidth: true,
        }
      }
    },
    {
      name: "leadsCount",
      label: "Leads",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (v) => {
          const statusOptions = {
            'pending': 'Pending Review',
            'under_review': 'Under Review',
            'error': 'AS400 Error',
            'sent': 'Sent to AS400',
          };
          return statusOptions[v];
        },
        filterOptions: {
          fullWidth: true,
          names: [
            'pending',
            'under_review',
            'error',
            'sent',
          ],
          renderValue: v => {
            const statusOptions = {
              'pending': 'Pending Review',
              'under_review': 'Under Review',
              'error': 'AS400 Error',
              'sent': 'Sent to AS400',
            };
            return statusOptions[v];
          },
        }
      }
    },
    {
      name: "authorId",
      label: "Requested By",
      options: {
        sort: false,
        filter: true,
        filterType: 'custom',
        customBodyRender: (value) => {
          if(managers.managers){
            let val = Object.values(managers.managers).filter(p => p.uuid === value);
            if (val !== null && val.length > 0) {
              return val[0].name;
            }
          }
          return value;
        },
        filterOptions: {
          fullWidth: true,
          names: managers.managers ? managers.managers : '',
          renderValue: value => {
            if(managers.managers) {
              const managersValues = Object.values(managers.managers);
              return managersValues.filter(p => p.uuid === value);
            }
          },
          display: (filterList, onChange, index, column) => {
            return(
              <Autocomplete
                className='autocompleteFix'
                key='managerAutocomplete'
                value={filterList[index][0] ? filterList[index][0] : null}
                name="manager"
                id="manager"
                onChange={(event, value) => {
                  filterList[index] = [value];
                  onChange(filterList[index], index, column);
                }}
                options={Object.keys(managers.managers)}
                getOptionLabel={(id) => {
                  if(typeof id === 'object') return '';
                  return managers.managers[id].name;
                }}
                renderInput={(params) => <TextField {...params} label="Assigned By" variant='standard'/> }
              />
            );
          },
        },
      }
    },
    {
      name: 'created',
      label: "Requested On",
      options: {
        filter: false,
        options: { sortDirection: 'desc' }
      }
    },
    {
      name: 'dateApproved',
      label: "Assigned On",
      options: {
        sort: true,
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: () => '',
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => {
            return (
              <fieldset className={(dateError ? classes.dateWrapperError : classes.dateWrapper)}>
                <legend>Assigned On</legend>
                <FormControl className={classes.datePicker}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        format="MM/dd/yyyy"
                        id={`created-date-min`}
                        label="Min"
                        margin="normal"
                        value={filterList[index][0]?.min ? filterList[index][0].min : null}
                        variant='standard'
                        onChange={date => {
                          (filterList[index][0] ? filterList[index][0].min = date : filterList[index][0] = {min: date, max: null});
                          onChange(filterList[index], index, column);
                          if(filterList[index][0].max && subDays(filterList[index][0].min, 1) > addDays(filterList[index][0].max, 1)) {
                            setDateError(true);
                          }
                          else if(dateError) {
                            setDateError(false);
                          }
                        }}
                        renderInput={props => <TextField {...props} varient='standard'/> }
                        KeyboardButtonProps={{
                          'aria-label': 'minimum create date',
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl className={classes.datePicker}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        format="MM/dd/yyyy"
                        id={`created-date-max`}
                        label="Max"
                        margin="normal"
                        value={filterList[index][0]?.max ? filterList[index][0].max : null}
                        variant='standard'
                        onChange={date => {
                          (filterList[index][0] ? filterList[index][0].max = date : filterList[index][0] = {min: null, max: date});
                          onChange(filterList[index], index, column);
                          if(filterList[index][0].min && subDays(filterList[index][0].min, 1) > addDays(filterList[index][0].max, 1)) {
                            setDateError(true);
                          }
                          else if(dateError) {
                            setDateError(false);
                          }
                        }}
                        renderInput={props => <TextField {...props}/> }
                        KeyboardButtonProps={{
                          'aria-label': 'maximum assigned date',
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {dateError && (
                    <div className={classes.dateErrorMsg}>
                      Min date should be before max date.
                    </div>
                  )}
              </fieldset>
            );
          }
        }
      }
    },
  ];

  const options = {
    confirmFilters: true,
    count: filteredLeadPaging.items,
    customToolbarSelect: null,
    download: false,
    rowsPerPageOptions: [10, 25, 50],
    rowsPerPage: filteredLeadPaging.itemsPerPage,
    serverSide: true,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    search: false,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            color="primary"
            className="btn"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      let options = { ...leadSets.options };
      switch (action) {
        case 'sort': {
          options.sort = {
            name: tableState.sortOrder.name,
            asc: tableState.sortOrder.direction === 'asc',
          }
          setFilteredLeadOptions(options);
          break;
        }
        case 'changePage': {
          options.page = tableState.page;
          setFilteredLeadOptions(options);
          break;
        }
        case 'changeRowsPerPage': {
          options.page = 0;
          options.itemsPerPage = tableState.rowsPerPage;
          setFilteredLeadOptions(options);
          break;
        }
        default: { break; }
      }
    },
  };

  const handleFilterSubmit = applyFilters => {
    setLoading(true);
    let filterList = applyFilters();

    let options = {
      items: leadSets.options.items,
      next: leadSets.options.next,
      previous: leadSets.options.previous,
      sort: leadSets.options.sort,
    };

    filterList.forEach((values, index) => {
      const name = columns[index].name;
      switch (name) {
        case 'dateApproved':
          if(values.length > 0){
            let minDate = values[0].min;
            let maxDate = values[0].max;
            
            options[name] = {
              min: (minDate ? format(subDays(minDate, 1), 'yyyy-MM-dd'): null),
              max: (maxDate ? format(addDays(maxDate, 1), 'yyyy-MM-dd'): null),
            };
          }
          break;
        default: 
        if(values.length > 0) options[name] = values[0];
          break;
      }
    });
      setFilteredLeadOptions(options);
  }

  return (
    <div className="leadsDashboard">
      {errorAlert && (<Alert severity="warning">Unable to review lead set <em>{errorAlert.label}</em>. {errorAlert.payload}</Alert>)}
      {loading && (
          <div className="progressWrapper">
            <CircularProgress />
          </div>
      )}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={leadsTableTheme()}>
          <MUIDataTable
            title={"Lead Sets"}
            data={filteredSets}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}
