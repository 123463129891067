import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  useNavigate,
} from "react-router-dom";
import { Button, Drawer } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import logo from '../../images/sl-logo.webp';
import { fetchLeads } from '../../Store/Leads';
import { reviewLeadSet } from '../../Store/LeadSets';
import { resetSelectedLeads } from "../../Store/SelectedLeads";
import { logout } from '../../Store/User';

const appStyles = makeStyles()(theme => ({
  button: {
    color: '#000000',
    fontSize: '1rem',
    padding: '7px 2rem 1px',
    lineHeight: '2.2',
    backgroundColor: "rgb(244, 196, 42)",
    '&:hover': {
      backgroundColor: "rgb(244, 196, 42)",
    },
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.8rem',
      padding: '2px 1.25rem',
      float: 'right',
      backgroundColor: "rgb(244, 196, 42)",
    },
  },
  drawer: {
    height: 'auto',
    width: 250,
  },
  link: {
    color: '#ffffff',
    textDecoration: 'none',
    padding: '.5rem',
    marginRight: '2rem !important',
    fontSize: '1.4rem',
    textTransform: 'none',
    width: '100%',
  },
  logout: {
    marginTop: '9px',
    float: 'right',
  }
}));

export default function Header(props) {
  const { loggedIn } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);

  const [showMenu, setShowMenu] = useState(false);
  const { classes, cx } = appStyles();

  const toggleMenu = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setShowMenu(open);
  }


  return (
    <header className="app-header">
      <div className="logo-container">
        <img src={logo} className="app-logo" alt="logo" />
        {/* {loggedIn && user.storedUser.current_user && (<>{user.storedUser.current_user.name}</>)} */}
      </div>

      <nav>
        {!loggedIn && (
          <Button
            className={classes.button }
            component={Link}
            to="/login"
          >Login</Button>
        )}

        {loggedIn && (
          <>
            <Button className={classes.button} onClick={toggleMenu(true)}>Exit</Button>
            <Drawer anchor={'right'} open={showMenu} onClose={toggleMenu(false)}>
              <div
                role="presentation"
                onClick={toggleMenu(false)}
                onKeyDown={toggleMenu(false)}
                className={cx(classes.drawer)}
              >
                <nav>
                  <Button
                    className={classes.link}
                    onClick={() => {
                      dispatch(reviewLeadSet())
                        .then(() => dispatch(resetSelectedLeads()))
                        .then(() => {
                          let options = {};

                          if (user.storedUser.user_role === 'sl_lead_dept') {
                            options.inventoryStatus = ['available', 'reserved'];
                          }

                          //dispatch(fetchLeads(options));
                        })
                        .then(() => navigate('/dashboard'))
                    }}
                  >Back to Dashboard</Button>
                  {user.storedUser && user.storedUser.user_role === 'sl_lead_dept' && (
                    <>
                      <Button
                        className={classes.link}
                        onClick={() => { dispatch(reviewLeadSet()).then(navigate('/lead-requests')) }}
                      >Review Lead Requests</Button>

                      <Button
                        className={classes.link}
                        onClick={() => { dispatch(reviewLeadSet()).then(navigate('/search-lead-sets')) }}
                      >Search Lead Sets</Button>

                      <Button
                        className={classes.link}
                        onClick={() => {
                          dispatch(resetSelectedLeads())
                            .then(() => dispatch(reviewLeadSet()))
                            .then(() => {
                              let options = {};
                              options.trash = true;
    
                              dispatch(fetchLeads(options));
                            })
                            .then(() => navigate('/trash'))
                        }}
                        to="/trash"
                      >Trash</Button>
                    </>
                  )}
                  <Button
                    className={cx(classes.button, classes.logout)}
                    onClick={() => {
                      navigate('/logged-out');
                      dispatch(logout(user));
                    }}
                  >Logout</Button>
                </nav>
              </div>
            </Drawer>
          </>
        )}
      </nav>
    </header>
  );
}
