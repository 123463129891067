import { useDispatch } from 'react-redux';
import { IconButton, Tooltip} from "@mui/material";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { makeStyles } from 'tss-react/mui';

import { recoverTrashLeads } from "../../Store/Leads";

const selectLeadStyles = makeStyles()({
  iconButton: {
    display: "inline-block",
    position: "relative",
    borderRadius: '100%',
    marginRight: 26,
    background: 'rgb(244, 196, 42)',
    border: '2px solid rgb(244, 196, 42)',
    padding: '9px 12px 6px',
    '&:hover': {
      background: '#fff',
      borderColor: '#999',
    }
  },
  assignIcon: {
    color: "#000"
  },
  popover: {
    padding: '1rem',
  },
});

export default function TrashLeadsTools(props) {
  const { options, rows, selectedRows } = props;

  const dispatch = useDispatch();

  const { classes } = selectLeadStyles();


  const selectedLeadIds = () => {
    const leads = rows;
    let selectedLeads = [];
    selectedRows.data.forEach(row => {
      selectedLeads.push(leads[row.dataIndex].id);
    });
    return selectedLeads;
  }

  const handleTrashRecover = () => {
    const selectedIds = selectedLeadIds();

    if (window.confirm("Are you sure you want to recover the selected leads?")) dispatch(recoverTrashLeads({leads: selectedIds, options: options}));
  };

  return (
    <div className={"toolbar-select-leads"}>
      <Tooltip title={"Recover from trash."}>
        <IconButton
          className={classes.iconButton}
          onClick={handleTrashRecover}
          size="large">
          <RestoreFromTrashIcon className={classes.assignIcon} />
        </IconButton>
      </Tooltip>
    </div>
    );

}
