import { BehaviorSubject } from 'rxjs';

const WEB_URL = process.env.REACT_APP_WEB_URL;

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));

export const AuthorizationApi = {
  login,
  logout,
  currentUser,
  get currentUserValue () { return currentUserSubject.value }
};

async function login(username, password) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({ 'name': username,'pass': password })
  };

  try {
    const response = await fetch(WEB_URL + '/user/login?_format=json', requestOptions);
    const user = await handleResponse(response);

    // store user details and jwt token in local storage to keep user logged in between page refreshes
    // jwt == access_token
    localStorage.setItem('user', JSON.stringify(user));
    currentUserSubject.next(user);
    return user;
  }
  catch(err) {
    console.log(err)
    return (err);
  }
  
  
}

function handleResponse(response) {

  return response.text().then(text => {
    if (!response.ok) {
      switch(response.status) {
        case 401:
        case 403:
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          AuthorizationApi.logout();
          return Promise.reject({
            ok: false,
            status: response.status,
            message: 'Invalid credentials.',
          });
        case 400:
          return Promise.reject({
            ok: false,
            status: response.status,
            message: 'Invalid username or password.',
          });
        case 503:
          return Promise.reject({
            ok: false,
            status: response.status,
            message: 'Site under maintenance. Please try again later.',
          });
        default:
          return Promise.reject({
            ok: false,
            status: response.status,
            message: `Site not responding, status code ${response.status}`,
          });
      }
    }
   
    let data = text && JSON.parse(text);

    data.ok = true;

    if(data.current_user.roles.length === 2){
      data.user_role = data.current_user.roles[1];
    }
    else {
      data.current_user.roles.forEach( role => {
        switch(role) {
          case 'authenticated':
          case 'anonymous':
            break;
          case 'sl_lead_dept':
            data.user_role = role;
            break;
          case 'rvp':
            if(data.user_role !== 'sl_lead_dept') data.user_role = role;
            break;
          default:
            if(!data.user_role) data.user_role = role;
            break;
        }
      });
    }

    return data;
  });
}

async function logout(user) {
  localStorage.removeItem('user');
  currentUserSubject.next(null);
  const postOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-url-urlencoded',
    },
  };
  if(!user || !user.storedUser){
    try {
      await fetch(WEB_URL + `/user/logout?_format=json}`, postOptions);
      return true;
    }
    catch(err) {
      console.error('logout error', err);
    }
  }
  else {
    const postOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-url-urlencoded',
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'X-CSRF-TOKEN': user.storedUser.csrf_token,
      },
    };
    try {
      await fetch(WEB_URL + `/user/logout?_format=json&token=${user.storedUser.logout_token}`, postOptions);
      localStorage.removeItem("user")
      return true;
    }
    catch(err) {
      console.error('logout error', err);
    }
  }
}

function currentUser() {
  return currentUserSubject.asObservable();
}
