const API_URL = process.env.REACT_APP_API_URL;

export default class UserApi {

  static async fetchManagers(user, dispatch) {

    const fetchHeader = {
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token
      }
    }

    try {
      console.log(user.storedUser.access_token)
      let response = await fetch(API_URL + `/managers`, fetchHeader);
      let managers = await response.json();

      return managers;
    }
    catch (err) {
      return dispatch.rejectWithValue(err);
    }
  }

}
