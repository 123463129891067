import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { red } from '@mui/material/colors';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import './App.css';
import Footer from "./Components/Global/Footer";
import Header from "./Components/Global/Header";
import LoginPage from "./Components/Auth/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import RequestLeads from "./Components/Forms/RequestLeads";
import LeadRequests from "./Components/Dashboard/LeadRequests";
import LeadSets from "./Components/Dashboard/LeadSets";
import ViewArchivedLeadSet from "./Components/Forms/ViewArchivedLeadSet";
import ReviewLeadSet from "./Components/Forms/ReviewLeadSet";
import Trash from "./Components/Dashboard/Trash";
import ReactGA from 'react-ga';

const apiUrl = process.env.REACT_APP_WEB_URL;

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        }
      }
    }
  },
  palette: {
    primary: {
      main: 'rgb(244, 196, 42)',
    },
    secondary: {
      main: red[800],
    }
  }
});

export default function App() {

  const location = useLocation();

  const user = useSelector(state => state.user);

  const loggedIn = checkLogin(user);

  useEffect(() => {
    ReactGA.initialize("UA-207160768-1");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="app">

          <Header
            loggedIn={loggedIn}
          />

          <main>
            <Routes>
              <Route exact path="/" element={loggedIn ? <Dashboard /> : <LoginPage />} />
              <Route 
                path="/login" 
                element={<LoginPage loggedIn={loggedIn} />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/assign-leads" element={<RequestLeads />} />
              <Route path="/lead-requests" element={<LeadRequests />} />
              <Route path="/review-leadset" element={<ReviewLeadSet />} />
              <Route path="/search-lead-sets" element={<LeadSets />} />
              <Route path="/view-leadset" element={<ViewArchivedLeadSet />} />
              <Route path="/trash" element={<Trash />} />
            </Routes>
          </main>

          <Footer
            loggedIn={loggedIn}
            apiUrl={apiUrl}
          />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function checkLogin(user) {
  if (user && user.storedUser && user.loggedIn) return true;
  return false
}
