import { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { fetchAllTaxonomies } from '../../Store/Taxonomy';
import { fetchLeads } from '../../Store/Leads';
import { fetchManagers } from '../../Store/Managers';
import FilterLeads from './FilterLeads';

const dashStyles = makeStyles()({
  button: {
    color: '#000000',
    fontSize: '1rem',
    padding: '10px 20px',
    lineHeight: '2.2',
    backgroundColor: "rgb(244, 196, 42)",
    textDecoration: 'none',
    display: 'inline-block',
    marginBottom: '15px',
    '&:hover': {
      backgroundColor: "rgb(244, 196, 42)",
    },
    width: 'auto',
  },
});

export default function Dashboard() {

  const dispatch = useDispatch();
  const taxonomy = useSelector(state => state.taxonomy);
  const leads = useSelector(state => state.leads);
  const leadSet = useSelector(state => state.reviewLeadSet);
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const managers = useSelector(state => state.fetchManagers);
  const selectedLeads = useSelector(state => state.selectedLeads);

  const { classes } = dashStyles();

  const [ heading, setHeading ] = useState('Leads Dashboard');
  const [ buttonText, setButtonText ] = useState(null);
  
  useEffect(() => {
    if (leadSet && leadSet.leadSet) {
      if (heading !== 'Add to Existing Lead Set') setHeading('Add to Existing Lead Set');
      setButtonText(`Continue approving Lead Set (${selectedLeads.selectedLeads.length} leads)`);
    }
    else if (selectedLeads && selectedLeads.selectedLeads.length > 0) {
      if (heading !== 'Building Lead Set') setHeading('Building Lead Set');
      setButtonText(`Assign ${selectedLeads.selectedLeads.length} leads to an Agent.`)
    }
    else if (heading !== 'Leads Dashboard') {
      setHeading('Leads Dashboard');
      setButtonText(null);
    }
  }, [heading, selectedLeads, leadSet]);

  useEffect(() => {
    if (taxonomy.loading === 'idle') {
      dispatch(fetchAllTaxonomies());
    }
    if (managers.loading === 'idle' ) {
      dispatch(fetchManagers(user));
    }
  }, [dispatch, managers.loading, taxonomy.loading, user]);

  useEffect(() => {
    if (taxonomy.loading === 'fulfilled' && managers.loading ===  'fulfilled') {
      let options = {};
      
      if(user.storedUser && user.storedUser.user_role === 'sl_lead_dept'){
        options.inventoryStatus = ['available', 'reserved'];
        options.dnc = 0;
        options.dnm = 0;
      }
      else if (!user.storedUser) navigate('/login');
      dispatch(fetchLeads(false));
      //dispatch(fetchLeads(options));
    }
    else if (taxonomy.loading === 'fail') {
      navigate("/login");
    }
  }, [dispatch, navigate, taxonomy.loading, managers.loading, user.storedUser]);

  useEffect(() => {
    if (leads.loading === 'fail') {
      navigate("/login");
    }
  }, [navigate, leads.loading]);

  return (
    <div>
      <h1>{heading}</h1>

      {buttonText && (
        <Link className={classes.button} to={(leadSet && leadSet.leadSet ? '/review-leadset' : "/assign-leads")}>
          {buttonText}
        </Link>
      )}
      <FilterLeads />
    </div>
  );
}