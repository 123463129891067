import { useState, useEffect } from 'react';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Autocomplete } from '@mui/material';
import { fetchLeads } from '../../Store/Leads';
import { createLeadSet, reviewLeadSet } from '../../Store/LeadSets';
import { resetSelectedLeads, setLeads } from "../../Store/SelectedLeads";
import ReviewLeads from './ReviewLeads';
import FailTable from './FailTable';
import './forms.css';

const requestStyles = makeStyles()(theme => ({
  link: {
    color: 'rgb(85, 26, 139)',
    textDecoration: 'underline',
    padding: 0,
    margin: '0 !important',
    fontSize: 'inherit',
    textTransform: 'none',
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline',
      background: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: 10,
      margin: '0 5px',
      fontWeight: 500,
    },
  },
  message: {
    textAlign: 'left',
  },
  noFocus: {
    "&.Mui-focused": {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  chargeIcon: {
    borderRadius: '100%',
    margin: '0 auto 3rem !important',
    color: '#000000',
    background: 'rgb(244, 196, 42)',
    border: '2px solid rgb(244, 196, 42)',
    '&::after': {
      content: "'Charge Multiple Agents'",
      fontSize: 12,
      position: 'absolute',
      top: 50,
      width: 99,
      left: -24,
    }
  },
  submitControl: {
    margin: '0 20px',
  },
  chargeAgentRow: {
    marginBottom: 45,
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    paddingBottom: 20,
    [theme.breakpoints.up('sm')]: {
      '&> div': {
        width: '38%',
      }
    }
  },
  formGroup: {
    [theme.breakpoints.down('md')]: {
      rowGap: '1rem',
    }
  },
}));

export default function LeadsForm() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const managers = useSelector(state => state.fetchManagers).managers;
  const selectedLeads = useSelector(state => state.selectedLeads);
  const user = useSelector(state => state.user);

  const [ agent, setAgent ] = useState('');
  const [ agentNumber, setAgentNumber ] = useState('');
  const [ chargeTo, setChargeTo ] = useState([{ chargeToAgent: '', chargeToNumber: '', splitCharge: 'credit' }]);
  const [ manager, setManager ] = useState('');
  const [ managerInput, setManagerInput ] = useState('');
  const [ comments, setComments ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ submitSuccess, setSubmitSuccess ] = useState(false);
  const [ submittedLeads, setSubmittedLeads ] = useState(0);
  const [ submitWarning, setSubmitWarning ] =  useState(false);
  const [ failedLeads, setFailedLeads ] = useState([]);
  const [ leadSetId, setLeadSetId ] = useState('');
  const [ totalCharge, setTotalCharge ] = useState(0);

  const {classes} = requestStyles();

  useEffect(() => {
    if(user.storedUser && user.storedUser.user_role !== 'sl_lead_dept' && managers) {
      const uid = user.storedUser.current_user.uid;
      setManager({'uuid': managers[uid].uuid});
    }
  }, [user, managers]);

  useEffect(() => {
    let total = 0;

    if(selectedLeads.selectedLeads && selectedLeads.selectedLeads.length >= 1) {
      if(selectedLeads.selectedLeads.length === 1) {
        total = currencyNumber(selectedLeads.selectedLeads[0].price);
      }
      else{
        total = selectedLeads.selectedLeads.reduce( (lead, value) => {
          return currencyNumber(lead, ['price']) + currencyNumber(value, ['price']);;
        });
      }
    }

    setTotalCharge(total);
  }, [selectedLeads]);

  const currencyNumber = (value, keys = []) => {
    if(typeof(value) === 'number') return value;
    if(typeof(value) === 'string') return parseFloat(value.replace(/,/g,""));

    let total = 0;
    keys.forEach(key => {
      const fieldValue = value[key];

      if(!fieldValue || fieldValue === null) {
        total +=0;
      }
      else if(!Array.isArray(fieldValue)){
        total += typeof(fieldValue) === 'number' ? parseFloat(fieldValue) : parseFloat(fieldValue.replace(/,/g,""));
      }
      else {
        if(fieldValue.length === 1) {
          total += typeof(fieldValue[0]) === 'number' ? parseFloat(fieldValue) : parseFloat(fieldValue[0].replace(/,/g,""));
        }
        else {
          total += fieldValue.reduce((field, reduceValue) => {
            const reduceValueNumber = typeof(reduceValue) === 'number' ? reduceValue : parseFloat(reduceValue.replace(/,/g,""));
            const reduceFieldValue = typeof(field) === 'number' ? field : parseFloat(field.replace(/,/g,""));
            return reduceValueNumber + reduceFieldValue;
          });
        }
      }
    });
    
    return total;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch(name) {
      case 'agent':
        setAgent(value);
        break;
      case 'agentNumber':
        setAgentNumber(value);
        break;
      case 'comments':
        setComments(value);
        break;
      default:
        break;
    }
  }
  
  const handleCharge = (index, event) => {
    const { name, value } = event.target;
    let chargeAgents = [...chargeTo];
    chargeAgents[index][name] = value;
    setChargeTo(chargeAgents);
  }
  
  const handleManager = (e, value) => {
    setManager(value);
  }

  const handleManagerInput = (e, value) => {
    setManagerInput(value);
  }

  const addChargeAgent = () => {
    const chargeAgents = [...chargeTo];
    if(chargeAgents.length < 3) {
      chargeAgents.push({
        chargeToAgent: '',
        chargeToNumber: '',
        splitCharge: 'credit',
      });
      setChargeTo(chargeAgents);
    }
  }

  const agentLabel = (index) => {
    switch(index) {
      case 0:
        return 'First Agent Name';
      case 1:
        return 'Second Agent Name';
      case 2:
        return 'Third Agent Name';
      default:
        break;
    }
  }

  const agentNumberLabel = (index) => {
    switch(index) {
      case 0:
        return 'First Agent Number';
      case 1:
        return 'Second Agent Number';
      case 2:
        return 'Third Agent Number';
      default:
        break;
    }
  }

  // Form submission
  const submitAgent = (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSubmitWarning(false);
    setFailedLeads([]);
    setLoading(true);

    const submission = {
      selectedLeads: selectedLeads.selectedLeads,
      chargeTo: chargeTo,
      user: user,
      manager: manager,
      agent: agent,
      agentNumber: agentNumber,
      comments: comments,
    };

    try {
      dispatch(createLeadSet(submission))
        .then(res => {
          if(!res.error && res.payload.leadCount) {
            setSubmitSuccess(true);
            setSubmittedLeads(res.payload.leadCount);
            if(user.storedUser.user_role === 'sl_lead_dept') setLeadSetId(res.payload.uuid);
            setLoading(false); 
          }
          else if(!res.error && res.payload.remainingLeads) {
            dispatch(setLeads(Object.values(res.payload.remainingLeads)));
            setFailedLeads(Object.values(res.payload.failedLeads));
            setSubmitWarning(true);
            window.scrollTo(0, 0);
            setLoading(false);
          }
          else {
            console.error(res);
            setErrorMessage(res.payload);
            window.scrollTo(0, 0);
            setLoading(false);
          }
        });
    } catch (err) {
      setErrorMessage(`There was a system error, if this continues contact your administrator. Error: ${err}`);
      window.scrollTo(0, 0);
      setLoading(false);
      console.error('Lead set create error', err);
    }
  }

  return <>
    {!selectedLeads && !submitSuccess && (<Navigate to="/dashboard" />)}
    {submitSuccess && (
      <Alert severity="success" className={classes.message}>Approval request for {submittedLeads} leads assigned to {agent} succesfully submitted.<br />  
        {leadSetId && <>
        <Button className={classes.link} onClick={() => {dispatch(reviewLeadSet(leadSetId)).then( () => navigate('/review-leadset'))}}>Review created lead set</Button> <span className={classes.hideMobile}>or</span> </>} <Button
            className={classes.link}
            onClick={() => {
              dispatch(resetSelectedLeads())
                .then(() => dispatch(reviewLeadSet()))
                .then(() => {
                  let options = {};

                  if (user.storedUser.user_role === 'sl_lead_dept') {
                    options.inventoryStatus = ['available', 'reserved'];
                  }

                  //dispatch(fetchLeads(false));
                })
                .then(() => navigate('/dashboard'))
            }}
          >Return to Dashboard</Button><span className={classes.hideMobile}>.</span>
      </Alert>
    )}
    {errorMessage && (<Alert severity="error">Lead set not created. {errorMessage} <Button
            className={classes.link}
            onClick={() => {
              dispatch(resetSelectedLeads())
                .then(() => dispatch(reviewLeadSet()))
                .then(() => {
                  let options = {};

                  if (user.storedUser.user_role === 'sl_lead_dept') {
                    options.inventoryStatus = ['available', 'reserved'];
                  }

                  //dispatch(fetchLeads(false));
                })
                .then(() => navigate('/dashboard'))
            }}
          >Return to Dashboard</Button> to start over.</Alert>
    )}
    {submitWarning && selectedLeads.selectedLeads && (
      <Alert severity="warning">{(user.storedUser.user_role === 'sl_lead_dept' ? 'The following' : 'Some')} leads were not available and have been removed from the set request. Submit again to request this lead set containing the remaining {selectedLeads.selectedLeads.length} leads or <Button
          className={classes.link}
          onClick={() => {
            dispatch(resetSelectedLeads())
              .then(() => dispatch(reviewLeadSet()))
              .then(() => {
                let options = {};

                if (user.storedUser.user_role === 'sl_lead_dept') {
                  options.inventoryStatus = ['available', 'reserved'];
                }

                //dispatch(fetchLeads(options));
              })
              .then(() => navigate('/dashboard'))
          }}
        >Start Over</Button> to create a new lead set.
      </Alert>
    )}
    {submitWarning && failedLeads.length > 0 && (
      <FailTable
        leads={failedLeads}
      />
    )}
    {loading && (
      <div className="progressWrapper">
        <CircularProgress />
      </div>
    )}
    {!submitSuccess && user.storedUser && (
      <>
        <h1>Request Leads</h1>
        <form className="assign-leads formGrid" onSubmit={submitAgent}>
          <h2>Assign Leads To</h2>
          <FormGroup row={true} className={classes.formGroup}>
            <FormControl>
              <TextField label="Agent" variant="outlined" required={true} value={agent} name="agent" id="agent" onChange={handleChange} />
            </FormControl>
            <FormControl>
              <TextField label="Agent Number" variant="outlined" value={agentNumber} name="agentNumber" id="agentNumber" type="number" InputProps={{ inputProps: { min: 0 } }} onChange={handleChange} />
            </FormControl>
          </FormGroup>
          <h2>Charge Leads To</h2>
          {chargeTo.map((agent, index) => (
            <FormGroup row={true} key={`${agent}~${index}`} className={`${classes.formGroup} ${classes.chargeAgentRow}`}>
              <FormControl>
                <TextField
                  label={agentLabel(index)}
                  variant="outlined"
                  name="chargeToAgent"
                  value={agent.chargeToAgent}
                  id="charge-to"
                  helperText={index === 0 ? "If different from assigned agent." : ''}
                  onChange={event => handleCharge(index, event)}
                />
              </FormControl>
              <FormControl>
                <TextField
                  label={agentNumberLabel(index)}
                  variant="outlined"
                  value={agent.chargeToNumber}
                  name="chargeToNumber"
                  id="chargeToNumber"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }} 
                  onChange={event => handleCharge(index, event)}
                />
              </FormControl>
              <FormControl component="fieldset" className="chargeTypeRadiosFieldset">
                <FormLabel className={classes.noFocus} component="legend">Charge Type</FormLabel>
                <RadioGroup 
                  className="chargeTypeRadios"
                  onChange={event => handleCharge(index, event)} value={agent.splitCharge}
                  name="splitCharge"
                  required
                >
                  <FormControlLabel value="credit" control={<Radio color="secondary"/>} label="Credit" />
                  <FormControlLabel value="finance" control={<Radio color="secondary"/>} label="Finance" />
                  <FormControlLabel value="split" control={<Radio color="secondary"/>} label="Other (Comments)" />
                </RadioGroup>
              </FormControl>
            </FormGroup>
          ))}
          {chargeTo.length < 3 && (
            <Tooltip title="Add Agent">
              <IconButton className={classes.chargeIcon} onClick={addChargeAgent} size="large">
                <PersonAddIcon className="addPerson" />
              </IconButton>
            </Tooltip>
          )}
          <FormGroup className={classes.formGroup}>
            {user.storedUser.user_role === 'sl_lead_dept' && managers && (
              <FormControl className="managerControl" >
                <Autocomplete
                  value={manager}
                  inputValue={managerInput}
                  name="manager"
                  id="manager"
                  onChange={handleManager}
                  onInputChange={handleManagerInput}
                  options={Object.values(managers)}
                  getOptionLabel={(user) => (user.name ? user.name : "") }
                  isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                  renderInput={(params) => <TextField {...params} label="Manager" varient="outlined" required={true} /> }

                />
              </FormControl>
            )}
            <FormControl className="commentField" fullWidth={true} >
              <TextField variant="outlined" label="Comments" name="comments" id="comments" onChange={handleChange} value={comments} />
            </FormControl>
          </FormGroup>
          {selectedLeads.selectedLeads && (
            <div className="requestTotals">
              <div>Lead Count: <strong>{selectedLeads.selectedLeads.length}</strong></div>
              {user.storedUser.user_role === 'sl_lead_dept' && (<div>Total Charge: <strong>${totalCharge}</strong></div>)}
            </div>
          )} 

          <div>
            <FormControl className={classes.submitControl}>
              <Button disabled={loading} type="submit" variant="contained" color="primary">Request Leads</Button>
            </FormControl>

            <FormControl className={classes.submitControl}>
              <Button
                disabled={loading}
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setLoading(true);
                  // saveFormState(); // this doesn't exist yet
                  navigate('/dashboard');
                }}
              >
                Add Additional Leads
              </Button>
            </FormControl>

            <FormControl className={classes.submitControl}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (window.confirm("Are you sure you want to cancel creating this lead set?")) {
                    dispatch(resetSelectedLeads())
                      .then(() => dispatch(reviewLeadSet()))
                      .then(() => {
                        let options = {};
    
                        if (user.storedUser.user_role === 'sl_lead_dept') {
                          options.inventoryStatus = ['available', 'reserved'];
                        }
    
                        //dispatch(fetchLeads(options));
                      })
                      .then(() => navigate('/dashboard'));
                  }
                }}
              >
                Cancel
              </Button>
            </FormControl>
          </div>

        </form>
        <ReviewLeads />
      </>
    )}
  </>;
}
