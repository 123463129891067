import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthorizationApi } from '../Api/AuthorizationApi';
import { fetchLeads } from './Leads';
import { logoutManagers } from './Managers';
import { resetSelectedLeads } from "./SelectedLeads";
import { fetchAllTaxonomies } from './Taxonomy';

export const login = createAsyncThunk(
  'user/login',

  async (credentials, {rejectWithValue}) => {

    try {
      const response = await AuthorizationApi.login(credentials.username, credentials.password);

      if(response.ok) return response;

      return rejectWithValue(response);
    }
    catch (err) {
      console.error('login error', err);
      return rejectWithValue(err);
    }
  }
);

export const logout = createAsyncThunk(
  'user/logout',
  async (user, {dispatch, rejectWithValue}) => {
    try {
      dispatch(logoutManagers());
      dispatch(fetchAllTaxonomies(true));
      dispatch(resetSelectedLeads());
      dispatch(fetchLeads(false));
      const response = await AuthorizationApi.logout(user);
      return response;
    }
    catch (err) {
      console.error('logout error', err);
      return rejectWithValue(err);
    }
  }
);

const storedUser = JSON.parse(localStorage.getItem('user'));

const initialState = storedUser ? { loggedIn: true, storedUser } : {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.storedUser = action.payload;
    },
    [login.rejected]: (state,action) => {
      state.loggedIn = false;
      state.storedUser = null;
    },
    [logout.pending]: (state) => {
      state.loggedIn = false;
      state.storedUser = null;
    },
    [logout.fulfilled]: (state) => {
      state.loggedIn = false;
      state.storedUser = null;
    }
  }
});

export default userSlice.reducer;

