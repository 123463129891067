import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReviewArchiveLeads from './ReviewArchiveLeads';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CurrencyFormat from './CurrencyFormat';

import './forms.css';

const reviewStyles = makeStyles()({
  submitControl: {
    margin: '0 20px',
  },
  alert: {
    border: '2px solid black',
  },
  billingRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noFocus: {
    "&.Mui-focused": {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  paddingLeft: {
    paddingLeft: 2,
  },
  paddingRight: {
    paddingRight: 2,
  },
});

export default function ViewArchivedLeadSet() {
  const leadSet = useSelector(state => state.reviewLeadSet).archivedLeadSet;
  const selectedLeads = useSelector(state => state.selectedLeads);

  const {classes} = reviewStyles();

  if (!leadSet) return <Navigate to="/search-lead-sets" />;

  const agentLabel = (index) => {
    switch(index) {
      case 0:
        return 'First Agent Name';
      case 1:
        return 'Second Agent Name';
      case 2:
        return 'Third Agent Name';
      default:
        break;
    }
  }

  const agentNumberLabel = (index) => {
    switch(index) {
      case 0:
        return 'First Agent Number';
      case 1:
        return 'Second Agent Number';
      case 2:
        return 'Third Agent Number';
      default:
        break;
    }
  }

  const currencyNumber = (value, keys = []) => {
    if(typeof(value) === 'number') return value;
    if(typeof(value) === 'string'){ 
      return parseFloat(value.replace(/,/g,""));
    }

    let total = 0;
    keys.forEach(key => {
      const fieldValue = value[key];
      if(fieldValue === null) {
        total +=0;
      }
      else if(!Array.isArray(fieldValue)){
        total += typeof(fieldValue) === 'number' ? parseFloat(fieldValue) : parseFloat(fieldValue.replace(/,/g,""));
      }
      else {
        total += fieldValue.reduce((field, reduceValue) => {
          const reduceValueNumber = typeof(reduceValue) === 'number' ? reduceValue : parseFloat(reduceValue.replace(/,/g,""));
          const reduceFieldValue = typeof(field) === 'number' ? field : parseFloat(field.replace(/,/g,""));
          return reduceValueNumber + reduceFieldValue;
        }, 0);
      }
    });
    
    return total;
  };

  return (
    <div>
      <h1>Review Lead Set</h1>
      <form className="review-leads" >
        <div className="formGrid">
          <h2>Assign Leads To</h2>
          <FormGroup row={true}>
            <FormControl>
              <TextField disabled label="Agent" variant="outlined" required={true} value={leadSet.assignedAgent} name="agent" id="agent" />
            </FormControl>
            <FormControl>
              <TextField disabled label="Agent Number" variant="outlined" required={true} value={leadSet.agentNumber} name="agentNumber" id="agentNumber" type="number" InputProps={{ inputProps: { min: 0 } }} />
            </FormControl>
          </FormGroup>
          <h2>Charge Agent(s)</h2>
          <div className="chargeAgents">
            {leadSet.chargeAgents.map((agent, index) => (
              <FormGroup row={true} key={`agent-wrapper~${index}`}>
                <FormControl>
                  <TextField 
                    disabled
                    key={`agent-name~${index}`}
                    label={agentLabel(index)}
                    variant="outlined"
                    required={true}
                    name={`field_agent_name`}
                    value={agent.agentName}
                    id={`charge-to~${index}`}
                    helperText={index === 0 ? "If different from assigned agent." : 'Split charge with first agent.'}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    disabled
                    key={`agent-number~${index}`}
                    label={agentNumberLabel(index)}
                    variant="outlined"
                    value={agent.agentNumber}
                    name="field_agent_number"
                    id={`chargeToNumber-${index}`}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </FormControl>
              </FormGroup>
            ))}
          </div>
          <FormControl className="managerControl" >
            <TextField
              disabled
              variant="outlined"
              label="Manager"
              id="manager"
              name="Manager"
              value={leadSet.managerName}
            />
          </FormControl>
          <FormControl className="commentsControl" fullWidth={true}>
            <TextField disabled variant="outlined" label="Comments" name="comments" id="comments" value={leadSet.comments} />
          </FormControl>

          <h2>Payments</h2>
          {leadSet.chargeAgents.map((agent, index) => (agent.agentName && 
            <div key={`payments-${agent.agentName}~${index}`} >
              <h3>{agent.agentName}</h3>
              <FormGroup row={true} className={`paymentsGroup`}>
                <FormGroup className="column">
                  <FormControl>
                    <InputLabel id={`billing-cycle-label-${index}`}>Billing Cycle</InputLabel>
                    <Select
                      disabled
                      labelId={`billing-cycle-label~${index}`}
                      id={`billing-cycle~${index}`}
                      value={agent.billingCycle}
                      name="field_billing_cycle"
                      label="Billing Cycle"
                    >
                      <MenuItem value={1}>One Payment</MenuItem>
                      <MenuItem value={2}>Two Weeks</MenuItem>
                      <MenuItem value={3}>Three Weeks</MenuItem>
                      <MenuItem value={4}>Four Weeks</MenuItem>
                      <MenuItem value={5}>Five Weeks</MenuItem>
                      <MenuItem value={6}>Six Weeks</MenuItem>
                      <MenuItem value={7}>Seven Weeks</MenuItem>
                      <MenuItem value={8}>Eight Weeks</MenuItem>
                      <MenuItem value={9}>Nine Weeks</MenuItem>
                    </Select>
                  </FormControl>
                  {agent.billingCycle === 1 &&
                    <FormControl className="datePick">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled
                          format="MM/dd/yyyy"
                          margin="normal"
                          id={`date-picker-inline~${index}~0`}
                          label="Charge Date"
                          renderInput={props => <TextField {...props}/> }
                          onChange={() => agent.chargeDates[0]}
                          value={agent.chargeDates[0]}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  }
                  {agent.billingCycle > 1 &&
                    <div className={classes.billingRow}>
                      <div className={classes.paddingLeft}>Total charge for {agent.agentName}:</div>
                      <div className={classes.paddingRight}>${currencyNumber(agent,['amountFinance'])}</div>
                    </div>
                  }
                </FormGroup>

                {agent.billingCycle === 1 &&
                  <>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" className={classes.noFocus}>Charge Type</FormLabel>
                      <RadioGroup disabled value={agent.splitCharge} name="field_split_charge" required>
                        <FormControlLabel value="credit" control={<Radio />} label="Credit" />
                        <FormControlLabel value="finance" control={<Radio />} label="Finance" />
                        <FormControlLabel value="split" control={<Radio />} label="Split Charge" />
                      </RadioGroup>
                    </FormControl>
                  
                    <FormGroup component="fieldset" className="chargeSplitSet">
                      <FormLabel component="legend">Charge Amount</FormLabel>
                      {(agent.splitCharge === 'split' || agent.splitCharge === 'credit') && (
                        <FormControl varient="outlined">
                          <TextField
                            disabled
                            label="Credit"
                            InputLabelProps={{ shrink: true }}
                            name="field_amount_credit"
                            variant="outlined"
                            value={agent.amountCredit}
                            InputProps={{
                              inputComponent: CurrencyFormat,
                            }}
                            inputProps= {{
                              index: index,
                            }}
                          />
                        </FormControl>
                      )}
                      {(agent.splitCharge === 'split' || agent.splitCharge === 'finance') && (  
                        <FormControl varient="outlined">
                          <TextField
                            disabled
                            label="Finance"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            name="field_amount_finance"
                            value={agent.amountFinance[0]}
                            InputProps={{
                              inputComponent: CurrencyFormat,
                            }}
                            inputProps= {{
                              index: index,
                              financeindex: 0,
                            }}
                          />
                        </FormControl>
                      )}
                    </FormGroup>
                  </>
                }
                {agent.billingCycle > 1 &&
                  <FormGroup className="column charges">
                    {agent.chargeDates.map( (chargeDate, billingIndex) => (
                      <FormGroup row={true} className="chargeRow" key={`billing~${index}~${billingIndex}`}>
                        <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              disabled
                              format="MM/dd/yyyy"
                              id={`date-picker~${index}~${billingIndex}`}
                              value={chargeDate}
                              onChange={() => chargeDate}
                              renderInput={props => <TextField {...props}/> }
                              label="Charge Date"
                              KeyboardButtonProps={{
                                'aria-label': 'charge date',
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        <FormControl varient="outlined">
                          <TextField
                            disabled
                            label="Finance"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            name="field_amount_finance"
                            value={agent.amountFinance[billingIndex]}
                            InputProps={{
                              inputComponent: CurrencyFormat,
                            }}
                            inputProps= {{
                              index: index,
                              financeindex: billingIndex,
                            }}
                            className="multiCurrency"
                          />
                        </FormControl>
                      </FormGroup>
                    ))}
                  </FormGroup>
                }
              </FormGroup>
            </div>
          ))}
          <div className="chargeTotals">
            <div className="row">
              <div>Total Lead Cost:</div><div>${leadSet.totalPrice}</div>
            </div>
          </div>
        </div>

        {leadSet && selectedLeads.selectedLeads.length > 0 && 
          <ReviewArchiveLeads />
        }

      </form>
    </div>
  );

}
