import React, { useState, useEffect } from 'react';
import { ExternalLink } from 'react-external-link';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import MUIDataTable from "mui-datatables";
import { fetchLeads, fetchAllLeads } from '../../Store/Leads';
import { reviewPendingLead } from '../../Store/LeadSets';
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import { makeStyles } from 'tss-react/mui';
import { format } from 'date-fns';

import { deepmerge } from '@mui/utils';
import FilterLeadsTools from "./FilterLeadsTools";
import { themeBreakPoints, defaultTableOptions } from "../LeadsTableTheme";
import './filter.css';

const useStyles = makeStyles()({
  dateErrorMsg: {
    color: 'red',
    width: '100%',
  },
  datePicker: {
    flex: '1 5 25%',
  },
  dateWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    flexFlow: 'row wrap',
  },
  dateWrapperError: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    borderColor: 'red',
    flexFlow: 'row wrap',
  },
  mail: {
    background: 'white',
    color: '#232323',
    border: '1px solid #232323',
    padding: '0 4px',
    textDecoration: 'none',
    textTransform: 'none',
    textAlign: 'center',
    display: 'block',
    borderRadius: 5,
  },
  missing: {
    color: 'red',
    fontWeight: 700,
  },
  pending: {
    textTransform: 'none',
    border: '1px solid #232323',
    padding: '2px 4px',
    textAlign: 'center',
  },
  selectedRow: {
    backgroundColor: 'rgba(82, 187, 82, 0.5)!important',
    '&:hover': {
      backgroundColor: "rgba(82, 187, 82, 0.5)!important",
    },
  },
  stateSelect: {
    left: -15,
    top: 7,
  }
});

const tableStyleOptions = {
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        stackedCommon: {
          [themeBreakPoints.breakpoints.down('md')]: {
            '&:first-of-type': {
              minWidth: 85,
              maxWidth: 150,
            },
            '&:last-of-type': {
              width: 'auto',
            },
          }
        },
        responsiveStackedSmallParent: {
          [themeBreakPoints.breakpoints.down('md')]: {
            '&:nth-of-type(2)': {
              marginTop: 15,
            },
            '&:last-of-type': {
              marginBottom: 15,
            },
          }
        },
      }
    },
   
    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          '&::before': {
            content: '"Must select APPLY FILTERS to save any changes before exiting."',
            position: 'absolute',
            fontSize: 12,
            left: 0,
            top: 0,
            width: 'inherit',
            padding: '.2rem 1rem',
          },
        }
      }
    },
  }
};

const leadsTableTheme = () => createTheme(deepmerge(tableStyleOptions, defaultTableOptions));

export default function FilterLeads() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responsive, setResponsive] = useState('simple');
  const taxonomy = useSelector(state => state.taxonomy);
  const leads = useSelector(state => state.leads);
  const selectedLeads = useSelector(state => state.selectedLeads);
  const user = useSelector(state => state.user);
  const storedUser = useSelector(state => state.user.storedUser);
  const managers = useSelector(state => state.fetchManagers);
  const leadSet = useSelector(state => state.reviewLeadSet);

  const [ loading, setLoading ] = useState(true);
  const [ initLoading, setInitLoading ] = useState(true);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ successMessage, setSuccessMessage ] = useState(null);
  const [ filteredLeads, setFilteredLeads ] = useState([]);
  const [ filteredLeadOptions, setFilteredLeadOptions ] = useState(null);
  const [ filteredLeadPaging, setFilteredLeadPaging ] = useState({
    itemsPerPage: 50,
    items: -1,
  });
  
  const [ tableColumns, setTableColumns ] = useState(null);
  const [ dateError, setDateError ] = useState(false);
  
  const leadDeptUser = (user.storedUser && user.storedUser.user_role === 'sl_lead_dept');
  const {classes} = useStyles();

  useEffect(() => {
    if (leads && leads.loading === 'pending') setLoading(true);

    if (leads  && leads.leads && leads.options && leads.loading === 'idle') {
      setFilteredLeadPaging({
        itemsPerPage: (leads.options.itemsPerPage ? leads.options.itemsPerPage : 50),
        items: leads.options.items,
      });
      setFilteredLeads(leads.leads);
      setErrorMessage(leads.error);
      setLoading(false);
      if(!filteredLeadOptions) setFilteredLeadOptions(leads.options);
      if(initLoading) setInitLoading(false);
    } else if(!leads.options && leads.loading === 'idle'){
      setInitLoading(false)
      setLoading(false);
      
    }

    if (leads && leads.error) {
      setErrorMessage(leads.error);
      if (leads.loading === 'idle') setLoading(false);
    }

    if (leads && leads.successMessage ) {
      setSuccessMessage(leads.successMessage);
      if (leads.loading === 'idle') setLoading(false);
    }

  }, [ filteredLeadOptions, initLoading, leads, ]);

  useEffect(() => {
    console.log(leadDeptUser)
    if (filteredLeadOptions) {
      setLoading(true);
      dispatch(fetchLeads(filteredLeadOptions));
    }
  }, [dispatch, filteredLeadOptions]);
 
  useEffect(() => {
    if (selectedLeads?.error) {
      setLoading(false);
      setErrorMessage(selectedLeads.error);
    }
    if(selectedLeads && !selectedLeads.error) {
       setErrorMessage(null);
    }
    setSuccessMessage(null);
  }, [selectedLeads]);

  const getColumnDisplay = (name, defaultDisplay) => {
    if(!tableColumns) return defaultDisplay;
    const column = tableColumns.find(col => col.name === name);
    return (column ? column.display === 'true' : defaultDisplay);
  }

  let columns = [
    {
      name: "location",
      label: "Flexible Location",
      options: {
        display: false,
        filter: true,
        filterType: 'textField',
        hint: 'Enter a comma seperated list of cities, counties, or zipcodes. Do not use this filter when using the standalone city, county, zip codes filters.'
      }
    },
    {
      name: "as400Id",
      label: "AS400 ID",
      options: {
        display: getColumnDisplay('as400Id', true),
        filter: true,
        filterType: 'textField'
      }
    },
    {
      name: "id",
      label: "id",
      options: {
        display: getColumnDisplay('id', false),
        filter: false,
      }
    },
    {
      name: "zip",
      label: 'Zip Code',
      options: {
        display: getColumnDisplay('zip', true),
        filter: true,
        filterType: 'textField',
        sort: true,
      }
    },
    {
      name: "dts",
      label: "Call DTS",
      options: {
        display: getColumnDisplay('dts', leadDeptUser),
        filter: true,
        filterType: 'textField',
        sort: true,
      }
    },
    {
      name: 'complete',
      label: 'Address?',
      options: {
        display: getColumnDisplay('complete', leadDeptUser),
        filter: true,
        sort: true,
        customBodyRender: value => value ? 'Y' : 'N',
        filterOptions: {
          names: ['Y', 'N'],
        },
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        display: getColumnDisplay('city', true),
        filter: true,
        sort: true,
        filterType: 'textField',
      }
    },
    {
      name: "county",
      label: "County",
      options: {
        display: getColumnDisplay('county', leadDeptUser),
        filter: true,
        sort: true,
        filterType: 'textField',
      }
    },
    {
      name: "stateId",
      label: "State",
      options: {
        display: getColumnDisplay('stateId', true),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(taxonomy.states){
            let val = taxonomy.states.filter(p => p.id === value);
            if (val !== null && val.length > 0) {
              return val[0].name;
            }
          }
          return '';
        },
        filterType: 'custom',
        filterOptions: {
          names: (taxonomy.states) ? taxonomy.states.map(p => p.id) : [],
          renderValue: v => (taxonomy.states) ? taxonomy.states.filter(p=>p.id === v)[0].name : '',
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <InputLabel className={classes.stateSelect} htmlFor='select-states'>
                  States
                </InputLabel>
                <Select
                  id='select-states'
                  multiple
                  variant='standard'
                  value={filterList[index]}
                  onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {taxonomy.states.map(state => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))};
                </Select>
              </FormControl>
            );
          },
        },
      }
    },
    {
      name: "leadTypeId",
      label: "Lead Type",
      options: {
        display: getColumnDisplay('leadTypeId', true),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const lead = filteredLeads[tableMeta.rowIndex];

          if(!lead?.leadTypeId) return <span className={classes.missing}>MISSING</span>;
          if(lead.leadTypeLabel) return lead.leadTypeLabel;
          if(taxonomy.leadTypes){
            let val = taxonomy.leadTypes.filter(p => p.id === value);
            if (val !== null && val.length > 0) {
              return val[0].type;
            }
          }
          return '';
        },
        filterType: 'custom',
        filterOptions: {
          names: (taxonomy.leadTypes) ? taxonomy.leadTypes.map(p => p.id) : [],
          renderValue: v => (taxonomy.leadTypes) ? taxonomy.leadTypes.filter(p=>p.id === v)[0].type : '',
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <InputLabel className={classes.stateSelect} htmlFor='select-lead-types'>
                  Lead Types
                </InputLabel>
                <Select
                  id='select-lead-types'
                  multiple
                  variant='standard'
                  value={filterList[index]}
                  onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {taxonomy.leadTypes.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.type}
                    </MenuItem>
                  ))};
                </Select>
              </FormControl>
            );
          }
        },
      }
    },
    {
      name: "dropCode",
      label: "DM Drop Code",
      options: {
        display: getColumnDisplay('dropCode', leadDeptUser),
        filter: true,
        sort: true,
        filterType: 'textField',
      }
    }
  ];

  const dncOptions = {
    'DNC': 1,
    'Call OK': 0,
    'All': undefined,
  };
  const dnmOptions = {
    'DNM': 1,
    'Mail OK': 0,
    'All': undefined,
  };

  if( leadDeptUser || (user.storedUser && user.storedUser.user_role === 'rvp')){
    const managerColumn = [
      {
        name: 'managerInventory',
        label: 'Manager Inventory',
        options: {
          display: getColumnDisplay('managerInventory', true),
          sort: false,
          filter: true,
          filterType: 'custom',
          customBodyRender: (value) => {
            if(managers.managers){
              let val = Object.values(managers.managers).filter(p => p.uuid === value);
              if (val !== null && val.length > 0) {
                return val[0].name;
              }
            }
            return value;
          },
          customFilterListOptions: {
            render: () => 'chip',
          },
          filterOptions: {
            names: managers.managers ? managers.managers : '',
            renderValue: value => {
              if(managers.managers) {
                const managersValues = Object.values(managers.managers);
                return managersValues.filter(p => p.uuid === value);
              }
            },
            display: (filterList, onChange, index, column) => (
              <Autocomplete
                multiple
                className='autocompleteFix'
                key='managerAutocomplete'
                value={filterList[index]}
                name="manager"
                id="manager"
                onChange={(event, value) => {
                  filterList[index] = value;
                  onChange(filterList[index], index, column);
                }}
                options={Object.values(managers.managers)}
                getOptionLabel={manager => manager.name}
                isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                renderInput={(params) => <TextField {...params} label={`Manager Inventory`} variant='standard'/> }
              />
            ),
          },
        }
      },
    ];

    columns = managerColumn.concat(columns);
  }

  if (leadDeptUser) {
    const inventoryStatusOptions = ['Available', 'Duplicate', 'Reserved', 'Assigned', 'Pending', 'Unlicensed', 'Not Available', ];

    const leadDeptColumns = [
      {
        name: "firstName",
        label: "First Name",
        options: {
          display: getColumnDisplay('firstName', true),
          filter: true,
          sort: true,
          filterType: 'textField',
        }
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          display: getColumnDisplay('lastName', true),
          filter: true,
          sort: true,
          filterType: 'textField',
        }
      },
      {
        name: "email",
        label: "Email",
        options: {
          display: getColumnDisplay('email', true),
          filter: true,
          sort: true,
          filterType: 'textField',
        }
      },
      {
        name: 'address',
        label: 'Address',
        options: {
          display: getColumnDisplay('address', true),
          filter: true,
          sort: true,
          filterType: 'textField',
        }
      },
      {
        name: 'ani',
        label: 'ANI',
        options: {
          display: getColumnDisplay('ani', true),
          filter: true,
          sort: true,
          filterType: 'textField',
        }
      },
      {
        name: 'phone',
        label: 'Phone',
        options: {
          display: getColumnDisplay('phone', true),
          filter: true,
          sort: true,
          filterType: 'textField',
        }
      },
      {
        name: 'inventoryStatus',
        label: 'Status',
        options: {
          display: getColumnDisplay('inventoryStatus', true),
          filter: true,
          sort: true,
          filterType: 'multiselect',
          filterList: (filteredLeadOptions && filteredLeadOptions.inventoryStatus && filteredLeadOptions.inventoryStatus.map(value => value.charAt(0).toUpperCase() + value.slice(1))),
          customBodyRender: (value, tableMeta) => {
            let statusName = value?.charAt(0).toUpperCase() + value?.slice(1);

            switch(statusName) {
              case 'Not_available':
                return 'Not Available';
              case 'Pending':
                return <Button
                  className={classes.pending}
                  onClick={() =>{
                    setLoading(true);
                    setErrorMessage(null);
                    const leadId = leads.leads[tableMeta.rowIndex].nid;
                    dispatch(reviewPendingLead(leadId)).then((response) => {
                      if(response.error) {
                        setErrorMessage(response.payload);
                        setLoading(false);
                      }
                      else{
                        navigate('/review-leadset');
                      }
                    });
                  }}
                >
                  {statusName}
                </Button>;
              default:
                return statusName;

            }
          },
          filterOptions: {
            names: inventoryStatusOptions,
          },
        }
      },
      {
        name: 'created',
        label: 'Creation Date',
        options: {
          display: getColumnDisplay('created', true),
          sort: true,
          filter: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: () => '',
          },
          filterOptions: {
            fullWidth: true,
            display: (filterList, onChange, index, column) => {
              return (
                <fieldset className={(dateError ? classes.dateWrapperError : classes.dateWrapper)}>
                  <legend>Creation Date</legend>
                  <FormControl className={classes.datePicker}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          format="MM/dd/yyyy"
                          id={`created-date-min`}
                          label="Min"
                          margin="normal"
                          value={filterList[index][0]?.min ? filterList[index][0].min : null}
                          variant='standard'
                          onChange={date => {
                            (filterList[index][0] ? filterList[index][0].min = date : filterList[index][0] = {min: date, max: null});
                            onChange(filterList[index], index, column);
                            if(filterList[index][0].max && filterList[index][0].min > filterList[index][0].max) {
                              setDateError(true);
                            }
                            else if(dateError) {
                              setDateError(false);
                            }
                          }}
                          renderInput={props => <TextField {...props} varient='standard'/> }
                          KeyboardButtonProps={{
                            'aria-label': 'minimum create date',
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl className={classes.datePicker}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          format="MM/dd/yyyy"
                          id={`created-date-max`}
                          label="Max"
                          margin="normal"
                          value={filterList[index][0]?.max ? filterList[index][0].max : null}
                          variant='standard'
                          onChange={date => {
                            (filterList[index][0] ? filterList[index][0].max = date : filterList[index][0] = {min: null, max: date});
                            onChange(filterList[index], index, column);
                            if(filterList[index][0].min && filterList[index][0].min > filterList[index][0].max) {
                              setDateError(true);
                            }
                            else if(dateError) {
                              setDateError(false);
                            }
                          }}
                          renderInput={props => <TextField {...props}/> }
                          KeyboardButtonProps={{
                            'aria-label': 'maximum create date',
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    {dateError && (
                      <div className={classes.dateErrorMsg}>
                        Min date should be before max date.
                      </div>
                    )}
                </fieldset>
              );
            }
          }
        }
      },
      {
        name: 'dnc',
        label: 'DNC',
        options: {
          display: getColumnDisplay('dnc', true),
          filter: true,
          sort: true,
          filterList: (filteredLeadOptions && filteredLeadOptions.dnc !== undefined && [Object.keys(dncOptions).find(key => dncOptions[key] === filteredLeadOptions.dnc)]),
          filterOptions: {
            names: ['DNC', 'Call OK'],
          }
        }
      },
      {
        name: 'dnm',
        label: 'DNM',
        options: {
          display: getColumnDisplay('dnm', true),
          filter: true,
          sort: true,
          filterList: (filteredLeadOptions && filteredLeadOptions.dnm !== undefined && [Object.keys(dnmOptions).find(key => dnmOptions[key] === filteredLeadOptions.dnm)]),
          filterOptions: {
            names: ['DNM', 'Mail OK'],
          }
        }
      },
      {
        name: 'mailImage',
        label: 'DM Image',
        options: {
          display: getColumnDisplay('mailImage', true),
          filter: false,
          sort: false,
          customBodyRender: value => {
            if(!value) return value;
            return (
              <ExternalLink
                className={classes.mail}
                href={value}
              >
                Mail Image
              </ExternalLink>
            );
            
          }
        }
      },
      {
        name: 'price',
        label: 'Price',
        options: {
          display: getColumnDisplay('price', true),
          filter: false,
          sort: false,
        }
      },
      {
        name: 'comments',
        label: 'Comments',
        options: {
          display: getColumnDisplay('comments', true),
          filter: false,
          sort: false,
        }
      },
    ];

    columns = leadDeptColumns.concat(columns);
  }

  if (user.storedUser && (user.storedUser.user_role === 'manager' || user.storedUser.user_role === 'rvp')) {
    const inventoryStatusOptions = ['Available', 'Reserved'];

    const managerRvpColumns = [
      {
        name: 'inventoryStatus',
        label: 'Status',
        options: {
          display: getColumnDisplay('inventoryStatus', true),
          filter: true,
          sort: true,
          filterType: 'multiselect',
          filterList: (filteredLeadOptions && filteredLeadOptions.inventoryStatus && filteredLeadOptions.inventoryStatus.map(value => value.charAt(0).toUpperCase() + value.slice(1))),
          customBodyRender: (value, tableMeta) => {
            if(value === undefined)
               return value;
            let statusName = value?.charAt(0).toUpperCase() + value.slice(1);

            switch(statusName) {
              case 'Not_available':
                return 'Not Available';
              case 'Pending':
                return <Button
                  className={classes.pending}
                  onClick={() =>{
                    setLoading(true);
                    setErrorMessage(null);
                    const leadId = leads.leads[tableMeta.rowIndex].nid;
                    dispatch(reviewPendingLead(leadId)).then((response) => {
                      if(response.error) {
                        setErrorMessage(response.payload);
                        setLoading(false);
                      }
                      else{
                        navigate('/review-leadset');
                      }
                    });
                  }}
                >
                  {statusName}
                </Button>;
              default:
                return statusName;

            }
          },
          filterOptions: {
            names: inventoryStatusOptions,
          },
        }
      }
    ];

    columns = managerRvpColumns.concat(columns);
  }

  const options = {
    confirmFilters: true,
    count: filteredLeadPaging.items,
    download: true,
    print: true,
    rowsPerPageOptions: [50, 100, 200, 300],
    rowsPerPage: filteredLeadPaging.itemsPerPage,
    search: false,
    serverSide: true,
    selectableRowsOnClick: true,
    responsive: "vertical",
    textLabels: {
      body: {
        noMatch: 'Sorry, no matching records found. Please modify filter parameters',
      },
      filter: {
        reset: 'CLEAR ALL',
      },
      selectedRows: {
        text: "leads(s) selected",
        deleteAria: "Delete Selected Leads",
      },
    },
    isRowSelectable: (dataIndex) => {
      if(!selectedLeads || !selectedLeads.selectedLeads) return true;
      
      const lead = filteredLeads[dataIndex];
      if(selectedLeads && selectedLeads.selectedLeads && selectedLeads.selectedLeads.some(sl => sl.id === lead.id)) return false;
      
      return true;
    },
    setRowProps: (row, dataIndex) => {
      const lead = filteredLeads[dataIndex];
      if(selectedLeads && selectedLeads.selectedLeads && selectedLeads.selectedLeads.some(sl => sl.id === lead.id)) return {className: classes.selectedRow};
    },
    rowsSelected: [],
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            color="primary"
            className="btn"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    customToolbarSelect: selectedRows => {
      return (<FilterLeadsTools
        selectedRows={selectedRows}
        rows={filteredLeads}
        dispatch={dispatch}
        options={filteredLeadOptions}
        userRole={storedUser.user_role}
        leadSet={leadSet.leadSet}
        managers={managers.managers}
        allSelectedLeads={selectedLeads.selectedLeads}
      />);
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      console.log('download')
        setLoading(false);
        dispatch(fetchAllLeads({...filteredLeadOptions, columns: columns}));
        return false;
    },
    onTableChange: (action, tableState) => {
      let options = { ...leads.options };
     
      switch (action) {
        case 'sort': {
          options.sort = {
            name: tableState.sortOrder.name,
            asc: tableState.sortOrder.direction === 'asc',
          }
          setFilteredLeadOptions(options);
          break;
        }
        case 'changePage': {
          options.page = tableState.page;
          setFilteredLeadOptions(options);
          break;
        }
        case 'changeRowsPerPage': {
          options.page = 0;
          options.itemsPerPage = tableState.rowsPerPage;
          setFilteredLeadOptions(options);
          break;
        }
        case 'propsUpdate': {
          if(tableState?.columns && !tableColumns) setTableColumns(tableState.columns);
          break;
        }
        case 'viewColumnsChange': {
          setTableColumns(tableState.columns);
          break;
        }
       
        default: { break; }
      }
    },
  };

  const handleFilterSubmit = applyFilters => {
    let filterList = applyFilters();
    let options = {
      items: leads.options?.items,
      next: leads.options?.next,
      previous: leads.options?.previous,
      userRole: user?.storedUser?.user_role,
      itemsPerPage: leads.options?.itemsPerPage,
    };

    filterList.forEach((values, index) => {
      const name = columns[index].name;

      if(values.length > 0) {
        switch (name) {
          case 'location':
            options[name] = values[0].trim();
            break;
          case 'address':
          case 'ani':
          case 'firstName':
          case 'city':
          case 'county':
          case 'dts':
          case 'dropCode':
          case 'email':
          case 'lastName':
          case 'phone':
          case 'zip':
            options[name] = values[0].trim();
            break;
          
          case 'as400Id':
            options['as400id'] = values[0].trim();
            break;

          case 'complete':
            const completeOptions = {
              'Y': 1,
              'N': 0,
              'All': undefined,
            };
            options['complete'] = completeOptions[values[0]];
            break;

          case 'created':
            let minDate = values[0].min;
            let maxDate = values[0].max;

            if (minDate) minDate = new Date(
              minDate.getUTCFullYear(),
              minDate.getMonth(),
              minDate.getDate(),
              0, 0, 1
            );

            if (maxDate) maxDate = new Date(
              maxDate.getUTCFullYear(),
              maxDate.getMonth(),
              maxDate.getDate(),
              23, 59, 59
            );

            options.created = {
              min: (minDate ? format(minDate, 't'): null),
              max: (maxDate ? format(maxDate, 't'): null),
            };
            break;

          case 'dnc':
            options['dnc'] = dncOptions[values[0]];
            break;

          case 'dnm':
            options['dnm'] = dnmOptions[values[0]];
            break;

          case 'inventoryStatus':
            options.inventoryStatus = values;
            break;
          
          case 'leadTypeId':
            const leadValues = values.map(v =>({ 
              id: v
            }));
            options.leadTypes = leadValues;
            break;

          case 'managerInventory':
            options.managerInventory = values.map(manager => ({id: manager.uuid}));
            break;

          case 'stateId':
            const stateValues = values.map(v =>({ 
              id: v
            }));
            options.states = stateValues;
            break;
          default:
            console.warn(`Unkown filter in handleFilterSubmit filterList: ${name}`);
            break;
        }
      }
      else if (values.length === 0) {
        delete options[name];
      }
    });

    setFilteredLeadOptions(options);
  }

  return (
    <div className="leadsDashboard">
      {successMessage && (<Alert severity="success">{successMessage}</Alert>)}

      {errorMessage && (<Alert severity="error">Error message: {errorMessage} If you are unable to resolve this error please contact your administrator. </Alert>)}

      {leads && leads.allLeadsData && (
        <Alert severity="success">
          Your download is available: 
          <CSVLink
            data={leads.allLeadsData}
            headers={leads.allLeadsHeaders}
            filename={`SeniorLifeLeads-${format(new Date(), 'MMddyyyy-HHmm')}.csv`}
            target='_blank'
            >
            Download CSV
          </CSVLink>
        </Alert>
      )}
      
      {loading && (
        <div className="progressWrapper">
          <CircularProgress />
        </div>
      )}
      {!initLoading && (

        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={leadsTableTheme()}>
            <MUIDataTable
              title={"Leads"}
              data={filteredLeads}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </div>
  );
}
