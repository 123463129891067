import { useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, StyledEngineProvider, } from '@mui/material/styles';

const theme = createTheme();

const leadsTableTheme = () => createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: { color: 'rgba(0, 0, 0, 0.87)' }
      }
    },
    MUIDataTable:{
      styleOverrides: {
        paper: {
          border: '3px solid red',
        }
      }
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        chip: {
          display: 'none'
        }
      }
    },
    MUIDataTableHead : {
      styleOverrides: {
        responsiveStacked: {
          [theme.breakpoints.down('md')]: {
            display: 'table-header-group',
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        },
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('md')]: {
            display: 'table-cell',
          },
        },
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        filterPaper: {
          [theme.breakpoints.down('md')]: {
            width: '75%',
            maxWidth: '90%',
            height: '100%',
          },
          [theme.breakpoints.down('sm')]: {
            width: '90%',
          },
        }
      }
    },
  }
});

export default function FailTable(props) {
  const taxonomy = useSelector(state => state.taxonomy);
  const user = useSelector(state => state.user);
  const leads = props.leads;

  let columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      }
    },
    {
      name: "nid",
      label: "nid",
      options: {
        filter: false,
        display: false,
      }
    },
    {
      name: "zip",
      label: 'Zip Code',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "dts",
      label: "Call DTS",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "county",
      label: "County",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "stateId",
      label: "State",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(taxonomy.states){
            let val = taxonomy.states.filter(p => p.id === value);
            if (val !== null && val.length > 0) {
              return val[0].name;
            }
          }
          return '';
        },
        filterType: 'multiselect',
        filterOptions: {
          fullWidth: true,
          // names: (taxonomy.states) ? taxonomy.states.map(p => p.id) : [],
          // renderValue: v => (taxonomy.states) ? taxonomy.states.filter(p=>p.id === v)[0].name : '',
        },
      }
    },
    {
      name: "leadTypeId",
      label: "Lead Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const lead = leads[tableMeta.rowIndex];
          if(lead.leadTypeLabel) return lead.leadTypeLabel;
          if(taxonomy.leadTypes){
            let val = taxonomy.leadTypes.filter(p => p.id === value);
            if (val !== null && val.length > 0) {
              return val[0].type;
            }
          }
          return '';
        },
        filterType: 'multiselect',
        filterOptions: {
          fullWidth: true,
        },
      }
    },
    {
      name: "dropCode",
      label: "DM Drop Code",
      options: {
        filter: false,
        sort: true
      }
    },
  ];
  
  if (user.storedUser && user.storedUser.user_role === 'sl_lead_dept') {
    const leadDeptColumns = [
      {
        name: 'fail_reason',
        label: "Fail Reason",
      },
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'address',
        label: 'Address',
        options: {
          filter: false,
          sort: true,
        }
      },
    ];

    columns = leadDeptColumns.concat(columns);
    columns.push({
      name: "price",
      label: "Price",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (value ? value : 0);
        }
      }
    });
  }

  const options = {
    download: true,
    downloadOptions: {
      filename: 'LeadSetLeads.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
      }
    },
    rowsPerPageOptions: [10, 25, 50],
    rowsSelected: [],
    search: false,
    selectableRows: false,
    viewColumns: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      const stateColumn = columns.findIndex(column => column.name === 'stateId');
      const leadTypeColumn = columns.findIndex(column => column.name === 'leadTypeId');

      const mappedData = data.map(row => {
        let newRow = {
          ...row
        };

        if(taxonomy.states && stateColumn){
          let val = taxonomy.states.filter(p => p.id === row.data[stateColumn]);
          if (val !== null && val.length > 0) {
            newRow.data[stateColumn] = val[0].name;
          }
        }

        if(taxonomy.states && leadTypeColumn){
          let val = taxonomy.leadTypes.filter(p => p.id === row.data[leadTypeColumn]);
          if (val !== null && val.length > 0) {
            newRow.data[leadTypeColumn] = val[0].type;
          }
        }

        return newRow;
      });

      return buildHead(columns) + buildBody(mappedData);
    },
  };


  return (
    <div className="leadsDashboard">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={leadsTableTheme()}>
          <MUIDataTable
            title={"Failed Leads"}
            data={leads}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}
