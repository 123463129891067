export default class PaginatedApi {
  static async fetchPaginated(url, header, previousResponse = []) {
    return new Promise((resolve) => {
      fetch(url, header)
      .then(response => {
        if(response.status === 403) return(response);
        return response.json();
      }).then(newResponse => {
        const joinedResponse = previousResponse.concat(newResponse.data);
        
        if(newResponse.links.next) {
          const recursiveResponse = this.fetchPaginated(newResponse.links.next.href, header, joinedResponse);
          return recursiveResponse;
        }

        return joinedResponse;
      })
      .then((joinedResponse)=>{
        resolve(joinedResponse);
      });
    });
  }
}
