import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TaxonomyApi from '../Api/TaxonomyApi';

export const fetchAllTaxonomies = createAsyncThunk(
  'taxonomy/fetchAllTaxonomies',
  async (reset = false, { getState, rejectWithValue }) => {
    const user = getState().user;
    if(reset) {
      return {
        states: null,
        leadTypes: null,
        pricing: null,
        logout: true,
      };
    }

    try {
      let response = await TaxonomyApi.fetchAllTaxonomies(user);
      response.logout = false;
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const taxonomySlice = createSlice({
  name: 'taxonomy',
  initialState: {
    states: null,
    leadTypes: null,
    // roles: null,
    pricing: null,
    loading: 'idle',
    logout: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchAllTaxonomies.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    [fetchAllTaxonomies.fulfilled]: (state, action) => {
      if (state.loading === 'pending' || action.payload.logout) {
        state.states = action.payload.states;
        state.leadTypes = action.payload.leadTypes;
        state.pricing = action.payload.pricing;
        state.loading = (action.payload.logout? 'idle' : 'fulfilled');
        state.logout = false;
      }
    },
    [fetchAllTaxonomies.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.loading = 'fail';
      }
    }
  }
});

export default taxonomySlice.reducer;
