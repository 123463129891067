const API_URL = process.env.REACT_APP_API_URL;

export default class ArchiveLeadApi {


  static async fetchLeads(leadSetId, user) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + user.storedUser.access_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }

    let leadResponse = await fetch(API_URL + `/archive/lead_set_leads?lead_set=${leadSetId}`, requestOptions);
    let responseJson = await leadResponse.json();
    let response = {'leads': []};

    response.leads = this.mapLeads(responseJson);

    return response;
  }

  static mapLeads(leadJson) {
    let leads = [];
    if(leadJson.data.length === 0) return leads;

    leads = leadJson.data.map(lead => ({
      id: lead.id,
      nid: lead.nid,
      dts: (lead.field_call_dts ? new Date(lead.field_call_dts).toLocaleDateString("en-US") : null),
      city: lead.field_city,
      complete: lead.field_complete,
      county: lead.field_address2,
      zip: lead.field_zip,
      leadTypeId: lead.field_lead_type,
      leadTypeText: lead.field_lead_type_text,
      leadTypeLabel: lead.field_lead_type_label,
      managerInventory: lead.field_manager_inventory,
      managerInventoryName: lead.field_manager_inventory__name,
      state: lead.field_state,
      dropCode: lead.field_dm_drop_code,

      ani: lead.field_ani,
      address: lead.field_address,
      comments: lead.field_comments,
      created: (lead.created ? new Date(lead.created).toLocaleDateString("en-US") : null),
      dnc: (lead.field_do_not_call ? 'DNC' : ''),
      dnm: (lead.field_do_not_mail ? 'DNM' : ''),
      email: lead.field_email,
      inventoryStatus: lead.field_inventory_status,
      firstName: lead.field_first_name,
      lastName: lead.field_last_name,
      mailImage: lead.field_mail_image,
      phone: lead.field_phone,
      price: lead.field_price,
    }));

    return (leads);
  }

  // static tryFormat(date) {
  //   try {
  //     return format(new Date(date), 'MM/dd/yyy');
  //   }
  //   catch(err) {
  //     console.log('date formatting error', date);
  //     return date;
  //   }
  // }
}


