import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAllTaxonomies } from '../../Store/Taxonomy';
import { fetchLeads } from '../../Store/Leads';
import { fetchManagers } from '../../Store/Managers';
import TrashLeads from './TrashLeads';

export default function Trash() {

  const dispatch = useDispatch();
  const taxonomy = useSelector(state => state.taxonomy);
  const leads = useSelector(state => state.leads);
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const managers = useSelector(state => state.fetchManagers);

  useEffect(() => {
    if (taxonomy.loading !== 'fulfilled' && taxonomy.loading !== 'fail') {
      dispatch(fetchAllTaxonomies());
    }
    if (managers.loading !== 'fulfilled' && managers.loading !== 'fail') {
      dispatch(fetchManagers(user));
    }
  }, [dispatch, managers.loading, taxonomy.loading, user]);

  useEffect(() => {
    if (taxonomy && taxonomy.loading === 'fulfilled') {
      let options = {
        trash: true,
      };
      dispatch(fetchLeads(options));
    }
    else if (taxonomy && taxonomy.loading === 'fail') {
      navigate("/login");
    }
  }, [dispatch, navigate, taxonomy]);

  useEffect(() => {
    if (leads && leads.loading === 'fail') {
      navigate("/login");
    }
  }, [navigate, leads]);

  return (
    <div>
      <h1>Trash Bin</h1>
      <TrashLeads />
    </div>
  );
}