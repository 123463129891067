import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserApi from '../Api/UserApi';

export const fetchManagers = createAsyncThunk(
  'managers/fetch',
  async (user, dispatch) => {
    if(!user) user = dispatch.getState().user;
    try {
      const response = await UserApi.fetchManagers(user, dispatch);
      return response;
    }
    catch (err) {
      console.error(err);
      return dispatch.rejectWithValue(err);
    }
  }
);

export const logoutManagers = createAsyncThunk(
  'managers/logout',
  () => true
);

const usersSlice = createSlice({
  name: 'managers',
  initialState: {
    managers: null,
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchManagers.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    [fetchManagers.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.managers = action.payload;
        state.loading = 'fulfilled';
      }
    },
    [fetchManagers.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.error = action.payload;
        state.loading = 'fail';
      }
    },
    [logoutManagers.fulfilled]: (state, action) => {
      state.managers = null;
      state.loading = 'idle';
      state.error = null;
    },
  }
});

export default usersSlice.reducer;
