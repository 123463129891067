import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { fetchLeads } from '../../Store/Leads';
import { Button, CircularProgress, } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { 
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TrashLeadsTools from "./TrashLeadsTools";
import './filter.css';

const useStyles = makeStyles()({
  selectFix: {
    left: -15,
    top: 7,
  }
});

const leadsTableTheme = () => createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: { color: 'rgba(0, 0, 0, 0.87)' }
      }
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        chip: {
          display: 'none'
        }
      }
    },
  },
});

export default function TrashLeads() {

  const dispatch = useDispatch();
  const taxonomy = useSelector(state => state.taxonomy);
  const leads = useSelector(state => state.leads);
  const user = useSelector(state => state.user);

  const [ loading, setLoading ] = useState(true);
  const [ errorMessage, setErrorMessage ] = useState(false);
  const [ successMessage, setSuccessMessage ] = useState(false);
  const [ filteredLeads, setFilteredLeads ] = useState([]);
  const [ filteredLeadOptions, setFilteredLeadOptions ] = useState({trash: true});
  const [ filteredLeadPaging, setFilteredLeadPaging ] = useState({
    itemsPerPage: 50,
    items: -1,
  });

  const {classes} = useStyles();

  useEffect(() => {
    if (leads && leads.leads && leads.loading === 'idle') {
      setFilteredLeads(leads.leads);
      setLoading(false);
    }

    //if (filteredLeadPaging?.items && leads.options?.itemsPerPage){
      if (filteredLeadPaging?.items !== leads.options?.items || filteredLeadPaging.itemsPerPage !== leads.options.itemsPerPage) {
        let updateFilteredLeadPaging = {...filteredLeadPaging};
        updateFilteredLeadPaging.items = leads.options.items;
        updateFilteredLeadPaging.itemsPerPage = leads.options.itemsPerPage;
        setFilteredLeadPaging(updateFilteredLeadPaging);
      }
    //}

    if (leads && leads.error !== errorMessage) {
      setErrorMessage(leads.error);
      setLoading(false);
    }

    if (leads && leads.successMessage !== successMessage) setSuccessMessage(leads.successMessage);

  }, [errorMessage, filteredLeadPaging, leads, successMessage]);

  useEffect(() => {
    if (filteredLeadOptions) {
      setLoading(true);
      dispatch(fetchLeads(filteredLeadOptions));
    }
  }, [dispatch, filteredLeadOptions]);

  let columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      }

    },
    {
      name: "zip",
      label: 'Zip Code',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "days",
      label: "Days since created",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "county",
      label: "County",
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          fullWidth: true,
        },
      }
    },
    {
      name: "stateId",
      label: "State",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(taxonomy.states && taxonomy.states.length > 1){
            let val = taxonomy.states.filter(p => p.id === value);
            if (val !== null && val.length > 0) {
              return val[0].name;
            }
          }
          return '';
        },
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          names: (taxonomy.states) ? taxonomy.states.map(p => p.id) : [],
          renderValue: v => (taxonomy.states) ? taxonomy.states.filter(p=>p.id === v)[0].name : '',
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <InputLabel className={classes.selectFix} htmlFor='select-states'>
                  States
                </InputLabel>
                <Select
                  id='select-states'
                  multiple
                  variant='standard'
                  value={filterList[index]}
                  onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {taxonomy.states.map(state => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))};
                </Select>
              </FormControl>
            );
          },
        },
      }
    },
    {
      name: "leadTypeId",
      label: "Lead Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(taxonomy.leadTypes && taxonomy.leadTypes.length > 1){
            let val = taxonomy.leadTypes.filter(p => p.id === value);
            if (val !== null && val.length > 0) {
              return val[0].type;
            }
          }
          return '';
        },
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          names: (taxonomy.leadTypes) ? taxonomy.leadTypes.map(p => p.id) : [],
          renderValue: v => (taxonomy.leadTypes) ? taxonomy.leadTypes.filter(p => p.id === v)[0].type : '',
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <InputLabel className={classes.selectFix} htmlFor='select-lead-types'>
                  Lead Types
                </InputLabel>
                <Select
                  id='select-lead-types'
                  multiple
                  variant='standard'
                  value={filterList[index]}
                  onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {taxonomy.leadTypes.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.type}
                    </MenuItem>
                  ))};
                </Select>
              </FormControl>
            );
          }
        },
      }
    },
    {
      name: "dropCode",
      label: "DM Drop Code",
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  if (user.storedUser && user.storedUser.user_role === 'sl_lead_dept') {
    const inventoryStatusOptions = ['Available', 'Duplicate', 'Reserved', 'Assigned', 'Pending', 'Unlicensed', 'Not Available'];

    const leadDeptColumns = [
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'address',
        label: 'Address',
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: 'inventoryStatus',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          filterType: 'multiselect',
          filterOptions: {
            fullWidth: true,
            names: inventoryStatusOptions,
          },
          filterList: (filteredLeadOptions && filteredLeadOptions.inventoryStatus && filteredLeadOptions.inventoryStatus.map(value => value.charAt(0).toUpperCase() + value.slice(1))),
          customBodyRender: (value, tableMeta) => {
            let statusName = value.charAt(0).toUpperCase() + value.slice(1);

            switch(statusName) {
              case 'Not_available':
                return 'Not Available';
              default:
                return statusName;

            }
          },
        }
      }
    ];

    columns = leadDeptColumns.concat(columns);
  }

  const options = {
    count: filteredLeadPaging.items,
    download: false,
    print: false,
    rowsPerPageOptions: [10, 25, 50],
    rowsPerPage: filteredLeadPaging.itemsPerPage,
    search: false,
    serverSide: true,
    selectableRowsOnClick: true,
    rowsSelected: [],
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            color="primary"
            className="btn"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      let options = { ...leads.options };
      switch (action) {
        case 'sort': {
          options.sort = {
            name: tableState.sortOrder.name,
            asc: tableState.sortOrder.direction === 'asc',
          }
          setFilteredLeadOptions(options);
          break;
        }
        case 'changePage': {
          options.page = tableState.page;
          setFilteredLeadOptions(options);
          break;
        }
        case 'changeRowsPerPage': {
          options.page = 0;
          options.itemsPerPage = tableState.rowsPerPage;
          setFilteredLeadOptions(options);
          break;
        }
        default: { break; }
      }
    },
    customToolbarSelect: selectedRows => {
      return (<TrashLeadsTools
        selectedRows={selectedRows}
        rows={filteredLeads}
        dispatch={dispatch}
        options={filteredLeadOptions}
      />);
    },
  };

  const handleFilterSubmit = applyFilters => {
    let filterList = applyFilters();
    let options = {
      items: leads.options.items,
      next: leads.options.next,
      previous: leads.options.previous,
      userRole: leads.options.userRole,
      itemsPerPage: leads.options.itemsPerPage,
      trash: true,
    };

    filterList.forEach((values, index) => {
      const name = columns[index].name;
      if(values.length > 0) {
        switch (name) {
          case 'address':
          case 'firstName':
          case 'city':
          case 'county':
          case 'dnc':
          case 'dts':
          case 'dropCode':
          case 'email':
          case 'lastName':
          case 'phone':
          case 'zip':
            options[name] = values[0];
            break;
          
          case 'stateId':
            const stateValues = values.map(v =>({ 
              id: v
            }));
            options.states = stateValues;
            break;

          case 'inventoryStatus':
            options.inventoryStatus = values;
            break;
          
          case 'leadTypeId':
            const leadValues = values.map(v =>({ 
              id: v
            }));
            options.leadTypes = leadValues;
            break;
          default:
            console.warn(`Unkown filter in handleFilterSubmit filterList: ${name}`);
            break;
        }
      }
      else if (values.length === 0) {
        delete options[name];
      }
    });

    setFilteredLeadOptions(options);
  }

  return (
    <div className="leadsDashboard">
      {successMessage && (<Alert severity="success">{successMessage}</Alert>)}
      {errorMessage && (<Alert severity="error">Something went wrong! Please contact your administrator. Error message: {errorMessage}</Alert>)}
      {loading && (
          <div className="progressWrapper">
            <CircularProgress />
          </div>
      )}

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={leadsTableTheme()}>
          <MUIDataTable
            title={"Leads in Trash"}
            data={filteredLeads}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </StyledEngineProvider>

    </div>
  );
}
